import * as requestFromServer from "./categoriesCrud";
import { categoriesSlice, callTypes } from "./categoriesSlice";

const { actions } = categoriesSlice;

export const fetchAllCategories = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllCategories()
    .then(response => {
      const { categories } = response.data;
      dispatch(actions.categoriesFetched({ categories }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createCategory = category => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCategory(category)
    .then(response => {
      const { category } = response.data;
      dispatch(actions.categoryCreated({ category }));
      return category;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateCategory = (id, category) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCategory(id, category)
    .then(response => {
      const { category } = response.data;
      dispatch(actions.categoryUpdated({ category }));
      return category;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};


export const deleteCategory = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCategory(id)
    .then(response => {
      const { category } = response.data;
      dispatch(actions.categoryDeleted({ id: category.id }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchCategory = id => dispatch => {
  if (!id) {
    return dispatch(actions.categoryFetched({ categoryForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCategoryById(id)
    .then(response => {
      const category = response.data;
      dispatch(actions.categoryFetched({ categoryForEdit: category }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};
