import React from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import { getQueryParams } from "../../../../_metronic/_helpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CustomButton } from "../../../Components/CustomButton";

const initialValues = {
  password: "",
  confirmPassword: "",
};

function ResetPassword(props) {
  const { intl, location: { search } } = props;
  const token = getQueryParams(search, "token");
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 8 }))
      .max(15, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: 15 }))
      .matches(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z]).*$/, intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_ERROR" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], intl.formatMessage({
        id: "AUTH.VALIDATION.MATCH_PASSWORD",
      }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus }) => {
      resetPassword(values.password, token)
        .then(() => {
          setStatus({
            message: intl.formatMessage(
              { id: "AUTH.RESET.SUCCESS" },
            ),
            type: "success"
          });
        })
        .catch(() => {
          setStatus({
            message: intl.formatMessage(
              { id: "AUTH.RESET.ERROR" },
              { name: values.email }
            ),
            type: "danger"
          });
        });
    },
  });

  return (
    <>
      <Fade in={true} timeout={2000}>
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <img
              alt="Logo"
              height="40"
              className="mb-15"
              src={toAbsoluteUrl("/media/logos/logo-light.png")}
            />
            <h3 className="font-size-h1"><FormattedMessage id="AUTH.RESET.TITLE" /></h3>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className={`mb-10 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">
                  {formik.status.message} {formik.status.type === "danger" && <a href="mailto:support@redmaki.com?subject=ResetPassword">
                    support</a>}.
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <TextField
                error={!!(formik.touched.password && formik.errors.password)}
                id="password"
                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.PASSWORD" })}
                name="password"
                type="password"
                margin="normal"
                variant="outlined"
                autoFocus={true}
                autoComplete="new-password"
                helperText={formik.errors.password}
                {...formik.getFieldProps("password")}
              />
              <TextField
                error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                id="confirmPassword"
                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.CONFIRM_PASSWORD" })}
                name="confirmPassword"
                type="password"
                margin="normal"
                variant="outlined"
                autoComplete="new-password"
                helperText={formik.errors.confirmPassword}
                {...formik.getFieldProps("confirmPassword")}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <CustomButton
                variant='primary'
                id="kt_login_forgot_submit"
                type="submit"
                className="mr-5"
                disabled={formik.errors.confirmPassword || formik.errors.password}
              >
                <FormattedMessage id="GENERAL.SUBMIT" />
              </CustomButton>
              <Link to="/auth">
                <CustomButton
                  variant='secondary'
                  type="button"
                  id="kt_login_forgot_cancel"
                >
                  <FormattedMessage id="GENERAL.CANCEL" />
                </CustomButton>
              </Link>
            </div>
          </form>
        </div>
      </Fade>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
