import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    "& .card-header": {
      justifyContent: "center !important",
      flexDirection: "column",
    },
  },
  link: {
    color: "#BFBFBF",
    marginRight: "10px",
  },
  domainContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  domain: {
    margin: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "5px",
    },
  },
}));

const FacebookIntegration = (props) => {
  const classes = useStyles();
  const { intl } = props;
  const key = useSelector((state) => state.auth?.user?.company?.apikey);
  const appointmentUrl = `${process.env.REACT_APP_BASE_URL}/${key}`;
  const [linkCopyText, setLinkCopyText] = useState(
    intl.formatMessage({ id: "GENERAL.COPY" })
  );

  useEffect(() => {
    setTimeout(() => {
      setLinkCopyText(intl.formatMessage({ id: "GENERAL.COPY" }));
    }, 3000);
  }, [linkCopyText, intl]);

  return (
    <Card className={classes.card}>
      <CardHeader title={intl.formatMessage({ id: "INTEGRATION.FACEBOOK" })}>
        <Link className={classes.link} to="/your-website">
          {intl.formatMessage({ id: "INTEGRATION.BACKBUTTON" })}
        </Link>
      </CardHeader>
      <CardBody>
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-facebook"
            viewBox="0 0 16 16"
          >
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
          </svg>
          &nbsp;
          {intl.formatMessage({
            id: "INTEGRATION.ADD_APPOINTMENTS_TO_FACEBOOK",
          })}
        </h4>
        <div className={classes.domainContainer}>
          <a
            href={`${appointmentUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${appointmentUrl}`}
          </a>

          <Tooltip
            disableFocusListener
            title={linkCopyText}
            placement="top"
            arrow
          >
            <CopyToClipboard
              text={`${appointmentUrl}`}
              className="btn btn-icon btn-light btn-sm ml-3"
            >
              <button
                className="btn btn-icon btn-light btn-sm ml-3"
                onClick={() =>
                  setLinkCopyText(intl.formatMessage({ id: "GENERAL.COPIED" }))
                }
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Clipboard.svg"
                    )}
                  />
                </span>
              </button>
            </CopyToClipboard>
          </Tooltip>
        </div>

        <p className="mt-3">
          <FormattedMessage
            id="INTEGRATION.FACEBOOK_TEXT"
            description="Facebook Integration guide text"
            values={{
              a: (chunks) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://redmaki.com"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </p>
      </CardBody>
    </Card>
  );
};

export default injectIntl(FacebookIntegration);
