/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useClearAllErrors } from "../../../../../app/helpers/useClearAllErrors";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { clearAllErrors } = useClearAllErrors();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const clearReduxErrors = () => clearAllErrors();

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`}>
          <NavLink
            className="menu-link"
            to="/dashboard"
            id="dashboard"
            onClick={clearReduxErrors}
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.DASHBOARD" />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/appointments", false)}`}
        >
          <NavLink
            className="menu-link"
            to="/appointments"
            id="appointments"
            onClick={clearReduxErrors}
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")} />
            </span>
            <span className="menu-text" id="appointments">
              <FormattedMessage id="MENU.APPOINTMENTS" />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/customers", false)}`}>
          <NavLink
            className="menu-link"
            to="/customers"
            id="customers"
            onClick={clearReduxErrors}
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.CUSTOMERS" />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/your-website", false)}`}
        >
          <NavLink
            className="menu-link"
            to="/your-website"
            id="your-website"
            onClick={clearReduxErrors}
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Smile.svg")} />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.YOUR_WEBSITE" />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/communication", false)}`}
        >
          <NavLink
            className="menu-link"
            to="/communication"
            id="communication"
            onClick={clearReduxErrors}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.COMMUNICATION" />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Components */}
        {/* Material-UI */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/settings",
            true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/settings"
            id="settings"
            onClick={clearReduxErrors}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Tools/Screwdriver.svg")}
              />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.SETTINGS" />
            </span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/settings/categories",
                  true
                )}`}
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/categories"
                  onClick={clearReduxErrors}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.SETTINGS.CATEGORIES" />
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Navigation */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu  ${getMenuItemActive(
                  "/settings/services",
                  true
                )}`}
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/services"
                  onClick={clearReduxErrors}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.SETTINGS.SERVICES" />
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Surfaces */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/settings/resources",
                  true
                )}`}
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/resources"
                  onClick={clearReduxErrors}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.SETTINGS.RESOURCES" />
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Feedback */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/settings/address",
                  true
                )}`}
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/address"
                  onClick={clearReduxErrors}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.SETTINGS.ADDRESS" />
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Data Display */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/settings/hours",
                  true
                )}`}
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/hours"
                  onClick={clearReduxErrors}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.SETTINGS.HOURS" />
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Utils */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/settings/employees",
                  true
                )}`}
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/employees"
                  onClick={clearReduxErrors}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.SETTINGS.EMPLOYEES" />
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/settings/booking-page",
                  true
                )}`}
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/booking-settings-page"
                  onClick={clearReduxErrors}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.SETTINGS.SETTINGS.PAGE" />
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
