import React from "react";
import { faCheck, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CustomEventSlot = (props) => {
    const { event, title } = props;

    const isBlockType = event.type === 'BLOCK';
    const noEmployeePreference = event.employee_preference === 0;

    return (
        <>
            {isBlockType ? (
                <FontAwesomeIcon
                    icon={faCheck}
                    // size="lg"
                    className="rbc-event-content__unblock-icon"
                />
            ) : null}
            <p>
                {title}
                {noEmployeePreference ? (
                    <FontAwesomeIcon icon={faExchangeAlt} style={{marginLeft: '5px'}} />
                ) : null}
            </p>
        </>
    );
};
