import "date-fns";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
// import VideoCallIcon from "@material-ui/icons/VideoCall";
import * as customerActions from "../../_redux/customers/customersActions";
import { CustomButton } from "../../../../Components/CustomButton";

function CustomerForm(props) {
  const { intl, toggleDrawer, customerId } = props;
  const iconColor = { color: "#B5B5C3" };

  const CustomerSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(
        125,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 125 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .max(
        125,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 125 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT" }))
      .max(
        255,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneNumber: Yup.string().max(
      32,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
        { max: 32 }
      )
    ),
    skype: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" },
          { min: 3 }
        )
      )
      .max(
        50,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 50 }
        )
      ),
    active: Yup.bool(),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customerActions.fetchCustomer(customerId));
  }, [customerId, dispatch]);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    skype: "",
    active: true,
    notAskReview: false,
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CustomerSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const customer = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        skype: values.skype,
        active: values.active,
        notAskReview: values.notAskReview,
      };
      try {
        if (customerId) {
          await dispatch(
            customerActions.updateCustomer(customerId, { ...customer })
          );
        } else {
          await dispatch(customerActions.createCustomer({ ...customer }));
        }
        toggleDrawer(false)(null);
      } catch (e) {
        console.error(e);
      }
    },
  });

  const { customersState } = useSelector(
    (state) => ({
      customersState: state.customers,
    }),
    shallowEqual
  );

  const { error: customersError, customerForEdit } = customersState;

  useEffect(() => {
    setInitialValues(customerForEdit || initialValues);
  }, [customerForEdit, initialValues]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
    >
      <div className="form-group fv-plugins-icon-container">
        {customersError && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{customersError}</div>
          </div>
        )}
        <TextField
          error={!!(formik.touched.firstName && formik.errors.firstName)}
          autoFocus
          id="firstName"
          label={intl.formatMessage({ id: "CUSTOMER.FIRST_NAME" })}
          name="firstName"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.firstName}
          {...formik.getFieldProps("firstName")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={!!(formik.touched.lastName && formik.errors.lastName)}
          id="lastName"
          label={intl.formatMessage({ id: "CUSTOMER.LAST_NAME" })}
          name="lastName"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.lastName}
          {...formik.getFieldProps("lastName")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={!!(formik.touched.email && formik.errors.email)}
          id="email"
          label={intl.formatMessage({ id: "CUSTOMER.EMAIL" })}
          type="email"
          name="email"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.email}
          {...formik.getFieldProps("email")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={!!(formik.touched.phoneNumber && formik.errors.phoneNumber)}
          id="phoneNumber"
          label={intl.formatMessage({ id: "CUSTOMER.PHONE" })}
          name="phoneNumber"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.phoneNumber}
          {...formik.getFieldProps("phoneNumber")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        {/* <TextField
          error={!!(formik.touched.skype && formik.errors.skype)}
          id="skype"
          label={intl.formatMessage({ id: "CUSTOMER.SKYPE" })}
          name="skype"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.skype}
          value={formik.values.skype || ""}
          onChange={(e) => formik.setFieldValue("skype", e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VideoCallIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={formik.values?.notAskReview || false}
              onChange={(value) =>
                formik.setFieldValue(
                  "notAskReview",
                  !formik.values?.notAskReview
                )
              }
            />
          }
          label={intl.formatMessage({ id: "CUSTOMER.NOT_ASK_REVIEW" })}
        /> */}
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <CustomButton
          variant='primary'
          type="submit"
          className="mr-5"
        >
          <FormattedMessage id="GENERAL.SUBMIT" />
        </CustomButton>
        <CustomButton
          variant='secondary'
          type="button"
          onClick={toggleDrawer(false)}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </CustomButton>
      </div>
    </form>
  );
}

export default injectIntl(CustomerForm);
