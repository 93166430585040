import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    "& .card-header": {
      justifyContent: "center !important",
      flexDirection: "column",
    },
  },
  link: {
    color: "#BFBFBF",
    marginRight: "10px",
  },
  scriptBox: {
    marginTop: "10px",
    backgroundColor: "#F5F5F5",
    color: "#818181",
    display: "flex",
    alignItems: "center",
    "& p": {
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: "5px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const FloatingButtonIntegration = (props) => {
  const classes = useStyles();
  const { intl } = props;
  const key = useSelector((state) => state.auth?.user?.company?.apikey);
  const [scriptCopyText, setScriptCopyText] = useState(
    intl.formatMessage({ id: "GENERAL.COPY" })
  );

  useEffect(() => {
    setTimeout(() => {
      setScriptCopyText(intl.formatMessage({ id: "GENERAL.COPY" }));
    }, 3000);
  }, [scriptCopyText, intl]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={intl.formatMessage({ id: "INTEGRATION.FLOATINGBUTTON" })}
      >
        <Link className={classes.link} to="/your-website">
          {intl.formatMessage({ id: "INTEGRATION.BACKBUTTON" })}
        </Link>
      </CardHeader>
      <CardBody>
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-play-btn"
            viewBox="0 0 16 16"
          >
            <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
          </svg>
          &nbsp;{intl.formatMessage({ id: "INTEGRATION.ADD_FLOATING_BUTTON" })}
        </h4>
        <p className="mt-3">
          {intl.formatMessage({
            id: "INTEGRATION.ADD_FLOATING_BUTTON_INSTRUCTION",
          })}
        </p>
        <div className={classes.scriptBox}>
          <p>{`<div id="redmakiPluginButton" apikey="${key}"></div><script type="text/javascript" src="https://cdn.redmaki.com/button.js"></script>`}</p>
          <Tooltip
            disableFocusListener
            title={scriptCopyText}
            placement="top"
            arrow
          >
            <CopyToClipboard
              text={`<div id="redmakiPluginButton" apikey="${key}"></div><script type="text/javascript" src="https://cdn.redmaki.com/button.js"></script>`}
              className="btn btn-icon btn-light btn-sm ml-3"
            >
              <button
                className="btn btn-icon btn-light btn-sm ml-3"
                onClick={() =>
                  setScriptCopyText(
                    intl.formatMessage({ id: "GENERAL.COPIED" })
                  )
                }
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Clipboard.svg"
                    )}
                  />
                </span>
              </button>
            </CopyToClipboard>
          </Tooltip>
        </div>
      </CardBody>
    </Card>
  );
};

export default injectIntl(FloatingButtonIntegration);
