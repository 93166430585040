/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as hoursActions from '../_redux/hoursActions';

import HoursTable from './HoursTable';
import { CustomSnackbar } from '../../../Components/CustomSnackbar';
import { CustomButton } from '../../../Components/CustomButton';
import { handleCreateOrUpdateHours } from '../../../helpers/hoursHelpers';

const initialValues = {
  hours: [
    [
      {
        id: '',
        day_of_week: 1,
        start_time: '08:00',
        end_time: '18:00',
        closed: false,
      },
    ],
    [
      {
        id: '',
        day_of_week: 2,
        start_time: '08:00',
        end_time: '18:00',
        closed: false,
      },
    ],
    [
      {
        id: '',
        day_of_week: 3,
        start_time: '08:00',
        end_time: '18:00',
        closed: false,
      },
    ],
    [
      {
        id: '',
        day_of_week: 4,
        start_time: '08:00',
        end_time: '18:00',
        closed: false,
      },
    ],
    [
      {
        id: '',
        day_of_week: 5,
        start_time: '08:00',
        end_time: '18:00',
        closed: false,
      },
    ],
    [
      {
        id: '',
        day_of_week: 6,
        start_time: '',
        end_time: '',
        closed: true,
      },
    ],
    [
      {
        id: '',
        day_of_week: 7,
        start_time: '',
        end_time: '',
        closed: true,
      },
    ],
  ],
};

function HoursForm(props) {
  const { intl, selectedHourType } = props;
  const dispatch = useDispatch();
  const { hoursState } = useSelector(
    (state) => ({
      hoursState: state.hours,
    }),
    shallowEqual
  );
  const { loading, error, hours, success } = hoursState;

  const [open, setOpen] = useState(false);
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleReset = () => {
    if (selectedHourType !== 'Company') {
      const { company_id, id: employeeId } = selectedHourType;

      dispatch(
        hoursActions.deleteEmployeeHoursAndReturnCompanyHours(company_id, employeeId)
      );
    } else {
      // formik.setFieldValue('hours', initialValues.hours);
      dispatch(hoursActions.fetchHours());
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: (values) => {
      const errors = [];
      values.hours.forEach((day, index) => {
        day.forEach((item, i) => {
          if (!day[0].closed) {
            if (
              !item.start_time ||
              !item.end_time ||
              item.end_time < item.start_time
            ) {
              if (!errors[index]) {
                errors[index] = [];
              }
              if (!errors[index][i]) {
                errors[index][i] = {};
              }

              if (item.end_time < item.start_time) {
                errors[index][i].end_time = true;
              }

              if (!item.start_time) {
                errors[index][i].start_time = true;
              }

              if (!item.end_time) {
                errors[index][i].end_time = true;
              }
            }
          }
        });
      });
      return errors;
    },
    onSubmit: async (values) => {
      const { hours, hoursType } = values;

      try {
        handleCreateOrUpdateHours(hoursType, hours, dispatch);
        setOpen(true);
      } catch (error) {
        console.log(error);
        setOpen(true);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('hoursType', 'Company');
  }, []);

  useEffect(() => {
    if (selectedHourType === 'Company') {
      formik.setFieldValue('hours', hours);
    } else {
      formik.setFieldValue('hours', hoursState.employees[selectedHourType.id]);
    }
  }, [hoursState, selectedHourType]);

  useEffect(() => {
    if (selectedHourType === 'Company') {
      dispatch(hoursActions.fetchHours());
    } else {
      const { company_id, id: employeeId } = selectedHourType;
      dispatch(hoursActions.fetchHoursByEmployee(company_id, employeeId));
    }
    formik.setFieldValue('hoursType', selectedHourType);
  }, [selectedHourType, dispatch]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='form hours-form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
    >
      {success && !loading && (
        <CustomSnackbar
          alertType='success'
          open={open}
          handleClose={handleClose}
          text={intl.formatMessage({ id: 'GENERAL.CHANGES_SAVED' })}
        />
      )}
      {error && (
        <CustomSnackbar
          alertType='error'
          open={open}
          handleClose={handleClose}
          text={error}
        />
      )}
      <HoursTable
        errors={formik.errors}
        touched={formik.touched}
        values={formik.values.hours}
        status={formik.status}
        setFieldValue={formik.setFieldValue}
        setFieldTouched={formik.setFieldTouched}
        selectedHourType={selectedHourType}
      />
      <div className='form-group d-flex flex-wrap flex-center'>
        <CustomButton
          type='submit'
          variant='primary'
          className='mr-5'
          loading={loading}
          showSpinner
          disabled={formik.isSubmitting}
          onClick={() => formik.setStatus('submitted')}
        >
          <FormattedMessage id='GENERAL.SUBMIT' />
        </CustomButton>

        {selectedHourType !== 'Company' ? (
          <CustomButton
            type='button'
            variant='secondary'
            onClick={handleReset}
          >
            Set back to company hours
          </CustomButton>
        ) : (
          <CustomButton
            type='button'
            variant='secondary'
            onClick={handleReset}
          >
            <FormattedMessage id='HOURS.RESET' />
          </CustomButton>
        )}
      </div>
    </form>
  );
}

export default injectIntl(HoursForm);
