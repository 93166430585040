import React from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

export const CustomSnackbar = ({ open, handleClose, text, alertType }) => {
    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <MuiAlert
                elevation={6}
                variant='filled'
                severity={alertType}
                action={[
                    <IconButton
                        key='close'
                        aria-label='Close'
                        color='inherit'
                        onClick={handleClose}
                        size='small'
                    >
                        <CloseIcon style={{ fontSize: 20 }} />
                    </IconButton>,
                ]}
            >
                {text}
            </MuiAlert>
        </Snackbar>
    );
};
