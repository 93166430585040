import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import { CategoriesPage } from "./Settings/Categories";
import { ServicesPage } from "./Settings/Services";
import { ResourcesPage } from "./Settings/Resources";
import { AddressPage } from "./Settings/Address";
import { HoursPage } from "./Settings/Hours";
import { EmployeesPage } from "./Settings/Employees";
import { GoogleCalendarPage } from "./Settings/GoogleCalendar";
import { OtherSettingsPage } from "./Settings/OtherSettings";
import { BookingSettingsPage } from "./Settings/BookingSettingsPage";

export default function SettingsPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/settings"
        to="/settings/categories"
      />
      <ContentRoute from="/settings/categories" component={CategoriesPage} />
      <ContentRoute from="/settings/services" component={ServicesPage} />
      <ContentRoute from="/settings/resources" component={ResourcesPage} />
      <ContentRoute from="/settings/address" component={AddressPage} />
      <ContentRoute from="/settings/hours" component={HoursPage} />
      <ContentRoute from="/settings/employees" component={EmployeesPage} />
      <ContentRoute from="/settings/booking-settings-page" component={BookingSettingsPage} />
      <ContentRoute from="/settings/google-calendar" component={GoogleCalendarPage} />
      <ContentRoute from="/settings/other-settings" component={OtherSettingsPage} />
      <Redirect push to="/error/error-v1" />
    </Switch>
  );
}
