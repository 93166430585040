import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { makeStyles, Divider, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    "& .card-header": {
      justifyContent: "center !important",
      flexDirection: "column",
    },
  },
  link: {
    color: "#BFBFBF",
    marginRight: "10px",
  },
  domainContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  iframeBox: {
    marginTop: "10px",
    backgroundColor: "#F5F5F5",
    color: "#818181",
    display: "flex",
    alignItems: "center",
    "& p": {
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: "5px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const WebsiteIntegration = (props) => {
  const classes = useStyles();
  const { intl } = props;
  const key = useSelector((state) => state.auth?.user?.company?.apikey);
  const appointmentUrl = `${process.env.REACT_APP_BASE_URL}/${key}`;
  const [linkCopyText, setLinkCopyText] = useState(
    intl.formatMessage({ id: "GENERAL.COPY" })
  );
  const [frameCopyText, setFrameCopyText] = useState(
    intl.formatMessage({ id: "GENERAL.COPY" })
  );

  useEffect(() => {
    setTimeout(() => {
      setFrameCopyText(intl.formatMessage({ id: "GENERAL.COPY" }));
    }, 3000);
  }, [frameCopyText, intl]);
  useEffect(() => {
    setTimeout(() => {
      setLinkCopyText(intl.formatMessage({ id: "GENERAL.COPY" }));
    }, 3000);
  }, [linkCopyText, intl]);

  return (
    <Card className={classes.card}>
      <CardHeader title={intl.formatMessage({ id: "INTEGRATION.WEBSITE" })}>
        <Link className={classes.link} to="/your-website">
          {intl.formatMessage({ id: "INTEGRATION.BACKBUTTON" })}
        </Link>
      </CardHeader>
      <CardBody>
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-link"
            viewBox="0 0 16 16"
          >
            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
          </svg>
          &nbsp;{intl.formatMessage({ id: "INTEGRATION.DIRECT_LINK" })}
        </h4>
        <div className={classes.domainContainer}>
          <a
            href={`${appointmentUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${appointmentUrl}`}
          </a>

          <Tooltip
            disableFocusListener
            title={linkCopyText}
            placement="top"
            arrow
          >
            <CopyToClipboard
              text={`${appointmentUrl}`}
              className="btn btn-icon btn-light btn-sm ml-3"
            >
              <button
                className="btn btn-icon btn-light btn-sm ml-3"
                onClick={() =>
                  setLinkCopyText(intl.formatMessage({ id: "GENERAL.COPIED" }))
                }
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Clipboard.svg"
                    )}
                  />
                </span>
              </button>
            </CopyToClipboard>
          </Tooltip>
        </div>

        <p className="mt-3">
          {intl.formatMessage({ id: "INTEGRATION.WEBSITE_TEXT_TOP" })}
        </p>
        <Divider variant="middle" className="mt-8 mb-8" />
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-calendar3"
            viewBox="0 0 16 16"
          >
            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
          &nbsp;{intl.formatMessage({ id: "INTEGRATION.IFRAME" })}
        </h4>
        <div className={classes.iframeContainer}>
          <p className="mt-3">
            {intl.formatMessage({ id: "INTEGRATION.WEBSITE_TEXT_BOTTOM" })}
          </p>
          <div className={classes.iframeBox}>
            <p>{`<iframe src="${appointmentUrl}" width="100%" height="720" frameBorder="0"></iframe>`}</p>
            <Tooltip
              disableFocusListener
              title={frameCopyText}
              placement="top"
              arrow
            >
              <CopyToClipboard
                text={`<iframe src="${appointmentUrl}" width="100%" height="720" frameBorder="0"></iframe>`}
                className="btn btn-icon btn-light btn-sm ml-3"
              >
                <button
                  className="btn btn-icon btn-light btn-sm ml-3"
                  onClick={() =>
                    setFrameCopyText(
                      intl.formatMessage({ id: "GENERAL.COPIED" })
                    )
                  }
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Clipboard.svg"
                      )}
                    />
                  </span>
                </button>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default injectIntl(WebsiteIntegration);
