import axios from "axios";

export const CATEGORY_URL = "categories";

export async function getAllCategories() {
  return axios.get(`${CATEGORY_URL}`);
}

export function createCategory(category) {
  return axios.post(CATEGORY_URL, { category });
}

export function updateCategory(id, category) {
  return axios.put(`${CATEGORY_URL}/${id}`, { category });
}

export function getCategoryById(categoryId) {
  return axios.get(`${CATEGORY_URL}/${categoryId}`);
}

export function deleteCategory(id) {
  return axios.delete(`${CATEGORY_URL}/${id}`);
}
