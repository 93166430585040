import "date-fns";
import * as Yup from "yup";
import { useFormik } from "formik";
import Switch from "@material-ui/core/Switch";
import TimerIcon from "@material-ui/icons/Timer";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import CategoryIcon from "@material-ui/icons/Category";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { injectIntl, FormattedMessage } from "react-intl";
import InventoryIcon from "@mui/icons-material/Inventory";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as servicesActions from "../../_redux/servicesActions";
import MoneyOutlinedIcon from "@material-ui/icons/MoneyOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CustomButton } from "../../../../Components/CustomButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as categoriesActions from "../../../Categories/_redux/categoriesActions";

function ServiceForm(props) {
  const { intl, toggleDrawer, serviceId } = props;
  const iconColor = { color: "#B5B5C3" };
  const dispatch = useDispatch();

  const ServiceSchema = Yup.object().shape({
    category: Yup.object().nullable(),
    blocksAgenda: Yup.bool(),
    customerCanBook: Yup.bool(),
    extra_email_text: Yup.string().max(
      1024,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
        { max: 1024 }
      )
    ),
    name: Yup.string()
      .max(
        255,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    price: Yup.number()
      .min(0, intl.formatMessage({ id: "GENERAL.VALIDATION.POSITIVE_OR_ZERO" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .lessThan(
        1000000,
        intl.formatMessage(
          { id: "SERVICE.VALIDATION.LESS_THAN" },
          { max: 1000000 }
        )
      ),
    duration: Yup.number()
      .positive()
      .moreThan(
        4,
        intl.formatMessage({ id: "GENERAL.VALIDATION.MORE_THAN" }, { min: 5 })
      )
      .lessThan(
        1441,
        intl.formatMessage(
          { id: "GENERAL.VALIDATION.LESS_THAN" },
          { max: 1440 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(() => {
    dispatch(servicesActions.fetchService(serviceId));
  }, [serviceId, dispatch]);

  useEffect(() => {
    dispatch(categoriesActions.fetchAllCategories());
  }, [dispatch]);

  const [initialValues, setInitialValues] = useState({
    category: null,
    name: "",
    price: "",
    duration: "",
    extra_email_text: "",
    parts: [],
    blocksAgenda: true,
    customerCanBook: true,
    resources: [],
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ServiceSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const service = {
        duration: values.duration,
        name: values.name,
        price: values.price,
        category_id: values.category ? values.category.id : null,
        extra_email_text: values.extra_email_text,
        blocks_agenda: values.blocksAgenda,
        parts: values.parts,
        customers_can_book: values.customerCanBook,
        company_id: values.company_id,
        resources: values.resources,
      };
      try {
        if (serviceId) {
          await dispatch(
            servicesActions.updateService(serviceId, { ...service })
          );
        } else {
          await dispatch(servicesActions.createService({ ...service }));
        }
        dispatch(servicesActions.fetchAllServices());
        toggleDrawer(false)(null);
      } catch (e) {
        console.error(e);
      }
    },
  });

  const { servicesState, categoriesState, resourcesState } = useSelector(
    (state) => ({
      servicesState: state.services,
      categoriesState: state.categories,
      resourcesState: state.resources,
    }),
    shallowEqual
  );

  const {
    error: servicesError,
    servicesLoading,
    serviceForEdit,
  } = servicesState;

  const { categories, error: categoryError } = categoriesState;
  const { resources, error: resourcesError } = resourcesState;

  const components = serviceForEdit?.components;

  const hasComponents = serviceForEdit && serviceForEdit?.components.length > 0;
  const isNewService = !serviceForEdit;
  const allServices = isNewService
    ? servicesState.services
    : servicesState.services.filter((s) => s.id !== serviceForEdit.id);

  const remainingBlankComponents = () => {
    if (hasComponents && !isNewService) {
      return 5 - components.length;
    } else if (!hasComponents && !isNewService) {
      return 5;
    } else if (isNewService) {
      return 5;
    }
  };
  const blankComponentsArray = new Array(remainingBlankComponents());

  if (hasComponents) {
    components.map((c) => blankComponentsArray.fill(c));
  } else if (!hasComponents && !isNewService) {
    blankComponentsArray.fill({ name: "No options" });
  } else if (isNewService) {
    allServices.map((s) => blankComponentsArray.fill(s));
  }

  const remainingPositions = (list) => {
    return 5 - list?.length;
  };

  const getInitialPosition = (list, index) => {
    switch (remainingPositions(list)) {
      case 5:
        return 1 + index;
      case 4:
        return 2 + index;
      case 3:
        return 3 + index;
      case 2:
        return 4 + index;
      case 1:
        return 5 + index;
      default:
        break;
    }
  };

  const handleChange = (e, selectedComponent, reason, component) => {
    let newParts = [...formik.values.parts];
    let totalDuration;

    if (reason === "select-option") {
      newParts = [...newParts, selectedComponent];

      selectedComponent !== null && formik.setFieldValue("parts", newParts);

      totalDuration = newParts.reduce((a, { duration }) => a + duration, 0);
    } else if (reason === "clear") {
      const toRemoveIndex = newParts.findIndex((p) => p.id === component.id);

      if (toRemoveIndex) {
        newParts.splice(toRemoveIndex, 1);
      }

      totalDuration = newParts.reduce((a, { duration }) => a + duration, 0);
    }

    formik.setFieldValue("parts", newParts);
    formik.setFieldValue("duration", totalDuration);
  };

  const [categoryValue, setCategory] = useState("");
  const [resourceValue, setResource] = useState("");

  useEffect(() => {
    setInitialValues({
      category: serviceForEdit?.category,
      price: serviceForEdit?.price || 0,
      duration: serviceForEdit?.duration || 0,
      name: serviceForEdit?.name || "",
      parts: components || [],
      extra_email_text: serviceForEdit?.extra_email_text || "",
      blocksAgenda: !serviceForEdit
        ? true
        : serviceForEdit?.blocks_agenda !== 0
          ? true
          : false,
      customerCanBook: !serviceForEdit
        ? true
        : serviceForEdit?.customers_can_book !== 0
          ? true
          : false,
      company_id: serviceForEdit?.company_id,
      resources: serviceForEdit?.resources[0],
    });
  }, [serviceForEdit, components]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
    >
      <div className="form-group fv-plugins-icon-container">
        {(servicesError || categoryError || resourcesError) && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">
              {servicesError || categoryError | resourcesError}
            </div>
          </div>
        )}
        <Autocomplete
          id="category"
          onChange={(e, value) => {
            formik.setFieldTouched("category", true);
            formik.setFieldValue("category", value);
          }}
          inputValue={categoryValue}
          onInputChange={(_, newInputValue) => {
            setCategory(newInputValue);
          }}
          options={categories || []}
          getOptionLabel={(option) => option.name}
          name="category"
          defaultValue={serviceForEdit && initialValues.category}
          value={formik.values.category}
          renderInput={(params) => (
            <TextField
              {...{
                ...params,
                InputProps: {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <CategoryIcon style={iconColor} />
                    </InputAdornment>
                  ),
                },
              }}
              error={!!(formik.touched.category && formik.errors.category)}
              helperText={formik.errors.category}
              label={intl.formatMessage({ id: "SERVICE.CATEGORY" })}
              margin="dense"
              variant="outlined"
            />
          )}
        />
        <TextField
          id="name"
          error={!!(formik.touched.name && formik.errors.name)}
          label={intl.formatMessage({ id: "SERVICE.DESCRIPTION" })}
          type="text"
          name="name"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.name ? formik.errors.name : null}
          InputLabelProps={{
            shrink: true,
          }}
          {...formik.getFieldProps("name")}
        />
        <TextField
          id="price"
          label={intl.formatMessage({ id: "SERVICE.PRICE" })}
          type="number"
          margin="dense"
          fullWidth
          error={!!(formik.touched.price && formik.errors.price)}
          helperText={formik.errors.price ? formik.errors.price : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MoneyOutlinedIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          {...formik.getFieldProps("price")}
        />
        <TextField
          id="extra_email_text"
          label={intl.formatMessage({ id: "SERVICE.OPTIONAL_TEXT" })}
          multiline
          rows={4}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          error={
            !!(
              formik.touched.extra_email_text && formik.errors.extra_email_text
            )
          }
          helperText={
            formik.errors.extra_email_text
              ? formik.errors.extra_email_text
              : null
          }
          {...formik.getFieldProps("extra_email_text")}
        />
        <TextField
          id="duration"
          label={intl.formatMessage({ id: "SERVICE.LENGTH" })}
          type="number"
          margin="dense"
          fullWidth
          error={!!(formik.touched.duration && formik.errors.duration)}
          helperText={
            formik.errors.duration
              ? formik.errors.duration
              : intl.formatMessage({ id: "SERVICE.DURATION_CALCULATED" })
          }
          InputProps={{
            readOnly:
              serviceForEdit?.components.length > 0 ||
                (isNewService && formik.values.parts.length > 0)
                ? true
                : false,
            startAdornment: (
              <InputAdornment position="start">
                <TimerIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          {...formik.getFieldProps("duration")}
        />

        {!isNewService &&
          hasComponents &&
          components.map((component, index) => {
            return (
              <Autocomplete
                id={`Component-${index + 1}`}
                key={component.id}
                onChange={(e, selectedComponent, reason) => {
                  handleChange(e, selectedComponent, reason, component);
                }}
                loading={servicesLoading}
                options={allServices}
                value={
                  formik.values.parts.length > 0 &&
                  formik.values.parts.find((p) => p === component)
                }
                getOptionLabel={(component) => component.name}
                style={{ width: "100%", margin: "10px 0" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params.id}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            );
          })}

        {!isNewService &&
          blankComponentsArray.map((component, index) => {
            return (
              <Autocomplete
                id={`Component-${getInitialPosition(components, index)}`}
                key={component.name + index}
                loading={servicesLoading}
                getOptionLabel={(component) => component.name}
                options={allServices}
                onChange={(e, selectedComponent, reason) =>
                  handleChange(e, selectedComponent, reason, component)
                }
                noOptionsText={intl.formatMessage({
                  id: "SERVICE.COMPONENT_NO_OPTIONS",
                })}
                style={{ width: "100%", margin: "10px 0" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params.id}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            );
          })}

        {isNewService &&
          blankComponentsArray.map((service, index) => {
            return (
              <Autocomplete
                id={`Component-${index + 1}`}
                key={`Service - ${service.name} - ${index + 1}`}
                loading={servicesLoading}
                options={allServices}
                onChange={(e, selectedComponent, reason) => {
                  handleChange(e, selectedComponent, reason, service);
                }}
                getOptionLabel={(service) => service.name}
                style={{ width: "100%", margin: "10px 0" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params.id}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            );
          })}
        <Autocomplete
          id="resources"
          onChange={(e, value) => {
            formik.setFieldTouched("resources", true);
            formik.setFieldValue("resources", value);
          }}
          inputValue={resourceValue}
          onInputChange={(_, newInputValue) => {
            setResource(newInputValue);
          }}
          options={resources || []}
          getOptionLabel={(option) => option.name}
          name="resources"
          defaultValue={serviceForEdit && initialValues.resources}
          value={formik.values.resources}
          renderInput={(params) => (
            <TextField
              {...{
                ...params,
                InputProps: {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <InventoryIcon style={iconColor} />
                    </InputAdornment>
                  ),
                },
              }}
              error={!!(formik.touched.resources && formik.errors.resources)}
              helperText={formik.errors.resources}
              label={intl.formatMessage({ id: "SERVICE.RESOURCE" })}
              margin="dense"
              variant="outlined"
            />
          )}
        />
        <FormControlLabel
          control={
            <Switch
              checked={formik.values?.blocksAgenda}
              onChange={(value) =>
                formik.setFieldValue(
                  "blocksAgenda",
                  !formik.values?.blocksAgenda
                )
              }
            />
          }
          label={intl.formatMessage({ id: "SERVICE.BLOCKS_AGENDA" })}
        />
        <FormControlLabel
          control={
            <Switch
              checked={formik.values?.customerCanBook}
              onChange={(value) =>
                formik.setFieldValue(
                  "customerCanBook",
                  !formik.values?.customerCanBook
                )
              }
            />
          }
          label={intl.formatMessage({ id: "SERVICE.CUSTOMER_CAN_BOOK" })}
        />
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <CustomButton type="submit" variant="primary" className="mr-5">
          <FormattedMessage id="GENERAL.SUBMIT" />
        </CustomButton>
        <CustomButton
          variant="secondary"
          type="button"
          onClick={toggleDrawer(false)}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </CustomButton>
      </div>
    </form>
  );
}

export default injectIntl(ServiceForm);
