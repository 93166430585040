import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useIntl } from 'react-intl';

import { fetchAllEmployees } from '../../Employees/_redux/employeesActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    hoursTypeDropdown: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: 0,
            marginRight: '1rem',
        },
    },
}));

export const HoursTypeSelector = ({
    selectedHourType,
    setSelectedHourType,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const employees = useSelector((state) => state.employees.employees);

    const handleChangeHourType = (type) => {
        setSelectedHourType(type);
    };

    useEffect(() => {
        dispatch(fetchAllEmployees());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.hoursTypeDropdown}>
            <p>{intl.formatMessage({ id: 'HOURS.TYPE.SELECTOR.LABEL' })}</p>
            <DropdownButton
                id='hoursType'
                title={selectedHourType.name ? selectedHourType.name : intl.formatMessage({ id: 'HOURS.TYPE.SELECTOR.COMPANY' })}
            >
                <Dropdown.Item
                    as='button'
                    onClick={() => handleChangeHourType('Company')}
                    active={selectedHourType === 'Company'}
                >
                    {intl.formatMessage({ id: 'HOURS.TYPE.SELECTOR.COMPANY' })}
                </Dropdown.Item>
                <Divider className="my-3" />
                {employees?.map((employee) => (
                    <Dropdown.Item
                        as='button'
                        key={employee.id}
                        onClick={() => handleChangeHourType(employee)}
                        active={selectedHourType === employee}
                    >
                        {employee.name}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </div>
    );
};
