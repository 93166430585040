import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import * as actions from "../../_redux/customers/customersActions";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import ActionsColumnFormatter from "./ActionsColumnFormatter";

function CustomersTable(props) {

  const { intl, toggleDrawer } = props;
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // Getting current state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );
  const { total, customers, listLoading } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchCustomers(customersUIProps.queryParams));
  }, [customersUIProps, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "action",
      text: intl.formatMessage({id: "GENERAL.ACTIONS"}),
      formatter: (value, row) => <ActionsColumnFormatter row={row} toggleDrawer={toggleDrawer}/>,
      style: {
        minWidth: "120px",
      },
    },
    {
      dataField: "name",
      text: intl.formatMessage({id: "CUSTOMER.NAME"}),
      formatter: (cellContent, row) => <div>{row.firstName} {row.lastName}</div>,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: intl.formatMessage({id: "CUSTOMER.EMAIL"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "phoneNumber",
      text: intl.formatMessage({id: "CUSTOMER.PHONE"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {intl.formatMessage({id: "CUSTOMER.SHOWING_ROWS"})} { from } {intl.formatMessage({id: "CUSTOMER.TO"})} { to } {intl.formatMessage({id: "CUSTOMER.OF"})} { size }
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: total,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.page,
    paginationTotalRenderer: customTotal
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="email"
                data={customers === null ? [] : customers}
                columns={columns}
                noDataIndication={()=> intl.formatMessage({id: "CUSTOMER.NO_CUSTOMERS"})}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(customersUIProps.setQueryParams)}
                {...paginationTableProps}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

export default injectIntl(CustomersTable);

