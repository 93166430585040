import axios from "axios";

export const HOURS_URL = "hours";
export const HOUR_URL = "hour";

export async function getHours() {
  return axios.get(`${HOURS_URL}`);
}

export async function getHoursByEmployee(companyId, employeeId) {
  return axios.get(`${HOURS_URL}/${companyId}/employees/${employeeId}`);
}

export async function getExtraHoursByEmployee(companyId, employeeId, startDate, endDate) {
  return axios.get(`${HOURS_URL}/${companyId}/employees/${employeeId}/${startDate}/${endDate}`);
}

export async function deleteEmployeeHoursAndReturnCompanyHours(companyId, employeeId) {
  return axios.delete(`${HOURS_URL}/${companyId}/employees/${employeeId}`);
}

export function createHour(hour) {
  return axios.post(HOUR_URL, { hour });
}

export function updateHour(hour) {
  return axios.put(`${HOUR_URL}/${hour.id}`, { hour });
}

export function deleteHour(id) {
  return axios.delete(`${HOUR_URL}/${id}`);
}
