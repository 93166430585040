import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import * as actions from "../../_redux/resourcesActions";
import ActionsColumnFormatter from "./ActionsColumnFormatter";
import MuiAlert from '@material-ui/lab/Alert';
import { Errors } from '../../../../Components/Errors';

function ResourcesTable(props) {

  const { intl, toggleDrawer } = props;
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.resources }),
    shallowEqual
  );
  const { resources } = currentState;

  useEffect(() => {
    dispatch(actions.fetchAllResources());
  }, [dispatch]);

  const columns = [
    {
      dataField: "action",
      text: intl.formatMessage({ id: "GENERAL.ACTIONS" }),
      formatter: (value, row) => <ActionsColumnFormatter row={row} toggleDrawer={toggleDrawer} />,
      headerStyle: (column, colIndex) => {
        return { width: '125px', textAlign: 'left' };
      }
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "RESOURCE.DESCRIPTION" }),
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "RESOURCE.TYPE" }),
      formatter: (value, row) => <span>{intl.formatMessage({ id: "RESOURCE.TYPE." + value })}</span>,
    }
  ];

  const noResourcesMessage = () => {
    return (
      <MuiAlert elevation={0} variant="outlined" severity="info" >
        {intl.formatMessage({ id: "RESOURCE.NO_RESOURCE" })}
      </MuiAlert>
    );
  };

  return (
    <>
      <Errors />
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        loading={true}
        remote
        keyField="name"
        data={resources === null ? [] : resources}
        columns={columns}
        noDataIndication={noResourcesMessage}
      />

    </>
  );
}

export default injectIntl(ResourcesTable);
