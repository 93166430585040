import React from "react";
import { GoogleLogout } from "react-google-login";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { actions } from "../Auth/_redux/authRedux";

const Logout = () => {
  const googleAppId = process.env.REACT_APP_GOOGLE_ID;
  const dispatch = useDispatch();
  const onSuccess = () => {
    dispatch(actions.googleSignOut());
  };

  return (
    <div>
      <GoogleLogout
        clientId={googleAppId}
        onLogoutSuccess={onSuccess}
        buttonText={<FormattedMessage id="GOOGLE.LOGOUT" />}
      />
    </div>
  );
};

export default Logout;
