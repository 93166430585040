import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  OnBoarded: "[OnBoarded] Action",
  GoogleSignin: "[Google Login] Action",
  GoogleSignout: "[Google Logout] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  newUser: false,
  googleInfo: null,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v705-redmaki-web-frontend-auth",
    whitelist: ["user", "authToken"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { authToken, user: undefined, newUser: false };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined, newUser: true };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { 
          ...state, 
          user,
          googleInfo: {
            profile: user.google_profile
          },
        };
      }

      case actionTypes.OnBoarded: {
        return { ...state, newUser: false };
      }

      case actionTypes.GoogleSignin: {
        return {
          ...state,
          googleInfo: {
            profile: action.payload
          },
        };
      }

      case actionTypes.GoogleSignout: {
        return { ...state, googleInfo: null };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  passOnBoarding: () => ({ type: actionTypes.OnBoarded }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  googleSignIn: (googleInfo) => ({
    type: actionTypes.GoogleSignin,
    payload: googleInfo,
  }),
  googleSignOut: () => ({
    type: actionTypes.GoogleSignout,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
