/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import { isAfter, isSameDay } from "date-fns";
import "date-fns";
import { nl, enUS } from "date-fns/locale";
import { TextField, MenuItem, Link, Divider, InputAdornment } from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import * as employeesAction from "../../../Employees/_redux/employeesActions";
import * as appointmentsAction from "../../_redux/appointmentsActions";
import {
  convertDateToString,
  formatDate,
  formatDateToTime,
  setTimeToDate,
  minTime
} from "../../../../helpers/dateHelpers";
import { CustomButton } from "../../../../Components/CustomButton";

import { appointmentsSlice } from "../../_redux/appointmentsSlice";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getCurrentLanguage } from "../../../../../_metronic/i18n";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const initialValues = {
  employee: null,
  remark: "",
  start: formatDateToTime(new Date()),
  end: formatDateToTime(new Date()),
  date: new Date(),
  type: "",
  company: "",
  repeat: "",
  repeat_times: "",
};

function BlockForm(props) {
  const { intl, appointmentId, toggleDrawer, start, setDrawer, onAppointmentChange, selectedEmployee } = props;
  const iconColor = { color: "#B5B5C3" };
  const { user } = useSelector((state) => state.auth);
  const { actions } = appointmentsSlice;

  const language = getCurrentLanguage();
  const datePickerLocale = language?.selectedLang === "en" ? enUS : nl;

  const AppointmentSchema = Yup.object().shape({
    start: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    end: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ).test("min", intl.formatMessage({
      id: "APPOINTMENTS.ERROR.MIN.END.DATE",
    }), 
    function(value, data) {
       return minTime(data.parent.start, value)
    }),
    date: Yup.date().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    employee: Yup.object().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    type: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    remark: Yup.string().nullable(),
    repeat: Yup.string().nullable(),
    repeat_times: Yup.number()
      .positive()
      .moreThan(
        0,
        intl.formatMessage({ id: "GENERAL.VALIDATION.MORE_THAN" }, { min: 0 })
      )
      .lessThan(
        366,
        intl.formatMessage({ id: "GENERAL.VALIDATION.LESS_THAN" }, { max: 365 })
      )
      .nullable(),
  });

  const [initialValuesState, setInitialValueState] = useState(initialValues);

  const formik = useFormik({
    initialValues: initialValuesState,
    validationSchema: AppointmentSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const startTime = setTimeToDate(values.date, values.start);
      const endTime = setTimeToDate(values.date, values.end);
      const isEndDateSameDay = isSameDay(startTime, endTime);
      const isEndDateAfterStartDate = isAfter(endTime, startTime);
      const appointment = {
        employee_id: values.employee.id,
        start: convertDateToString(startTime),
        end: convertDateToString(endTime),
        date: convertDateToString(values.date),
        remark: values.remark,
        company_id: values.company,
        type: values.type,
        repeat: values.repeat,
        repeat_times: values.repeat_times,
      };

      if (!isEndDateAfterStartDate) {
        formik.setFieldError(
          "end",
          intl.formatMessage({ id: "APPOINTMENTS.ERROR.MIN.END.DATE" })
        );
        return;
      }

      if (!isEndDateSameDay) {
        formik.setFieldError(
          "end",
          intl.formatMessage({ id: "APPOINTMENTS.ERROR.MAX.END.DATE" })
        );
        return;
      }

      if (appointmentId) {
        await dispatch(
          appointmentsAction.updateAppointment(appointmentId, {
            ...appointment,
          }, toggleDrawer)
        );
      } else {
        await dispatch(
          appointmentsAction.createAppointment({ ...appointment }, toggleDrawer)
        );
      }
      // toggleDrawer(false)(null);
    },
  });

  const { employeesState, appointmentsState } = useSelector(
    (state) => ({
      employeesState: state.employees,
      appointmentsState: state.appointments,
    }),
    shallowEqual
  );

  const {
    employeesByServiceId: employees,
    error: employeesError,
  } = employeesState;
  const {
    error: appointmentsError,
    appointmentForEdit,
    actionsLoading,
  } = appointmentsState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appointmentsAction.fetchAppointment(appointmentId));
  }, [appointmentId, dispatch]);

  const startDate = useMemo(() => {
    return appointmentForEdit?.startElements && formatDate(appointmentForEdit.startElements)
  }, [appointmentForEdit?.startElements]);
  
  const endDate = useMemo(() => {
    return  appointmentForEdit?.startElements && formatDate(appointmentForEdit.endElements)
  }, [appointmentForEdit?.startElements, appointmentForEdit?.endElements]);

  const [values, setValues] = useState({ employee: null });

  const getInitialDate = useCallback((dateType) => {
    if (appointmentForEdit) {
      return dateType === "start" ? startDate : endDate;
    } else if (!appointmentForEdit && start !== "undefined") {
      return start;
    } else {
      return new Date();
    }
  }, [appointmentForEdit, startDate, endDate, start]);

  useMemo(() => {
    let employee;

    if (appointmentForEdit) {
      employee = appointmentForEdit.employee;
    } else {
      employee = employeesState.employees.find((e) => e.id === selectedEmployee);
    }

    setValues({
      employee: employee,
    });
    setInitialValueState({
      remark: appointmentForEdit?.remark || "",
      start: formatDateToTime(getInitialDate("start")),
      end: formatDateToTime(getInitialDate("end")),
      date: getInitialDate("start"),
      employee: employee,
      type: appointmentForEdit?.type || "",
      company: user?.company?.id,
      repeat: appointmentForEdit?.repeat || "",
      repeat_times: appointmentForEdit?.repeat_times || "",
    });
  }, [user, appointmentForEdit, selectedEmployee]);

  useEffect(() => {
    dispatch(
      employeesAction.fetchEmployeesByService(
        formik.values.company,
        formik.values.service
      )
    );
  }, [formik.values.company, formik.values.service, dispatch]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
    >
      <div className="form-group fv-plugins-icon-container">
        {(employeesError || appointmentsError) && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">
              {employeesError || appointmentsError}
            </div>
          </div>
        )}
        {appointmentForEdit?.appointment?.repeat_times > 0 && (
          <Alert severity="info" className="mb-3">
            {intl.formatMessage({
              id: "APPOINTMENTS.APPOINTMENT_PART_OF_REPEAT",
            })}
            &nbsp;
            <Link
              href="#"
              onClick={async () => {
                await dispatch(
                  appointmentsAction.deleteAppointment(
                    appointmentForEdit.appointment.id
                  )
                );
                toggleDrawer(false)(null);
              }}
            >
              {intl.formatMessage({ id: "APPOINTMENTS.DELETE_SERIES" })}
            </Link>
          </Alert>
        )}
        {(!appointmentId || (values.employee && !actionsLoading)) && (
          <Autocomplete
            // id="employee"
            name='employee'
            value={formik.values.employee || null}
            onChange={(e, value) => {
              formik.setFieldTouched("employee", true);
              formik.setFieldValue("employee", value);
            }}
            options={employees || []}
            getOptionLabel={(option) => {
              const employee = employeesState?.employees?.find(e => e.id === option.id);
              return `${employee?.firstName} ${employee?.lastName}`;
            }}
            getOptionSelected={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...{
                  ...params,
                  InputProps: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon style={iconColor} />
                      </InputAdornment>
                    ),
                  },
                }}
                error={!!(formik.touched.employee && formik.errors.employee)}
                helperText={formik.errors.employee}
                label={intl.formatMessage({
                  id: "APPOINTMENTS.SELECT_EMPLOYEE",
                })}
                margin="dense"
                variant="outlined"
              />
            )}
          />
        )}
        <TextField
          error={!!(formik.touched.type && formik.errors.type)}
          helperText={formik.errors.type}
          select
          id="type"
          label={intl.formatMessage({ id: "APPOINTMENTS.TYPE" })}
          margin="dense"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          {...formik.getFieldProps("type")}
        >
          <MenuItem key="ILLNESS" value="ILLNESS">
            {intl.formatMessage({ id: "APPOINTMENTS.ILLNESS" })}
          </MenuItem>
          <MenuItem key="LEAVE" value="LEAVE">
            {intl.formatMessage({ id: "APPOINTMENTS.LEAVE" })}
          </MenuItem>
          <MenuItem key="PUBLICHOLIDAY" value="PUBLICHOLIDAY">
            {intl.formatMessage({ id: "APPOINTMENTS.PUBLICHOLIDAY" })}
          </MenuItem>
          <MenuItem key="OTHER" value="OTHER">
            {intl.formatMessage({ id: "GENERAL.OTHER" })}
          </MenuItem>
        </TextField>
        <Divider light className="mt-4 mb-4" />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={datePickerLocale}
        >
          <div className="d-flex flex-row">
            <div className="col-4 col-sm-3 col-md-3 p-0 pr-2">
              <TextField
                id="start"
                error={!!(formik.touched.start && formik.errors.start)}
                helperText={formik.errors.start}
                label={intl.formatMessage({ id: "APPOINTMENTS.STARTTIME" })}
                fullWidth
                margin="dense"
                variant="outlined"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values?.start}
                onChange={(e) => formik.setFieldValue("start", e.target.value)}
              />
            </div>
            <div className="col-4 col-sm-3 col-md-3 p-0 mr-2 pr-1">
              <TextField
                id="end"
                error={!!(formik.touched.end && formik.errors.end)}
                helperText={formik.errors.end}
                label={intl.formatMessage({ id: "APPOINTMENTS.ENDTIME" })}
                fullWidth
                margin="dense"
                variant="outlined"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values?.end}
                onChange={(e) => formik.setFieldValue("end", e.target.value)}
              />
            </div>
            <div className="col-4 col-sm-6 col-md-6 pl-2 pr-0">
            <DatePicker
              id="date"
              renderInput={(props) => (
                <TextField
                  {...props}
                  error={!!(formik.touched.date && formik.errors.date)}
                  helperText={formik.errors.date}
                  margin="dense"
                  variant="outlined"
                  style={{ width: "97%" }}
                />
              )}
              label={intl.formatMessage({ id: "APPOINTMENTS.DATE" })}
              value={formik.values?.date}
              onChange={(value) => formik.setFieldValue("date", value)}
              format="dd-MM-yyyy"
              hideTabs={true}
              showToolbar={false}
            />
            </div>
          </div>
        </LocalizationProvider>
        <Divider light className="mt-4 mb-4" />
        <TextField
          id="remark"
          label={intl.formatMessage({ id: "APPOINTMENTS.DESCRIPTION" })}
          multiline
          rows={4}
          fullWidth
          margin="dense"
          variant="outlined"
          {...formik.getFieldProps("remark")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {appointmentForEdit === undefined && (
          <div className="row">
            <div className="col-sm-8">
              <TextField
                error={!!(formik.touched.repeat && formik.errors.repeat)}
                helperText={formik.errors.repeat}
                select
                id="repeat"
                label={intl.formatMessage({ id: "APPOINTMENTS.REPEAT" })}
                margin="dense"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("repeat")}
              >
                <MenuItem key="X" value=""></MenuItem>
                <MenuItem key="D" value="D">
                  {intl.formatMessage({ id: "APPOINTMENTS.DAILY" })}
                </MenuItem>
                <MenuItem key="W" value="W">
                  {intl.formatMessage({ id: "APPOINTMENTS.WEEKLY" })}
                </MenuItem>
                <MenuItem key="M" value="M">
                  {intl.formatMessage({ id: "APPOINTMENTS.MONTHLY" })}
                </MenuItem>
                <MenuItem key="Y" value="Y">
                  {intl.formatMessage({ id: "APPOINTMENTS.YEARLY" })}
                </MenuItem>
              </TextField>
            </div>
            <div className="col-sm-4">
              <TextField
                id="repeat_times"
                label={intl.formatMessage({ id: "APPOINTMENTS.REPEAT_TIMES" })}
                margin="dense"
                variant="outlined"
                type="number"
                fullWidth
                InputProps={{
                  inputProps: { min: 0, max: 365 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("repeat_times")}
              />
            </div>
          </div>
        )}
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <CustomButton
          type="submit"
          variant='primary'
          disabled={formik.isSubmitting}
          loading={actionsLoading}
          showSpinner
          className="mr-5"
        >
          <FormattedMessage id="GENERAL.SUBMIT" />
        </CustomButton>
        <CustomButton
          variant='secondary'
          type="button"
          className="mr-5"
          onClick={() => dispatch(appointmentsAction.resetAppointmentForEdit(toggleDrawer))}
        >
          <FormattedMessage id="GENERAL.CLOSE" />
        </CustomButton>
        {appointmentId && (
          <CustomButton
            variant='outline-danger'
            type="button"
            onClick={() => {
              dispatch(appointmentsAction.deleteAppointment(appointmentId)).then(() => {
                onAppointmentChange(null);
                setDrawer(false);
                actions.appointmentDrawerClose();
              });
            }}
          >
            <FormattedMessage id="APPOINTMENTS.CANCEL_APPOINTMENT" />
          </CustomButton>
        )}
      </div>

    </form>
  );
}

export default injectIntl(BlockForm);
