/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

export const useWindowDimensions = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { innerWidth: width } = window;

    useEffect(() => {
        if (width <= 768) {
            setIsMobile(true);
        }
    }, []);

    return {
        isMobile
    };
};

