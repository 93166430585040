/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function UniversalTileWidget({
  backgroundColor,
  widgetHeight = "150px",
  provider,
}) {
  return (
    <>
      <div
        className={`card card-custom `}
        style={{
          height: widgetHeight,
          width: "100%",
          backgroundColor: backgroundColor,
        }}
      >
        <div className="card-body">
          <span
            style={{ color: "white" }}
            className="svg-icon svg-icon-3x svg-icon-white ml-n2"
          >
            {provider.icon}
          </span>
          <div className={`text-light font-weight-bolder font-size-h2 mt-3`}>
            {provider.title}
          </div>
        </div>
      </div>
    </>
  );
}
