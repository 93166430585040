import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import CustomersFilter from "./customers-filter/CustomersFilter";
import CustomersTable from "./customers-table/CustomersTable";
import { injectIntl, FormattedMessage } from "react-intl";
import CustomerDrawer from "./CustomerDrawer/CustomerDrawer";

export function CustomersCard(props) {

  const {intl} = props;
  const [drawer, setDrawer] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const toggleDrawer = (open, id) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
    setCustomerId(id)
  };

  return (
    <Card>
      <CardHeader title={intl.formatMessage({id: "CUSTOMER.CUSTOMERS_LIST"})}>
        <CardHeaderToolbar>
          <CustomersFilter />
          <button
            type="button"
            className="btn btn-primary"
            onClick={toggleDrawer(true)}
          >
            <FormattedMessage id={"CUSTOMER.ADD_CUSTOMER"}/>
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersTable toggleDrawer={toggleDrawer}/>
      </CardBody>
      <CustomerDrawer open={drawer} toggleDrawer={toggleDrawer} customerId={customerId}/>
    </Card>
  );
}

export default injectIntl(CustomersCard);
