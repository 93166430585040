import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import { InputAdornment, IconButton } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CustomButton } from "../../../Components/CustomButton";

const initialValues = {
  fullName: "",
  email: "",
  companyName: "",
  password: ""
};

function Registration(props) {

  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const RegistrationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 3 }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: 50 }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT" }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: 50 }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    companyName: Yup.string()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 3 }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: 50 }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 8 }))
      .matches(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z]).*$/, intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_ERROR" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.fullName, values.password, values.companyName, intl.locale)
        .then(({ data: { accessToken } }) => {
          props.register(accessToken);
          disableLoading();
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          const errors = error.response.data.errors;
          setStatus(errors[Object.keys(errors)[0]][0]);
          disableLoading();
        });
    }
  });

  return (
    <Fade in={true} timeout={1000}>
      <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-5 mb-lg-10">
          <img
            alt="Logo"
            height="40"
            className="mb-15"
            src={toAbsoluteUrl("/media/logos/logo-light.png")}
          />
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.REGISTER.DESC" />
          </p>
        </div>

        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* end: Alert */}

          <TextField
            error={!!(formik.touched.companyName && formik.errors.companyName)}
            id="companyName"
            label={intl.formatMessage({ id: "AUTH.REGISTER.COMPANY_NAME" })}
            placeholder={intl.formatMessage({ id: "AUTH.REGISTER.COMPANY_NAME_PLACEHOLDER" })}
            name="companyName"
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            helperText={formik.errors.companyName}
            {...formik.getFieldProps("companyName")}
          />
          <TextField
            error={!!(formik.touched.fullName && formik.errors.fullName)}
            id="fullName"
            label={intl.formatMessage({ id: "AUTH.INPUT.FULL_NAME" })}
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.FULL_NAME_PLACEHOLDER" })}
            name="fullName"
            margin="normal"
            variant="outlined"
            helperText={formik.errors.fullName}
            InputLabelProps={{
              shrink: true,
            }}
            {...formik.getFieldProps("fullName")}
          />
          <TextField
            error={!!(formik.touched.email && formik.errors.email)}
            id="email"
            label={intl.formatMessage({ id: "AUTH.GENERAL.EMAIL" })}
            placeholder={intl.formatMessage({ id: "AUTH.GENERAL.EMAIL_PLACEHOLDER" })}
            type="email"
            name="email"
            margin="normal"
            variant="outlined"
            autoComplete="username"
            helperText={formik.errors.email}
            InputLabelProps={{
              shrink: true,
            }}
            {...formik.getFieldProps("email")}
          />
          <TextField
            error={!!(formik.touched.password && formik.errors.password)}
            id="password"
            label={intl.formatMessage({ id: "AUTH.GENERAL.PASSWORD" })}
            placeholder={intl.formatMessage({ id: "AUTH.GENERAL.PASSWORD_PLACEHOLDER" })}
            name="password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            autoComplete="new-password"
            helperText={formik.errors.password}
            {...formik.getFieldProps("password")}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <div className="form-group d-flex flex-wrap flex-center">
            <CustomButton
              variant='primary'
              type="submit"
              disabled={formik.isSubmitting}
              showSpinner
              loading={loading}
              className="mr-5"
            >
              <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
            </CustomButton>
          </div>
        </form>
        <div>
          <span>{intl.formatMessage({ id: "AUTH.REGISTER.I_ACCEPT" })} </span>
          <a target="_blank" rel="noopener noreferrer" href="https://redmaki.com/terms">
            <FormattedMessage id="AUTH.GENERAL.TERMS_AND_CONDITION" />
          </a>
        </div>
      </div>
    </Fade>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
