/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";

export function QuickActionsDropdown() {
  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="kt_quick_actions_panel_toggle"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-actions-tooltip"><FormattedMessage id="MENU.QUICK_ACTION" /></Tooltip>
          }
        >
          <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                  />
                </span>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
        <form>
          {/* begin: Head */}
          <div
            className="d-flex flex-column align-items-center justify-content-center pt-10 pb-10 bgi-size-cover bgi-no-repeat rounded-top"
          >
            <h3 className="text-black font-weight-bold font-size-5">
              Quick Actions
            </h3>
          </div>
          {/* end: Head */}
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
}
