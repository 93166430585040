/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import * as serviceWorkerRegistration from './../serviceWorkerRegistration';

import { NewVersionSnackbar } from './NewVersionSnackbar';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const App = ({ store, persistor, basename }) => {

  Bugsnag.start({
    apiKey: 'f08777a206f8f6f7075fad2de8ba1f95',
    plugins: [new BugsnagPluginReact()]
  });
  
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)  
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const onConfirmNewVersion = () => {
    setSnackbarOpen(false);
    window.location.reload();
  };

  const handleCloseSnackbar = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            setSnackbarOpen(true);
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  });

  return (
    <ErrorBoundary>
      {/* Provide Redux store */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                  <NewVersionSnackbar
                    onConfirmNewVersion={onConfirmNewVersion}
                    snackbarOpen={snackbarOpen}
                    handleClose={handleCloseSnackbar}
                  />
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
