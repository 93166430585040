import * as requestFromServer from "./communicationsCrud";
import {communicationSlice, callTypes} from "./communicationsSlice";
const {actions}=communicationSlice;

export const uploadCompanyLogo=( uploadCompanyLogo, id ) => ( dispatch ) => {
    dispatch( actions.startCall( {callType: callTypes.action} ) );
    return requestFromServer
        .uploadLogo( uploadCompanyLogo, id )
        .then( ( response ) => {
            const {data}=response;
            dispatch( actions.companyFetched( {data} ) )
        } )
        .catch( ( error ) => {
            const errors=error.response.data.errors;
            dispatch( actions.catchErrors( {errors} ) );
        } );
};
export const fetchCompanyData=( id ) => ( dispatch ) => {
    dispatch( actions.startCall( {callType: callTypes.list} ) );
    return requestFromServer.getCompanyInfo( id ).then( ( response ) => {
        const {data}=response
        dispatch( actions.companyFetched( {data} ) )
    } ).catch( ( error ) => {
        const errors=error.response.data.errors;
        dispatch( actions.catchErrors( {errors} ) )
    } )
}