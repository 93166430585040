import React from 'react';
import {injectIntl} from "react-intl";
import {makeStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {Card, CardBody, CardHeader} from "../../../../../_metronic/_partials/controls";
import EmployeeForm from "./EmployeeForm";

const useStyles = makeStyles({
  list: {
    width: 350,
  }
});

function ServiceDrawer(props) {
  const classes = useStyles();
  const {intl, employeeId, toggleDrawer, open} = props;

  const sideList = () => (
    <div
      className={classes.list}
    >
      <Card>
        <CardHeader title={intl.formatMessage({id: "EMPLOYEE.EMPLOYEE"})}/>
        <CardBody>
          <EmployeeForm toggleDrawer={toggleDrawer} employeeId={employeeId} />
        </CardBody>
      </Card>
    </div>
  );

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {sideList()}
    </SwipeableDrawer>

  );
}

export default injectIntl(ServiceDrawer);

