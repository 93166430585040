import React from "react";
import { GoogleLogin } from "react-google-login";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { actions } from "../Auth/_redux/authRedux";
import { createGoogleProfile } from "../Auth/_redux/authCrud";

const Login = () => {

  const googleClientId = process.env.GOOGLE_CLIENT_ID || "65468899481-9dcg4d03l6r2c1d1f4gao9ama4r4aic6.apps.googleusercontent.com";
  const dispatch = useDispatch();

  const onSuccess = (response) => {
    console.log('onSuccess');
    console.log(response);
    createGoogleProfile(response.code)
      .then((response) => {
        dispatch(actions.googleSignIn(response.data.googleProfile));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const onFailure = (res) => {
    console.log('onFailure');
    console.log(res);
  };

  return (
    <div>
      <div className="card card-custom">
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder">
            <FormattedMessage id="DASHBOARD.SEE_YOUR_WEBSITE_VISITORS" />
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          <div className="d-flex justify-content-center bg-light-secondary rounded p-5">
            <GoogleLogin
              clientId={googleClientId}
              buttonText={<FormattedMessage id="GOOGLE.SIGN_IN" />}
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              responseType="code"              
              accessType="offline"
              scope={'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
