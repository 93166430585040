import axios from "axios";
export const APPOINTMENTS_URL = "appointments";
export const SETTINGS_URL = "settings";

export async function getAllAppointments() {
  return axios.get(`${APPOINTMENTS_URL}`);
}

export async function getAppointments(startDate, endDate) {
  return axios.get(
    `${APPOINTMENTS_URL}?startDate=${startDate}&endDate=${endDate}`
  );
}
export async function getAppointmentsSummary() {
  return await axios.get(`${APPOINTMENTS_URL}?summary=true`);
}

export function createAppointment(appointment) {
  return axios.post(APPOINTMENTS_URL, { appointment });
}

export function updateAppointment(id, appointment) {
  return axios.put(`${APPOINTMENTS_URL}/${id}`, { appointment });
}

export function deleteAppointment(id) {
  return axios.delete(`${APPOINTMENTS_URL}/${id}`);
}

export function getAppointmentById(appointmentId) {
  return axios.get(`${APPOINTMENTS_URL}/${appointmentId}`);
}

export async function getAppointmentSettings() {
  return axios.get(SETTINGS_URL);
}

export function createAppointmentSettings(settings) {
  return axios.post(SETTINGS_URL, { settings });
}
