import { createSlice } from "@reduxjs/toolkit";

const initialResourceState = {
  listLoading: false,
  actionsLoading: false,
  resources: null,
  lastError: null,
  errors: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const resourcesSlice = createSlice({
  name: "resources",
  initialState: initialResourceState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errors = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    resourceCreated: (state, action) => {
      state.error = null;
      state.resources.push(action.payload.resource);
    },
    resourceUpdated: (state, action) => {
      state.error = null;
      state.resources = [
        ...state.resources.map(resource => resource.id === action.payload.resource.id ? action.payload.resource : resource),
      ];
    },
    resourceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.resources = [
        ...state.resources.filter(resources => resources.id !== Number(action.payload.id)),
      ];
    },
    resourceFetched: (state, action) => {
      state.actionsLoading = false;
      state.resourceForEdit = action.payload.resourceForEdit;
      state.error = null;
    },
    resourcesFetched: (state, action) => {
      const { resources } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.resources = resources;
    }
  }
});
