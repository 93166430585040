/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as servicesActions from "../../Services/_redux/servicesActions";
import * as employeesActions from "../../Employees/_redux/employeesActions";
import * as addressActions from "../../Address/_redux/addressActions";
import { Link, NavLink } from "react-router-dom";

function GetStartedWidget(props) {

  const { intl } = props;
  const dispatch = useDispatch();

  const { servicesState, employeesState, addressState } = useSelector(
    (state) => ({
      servicesState: state.services,
      employeesState: state.employees,
      addressState: state.address,
    }),
    shallowEqual
  );

  const { services } = servicesState;
  const { employees } = employeesState;
  const { address } = addressState;

  useEffect(() => {
    dispatch(servicesActions.fetchAllServices());
    dispatch(employeesActions.fetchAllEmployees());
    dispatch(addressActions.fetchAddress());
  }, [dispatch]);

  return (
    <>
      <div className="card card-custom">
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            {intl.formatMessage({ id: "DASHBOARD.GET_STARTED" })}
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          {services.length === 0 && (
            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-9">
              <span className="svg-icon svg-icon-success mr-5 svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")}
                ></SVG>
              </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <NavLink
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  to="/settings/services"
                >
                  {intl.formatMessage({ id: "DASHBOARD.ENTER_SERVICES" })}
                </NavLink>
                <span className="text-muted font-weight-bold">
                  {intl.formatMessage({ id: "DASHBOARD.ADD_SERVICES_INFO" })}
                </span>
              </div>
              <span className="font-weight-bolder text-danger py-1 font-size-lg">
                <NavLink
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  to="/settings/services"
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Angle-right.svg"
                    )}
                  ></SVG>
                </NavLink>
              </span>
            </div>
          )}

          {employees.length === 0 && (
            <div className="d-flex align-items-center mb-9 bg-light-warning rounded p-5">
              <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                ></SVG>
              </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <NavLink
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  to="/settings/employees"
                >
                  {intl.formatMessage({ id: "DASHBOARD.ENTER_EMPLOYEES" })}
                </NavLink>
                <span className="text-muted font-weight-bold">
                  {intl.formatMessage({ id: "DASHBOARD.ENTER_EMPLOYEES_INFO" })}
                </span>
              </div>
              <span className="font-weight-bolder text-danger py-1 font-size-lg">
                <NavLink
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  to="/settings/employees"
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Angle-right.svg"
                    )}
                  ></SVG>
                </NavLink>
              </span>
            </div>
          )}

          {address.id === "" && (
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-9">
              <span className="svg-icon svg-icon-danger mr-5 svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
                ></SVG>
              </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <NavLink
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  to="/settings/address"
                >
                  {intl.formatMessage({ id: "DASHBOARD.ENTER_ADDRESS" })}
                </NavLink>
                <span className="text-muted font-weight-bold">
                  {intl.formatMessage({ id: "DASHBOARD.ADD_ADDRESS_INFO" })}
                </span>
              </div>

              <span className="font-weight-bolder text-danger py-1 font-size-lg">
                <NavLink
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  to="/settings/address"
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Angle-right.svg"
                    )}
                  ></SVG>
                </NavLink>
              </span>
            </div>
          )}
          <div className="d-flex align-items-center bg-light-info rounded p-5 mb-9">
            <span className="svg-icon svg-icon-danger mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")}
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="https://redmaki.com/"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                {intl.formatMessage({ id: "DASHBOARD.GET_MORE_CUSTOMERS" })}
              </a>
              <span className="text-muted font-weight-bold">
                {intl.formatMessage({
                  id: "DASHBOARD.GET_MORE_CUSTOMERS_INFO",
                })}
              </span>
            </div>

            <span className="font-weight-bolder text-danger py-1 font-size-lg">
              <a href="https://redmaki.com/">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-right.svg"
                  )}
                ></SVG>
              </a>
            </span>
          </div>

          {false && (
          <div className="d-flex align-items-center bg-light-info rounded p-5 mb-9">
            <span className="svg-icon svg-icon-danger mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")}
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <Link
                to="/settings/google-calendar"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                {intl.formatMessage({ id: "DASHBOARD.CONNECT_TO_GOOGLE" })}
              </Link>
              <span className="text-muted font-weight-bold">
                {intl.formatMessage({
                  id: "DASHBOARD.CONNECT_TO_GOOGLE_SUBTITLE",
                })}
              </span>
            </div>

            <span className="font-weight-bolder text-danger py-1 font-size-lg">
              <Link to="/settings/google-calendar">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-right.svg"
                  )}
                ></SVG>
              </Link>
            </span>
          </div>
          )}
          
        </div>
      </div>
    </>
  );
}
export default injectIntl(GetStartedWidget);
