import axios from "axios";

export const ADDRESS_URL = "addresses";

export async function getAddress() {
  return axios.get(`${ADDRESS_URL}`);
}

export function createAddress(address) {
  return axios.post(ADDRESS_URL, { address });
}

export function updateAddress(id, address) {
  return axios.put(`${ADDRESS_URL}/${id}`, { address });
}
