import * as requestFromServer from "./employeesCrud";
import { employeesSlice, callTypes } from "./employeesSlice";

const { actions } = employeesSlice;

export const fetchEmployeesByService = (companyId, serviceId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if (serviceId) {
    return requestFromServer
      .getEmployeesByService(companyId, serviceId)
      .then(response => {
        const { employees } = response.data;
        dispatch(actions.employeesByServiceIdFetched({ employees }));
      })
      .catch(error => {
        const errors = error.response.data.errors;
        dispatch(actions.catchErrors({ errors }));
      });
  } else {
    return requestFromServer
      .getAllEmployee()
      .then(response => {
        dispatch(actions.employeesByServiceIdFetched({ employees: response.data.employees }));
      })
      .catch(error => {
        const errors = error.response.data.errors;
        dispatch(actions.catchErrors({ errors }));
      });
  }
};

export const fetchAllEmployees = () => dispatch => {
  return requestFromServer
    .getAllEmployee()
    .then(response => {
      const { employees } = response.data;
      dispatch(actions.employeesFetched({ employees }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createEmployee = employee => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createEmployee(employee)
    .then(response => {
      const { employee } = response.data;
      dispatch(actions.employeeCreated({ employee }));
      return employee;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateEmployee = (id, employee) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateEmployee(id, employee)
    .then(response => {
      const { employee } = response.data;
      dispatch(actions.employeeUpdated({ employee }));
      return employee;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const deleteEmployee = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEmployee(id)
    .then(response => {
      const { employee } = response.data;
      dispatch(actions.employeeDeleted({ id: employee.id }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchEmployee = id => dispatch => {
  if (!id) {
    return dispatch(actions.employeeFetched({
      employeeForEdit: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        serviceIds: [],
      }
    }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getEmployeeById(id)
    .then(response => {
      const employee = response.data;
      dispatch(actions.employeeFetched({ employeeForEdit: employee }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};
