import axios from "axios";

export const CUSTOMERS_URL = "customers";

export async function findCustomers(queryParams) {
  return axios.get(`${CUSTOMERS_URL}`, { params: queryParams });
}

export function createCustomer(customer) {
  return axios.post(CUSTOMERS_URL, { customer });
}

export function updateCustomer(id, customer) {
  return axios.put(`${CUSTOMERS_URL}/${id}`, { customer });
}

export function getCustomerById(customerId) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}`);
}

export function deleteCustomer(id) {
  return axios.delete(`${CUSTOMERS_URL}/${id}`);
}
