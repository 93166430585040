import * as requestFromServer from "./servicesCrud";
import { servicesSlice, callTypes } from "./servicesSlice";

const { actions } = servicesSlice;

export const fetchServicesByEmployee = (companyId, employeeId) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if (employeeId) {
    return requestFromServer
      .getServicesByEmployee(companyId, employeeId)
      .then((response) => {
        const { services } = response.data;
        dispatch(actions.servicesByEmployeeIdFetched({ services }));
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        dispatch(actions.catchErrors({ errors }));
      });
  } else {
    return requestFromServer
      .getAllService()
      .then((response) => {
        const { services } = response.data;
        dispatch(actions.servicesByEmployeeIdFetched({ services }));
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        dispatch(actions.catchErrors({ errors }));
      });
  }
};

export const fetchAllServices = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllService()
    .then((response) => {
      const { services } = response.data;
      dispatch(actions.servicesFetched({ services }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createService = (service) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createService(service)
    .then((response) => {
      const { service } = response.data;
      dispatch(actions.serviceCreated({ service }));
      return service;
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateService = (id, service) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateService(id, service)
    .then((response) => {
      const { service } = response.data;
      dispatch(actions.serviceUpdated({ service }));
      return service;
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const deleteService = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteService(id)
    .then((response) => {
      const { service } = response.data;
      dispatch(actions.serviceDeleted({ id: service.id }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchService = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.serviceFetched({ serviceForEdit: null }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getServiceById(id)
    .then((response) => {
      const service = response.data;
      dispatch(actions.serviceFetched({ serviceForEdit: service }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};
