import * as Yup from "yup";
import { minTime } from "../../../../helpers/dateHelpers";

export const getAppointmentSchema = (intl, start) => {
  return Yup.object().shape({
    customer: Yup.object().nullable(),
    start: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    end: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .test(
        "min",
        intl.formatMessage({
          id: "APPOINTMENTS.ERROR.MIN.END.DATE",
        }),
        function(value, data) {
          return minTime(data.parent.start, value);
        }
      ),
    date: Yup.date().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    remark: Yup.string().nullable(),
    employee: Yup.object().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    service: Yup.object().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    firstName: Yup.string()
      .max(
        100,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 100 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .max(
        155,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 155 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phone: Yup.string().max(
      32,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
        { max: 32 }
      )
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT" }))
      .max(
        255,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    repeat: Yup.string().nullable(),
    repeat_times: Yup.number()
      .positive()
      .moreThan(
        0,
        intl.formatMessage({ id: "GENERAL.VALIDATION.MORE_THAN" }, { min: 0 })
      )
      .lessThan(
        366,
        intl.formatMessage({ id: "GENERAL.VALIDATION.LESS_THAN" }, { max: 365 })
      )
      .nullable(),
  });
};
