import * as requestFromServer from "./appointmentsCrud";
import { appointmentsSlice, callTypes } from "./appointmentsSlice";

const { actions } = appointmentsSlice;

export const getAllAppointments = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAppointments()
    .then((response) => {
      const { appointments } = response.data;
      dispatch(actions.appointmentsFetched({ appointments }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const resetAppointmentForEdit = (toggleDrawer) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  toggleDrawer(false)(null);
  return dispatch(actions.appointmentForEditReseted());
};

export const getAppointments = (startDate, endDate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAppointments(startDate, endDate)
    .then((response) => {
      const { appointments } = response.data;
      dispatch(actions.appointmentsFetched({ appointments }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchAppointmentsSummary = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAppointmentsSummary()
    .then((response) => {
      const data = response.data;
      dispatch(actions.fetchAppointmentsSummary(data));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createAppointment = (appointment, toggleDrawer) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAppointment(appointment)
    .then((response) => {
      const { appointment } = response.data;
      dispatch(actions.appointmentCreated({ appointment }));
      toggleDrawer(false)(null);
      return appointment;
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const deleteAppointment = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAppointment(id)
    .then((response) => {
      const { id } = response.data.appointment;
      dispatch(actions.appointmentDeleted({ id }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateAppointment = (id, appointment, toggleDrawer) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAppointment(id, appointment)
    .then((response) => {
      const { appointment } = response.data;
      dispatch(actions.appointmentUpdated({ appointment }));
      toggleDrawer(false)(null);
      return appointment;
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchAppointment = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.appointmentFetched({ appointmentForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAppointmentById(id)
    .then((response) => {
      const appointment = response.data;
      dispatch(actions.appointmentFetched({ appointmentForEdit: appointment }));
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

// TODO: Update this when settings API is live
export const getAppointmentSettings = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAppointmentSettings()
    .then((response) => {
      const { settings } = response.data;
      dispatch(actions.settingsFetched({ settings }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createAppointmentSettings = (settings) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAppointmentSettings(settings)
    .then((response) => {
      const { settings } = response.data;
      dispatch(actions.appointmentSettingsCreated(settings));
      return settings;
    })
    .catch((error) => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};