import { createSlice } from "@reduxjs/toolkit";

const initialAddressState = {
  loading: false,
  address: {
    id: "",
    line1: "",
    city: "",
    postcode: "",
    email: "",
    phonenumber: "",
    website: "",
  },
  error: null,
  errors: null,
  success: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const addressSlice = createSlice({
  name: "address",
  initialState: initialAddressState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;
      state.loading = false;
      state.success = false;
    },
    startCall: (state) => {
      state.errors = null;
      state.loading = true;
      state.success = false;
    },
    addressCreated: (state, action) => {
      state.error = null;
      state.address = action.payload.address;
      state.loading = false;
      state.success = true;
    },
    addressUpdated: (state, action) => {
      state.error = null;
      state.address = action.payload.address;
      state.loading = false;
      state.success = true;
    },
    addressFetched: (state, action) => {
      const { address } = action.payload;
      state.loading = false;
      state.success = false;
      state.error = null;
      if (address.length === 1) {
        state.address = address[0];
      }
    }
  }
});
