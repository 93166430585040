import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import AppointmentForm from "./AppointmentForm";
import BlockForm from "./BlockForm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles({
  list: {
    width: "100%",
  },
});

function SwipeableTemporaryDrawer(props) {
  const {
    intl,
    appointmentId,
    activeTab,
    toggleDrawer,
    open,
    onAppointmentChange,
    start,
    setDrawer,
    selectedEmployee
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(activeTab);
  const [otherTabDisabled, setOtherTabDisabled] = useState(false);
  const [clientTabDisabled, setClientTabDisabled] = useState(false);

  if (appointmentId && activeTab === 0 && !otherTabDisabled) {
    setOtherTabDisabled(true);
  } else if (appointmentId && activeTab === 1 && !clientTabDisabled) {
    setClientTabDisabled(true);
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const sideList = () => {
    return (
      <div className={classes.list}>
        <Card>
          <CardHeader
            title={intl.formatMessage({ id: "APPOINTMENTS.APPOINTMENT" })}
          />
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={intl.formatMessage({ id: "CUSTOMER.CUSTOMER" })}
              disabled={clientTabDisabled}
            />
            <Tab
              label={intl.formatMessage({ id: "GENERAL.OTHER" })}
              disabled={otherTabDisabled}
            />
          </Tabs>
          <CardBody>
            {value === 0 && open && (
              <AppointmentForm
                toggleDrawer={toggleDrawer}
                appointmentId={appointmentId}
                onAppointmentChange={onAppointmentChange}
                start={start}
                setDrawer={setDrawer}
                selectedEmployee={selectedEmployee}
              />
            )}
            {value === 1 && open && (
              <BlockForm
                toggleDrawer={toggleDrawer}
                setDrawer={setDrawer}
                appointmentId={appointmentId}
                onAppointmentChange={onAppointmentChange}
                start={start}
                selectedEmployee={selectedEmployee}
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {sideList()}
    </SwipeableDrawer>
  );
}

export default injectIntl(SwipeableTemporaryDrawer);
