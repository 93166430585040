import axios from "axios";

export const RESOURCE_URL = "resources";

export async function getAllResources() {
  return axios.get(`${RESOURCE_URL}`);
}

export function createResource(resource) {
  return axios.post(RESOURCE_URL, { resource });
}

export function updateResource(id, resource) {
  return axios.put(`${RESOURCE_URL}/${id}`, { resource });
}

export function getResourceById(resourceId) {
  return axios.get(`${RESOURCE_URL}/${resourceId}`);
}

export function deleteResource(id) {
  return axios.delete(`${RESOURCE_URL}/${id}`);
}
