import React from "react";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

function ShowHeader (props) {
    const { location : { pathname = "login" }, intl } = props;
    const accountText = intl.formatMessage({ id: pathname.includes('login') ? "AUTH.GENERAL.NO_ACCOUNT" : "AUTH.GENERAL.HAVE_ACCOUNT"});
    const signText = intl.formatMessage({ id: pathname.includes('login') ? "AUTH.GENERAL.SIGN_UP!" : "AUTH.GENERAL.SIGN_IN!" });
    const link = (pathname.includes('login') ? '/auth/registration' : '/auth/login');
    return (
        <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
            <span className="font-weight-bold text-dark-50">{accountText}</span>
            <Link to={link} className="font-weight-bold ml-2" id="kt_login_signup">{signText}</Link>
        </div>
    )
}

export default injectIntl(ShowHeader);
