import * as requestFromServer from "./resourcesCrud";
import { resourcesSlice, callTypes } from "./resourcesSlice";

const { actions } = resourcesSlice;

export const fetchAllResources = () => dispatch => {
  return requestFromServer
    .getAllResources()
    .then(response => {
      const { resources } = response.data;
      dispatch(actions.resourcesFetched({ resources }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createResource = resource => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createResource(resource)
    .then(response => {
      const { resource } = response.data;
      dispatch(actions.resourceCreated({ resource }));
      return resource;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateResource = (id, resource) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateResource(id, resource)
    .then(response => {
      const { resource } = response.data;
      dispatch(actions.resourceUpdated({ resource }));
      return resource;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};


export const deleteResource = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteResource(id)
    .then(response => {
      const { resource } = response.data;
      dispatch(actions.resourceDeleted({ id: resource.id }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchResource = id => dispatch => {
  if (!id) {
    return dispatch(actions.resourceFetched({ resourceForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getResourceById(id)
    .then(response => {
      const resource = response.data;
      dispatch(actions.resourceFetched({ resourceForEdit: resource }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};
