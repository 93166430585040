/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Events");
  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="kt_quick_notifications_toggle"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="user-notification-tooltip">
              <FormattedMessage id="MENU.USER_NOTIFICATION" />
            </Tooltip>
          }
        >
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
            <span className="pulse-ring"/>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
        <form>
          <div className="d-flex flex-column pb-10 bgi-size-cover bgi-no-repeat rounded-top" >

            <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Events"
                        className={`nav-link show ${
                          key === "Events" ? "active" : ""
                        }`}
                      >
                        RedMaki
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Logs"
                        className={`nav-link show ${
                          key === "Logs" ? "active" : ""
                        }`}
                      >
                        <FormattedMessage id="GENERAL.EVENTS" />
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane
                      eventKey="Events"
                      id="topbar_notifications_events"
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        <a href="#" className="navi-item">
                          <div className="navi-link">
                            <div className="navi-icon mr-2">
                              <i className="flaticon2-line-chart text-success"></i>
                            </div>
                            <div className="navi-text">
                              <div className="font-weight-bold">
                                <FormattedMessage id="GENERAL.WELCOME_TO_REDMAKI" />
                              </div>
                              <div className="text-muted">23h</div>
                            </div>
                          </div>
                        </a>

                      </PerfectScrollbar>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
                      <div className="d-flex flex-center text-center text-muted min-h-200px">
                        All caught up!
                        <br />
                        No new notifications.
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>

          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
}
