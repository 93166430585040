import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/nl";

import nlAuth from "./locales/nl/auth";
import enAuth from "./locales/en/auth";
import nlMenu from "./locales/nl/menu";
import enMenu from "./locales/en/menu";
import enUserProfile from "./locales/en/userProfile";
import nlUserProfile from "./locales/nl/userProfile";
import nlCustomer from "./locales/nl/customer";
import enCustomer from "./locales/en/customer";
import enGeneral from "./locales/en/general";
import nlGeneral from "./locales/nl/general";
import nlAppointments from "./locales/nl/appointments";
import enAppointments from "./locales/en/appointments";
import enService from "./locales/en/service";
import nlService from "./locales/nl/service";
import enEmployee from "./locales/en/employee";
import nlEmployee from "./locales/nl/employee";
import nlCategory from "./locales/nl/category";
import enCategory from "./locales/en/category";
import enResource from "./locales/en/resource";
import nlResource from "./locales/nl/resource";
import nlHours from "./locales/nl/hours";
import enHours from "./locales/en/hours";
import enTour from "./locales/en/tour";
import nlTour from "./locales/nl/tour";
import enAddress from "./locales/en/address";
import nlAddress from "./locales/nl/address";
import nlDashboard from "./locales/nl/dashboard";
import enDashboard from "./locales/en/dashboard";
import nlIntegrations from "./locales/nl/integrations";
import enIntegrations from "./locales/en/integrations";
import nlGoogle from "./locales/nl/google";
import enGoogle from "./locales/en/google";
import enCommunication from "./locales/en/communication.json";
import nlCommunication from "./locales/nl/communication.json"
const allMessages = {
  nl: {
    ...nlMenu,
    ...nlAuth,
    ...nlUserProfile,
    ...nlCustomer,
    ...nlGeneral,
    ...nlAppointments,
    ...nlService,
    ...nlEmployee,
    ...nlCategory,
    ...nlResource,
    ...nlHours,
    ...nlTour,
    ...nlAddress,
    ...nlDashboard,
    ...nlIntegrations,
    ...nlGoogle,
    ...nlCommunication,
  },
  en: {
    ...enMenu,
    ...enAuth,
    ...enUserProfile,
    ...enCustomer,
    ...enGeneral,
    ...enAppointments,
    ...enService,
    ...enEmployee,
    ...enCategory,
    ...enResource,
    ...enHours,
    ...enTour,
    ...enAddress,
    ...enDashboard,
    ...enIntegrations,
    ...enGoogle,
    ...enCommunication,
  },
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
