import React, {useState} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import CategoriesTable from "./categories-table/CategoriesTable";
import { injectIntl, FormattedMessage } from "react-intl";
import CategoryDrawer from "./CategoryDrawer/CategoryDrawer";
import CategoryIcon from "@material-ui/icons/Category";

export function CategoriesCard(props) {

  const {intl} = props;
  const [drawer, setDrawer] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const toggleDrawer = (open, id) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
    setCategoryId(id);
  };

  return (
    <Card>
      <CardHeader 
        title={intl.formatMessage({id: "CATEGORY.CATEGORY_LIST"})}
        icon={<CategoryIcon color="secondary" fontSize="medium" style={{ marginRight: 10 }} />}
      >
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setDrawer(true)}
          >
            <FormattedMessage id={"CATEGORY.ADD"}/>
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CategoriesTable toggleDrawer={toggleDrawer} />
      </CardBody>
      <CategoryDrawer open={drawer} toggleDrawer={toggleDrawer} categoryId={categoryId} />
    </Card>
  );
}

export default injectIntl(CategoriesCard);
