
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addMinutes, format, getHours, getMinutes, isEqual } from "date-fns";
import { convertDateToString, formatTimeToString } from "../../../helpers/dateHelpers";

import * as appointmentsActions from "../_redux/appointmentsActions";
import * as hoursActions from '../../Hours/_redux/hoursActions';

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

export const CustomTimeSlot = ({
    children,
    appointments,
    currentStepTimeSlot,
    resource: employeeId,
    value: slotDate,
    setActiveTab,
    setStartDate,
    setDrawer,
    setSelectedEmployee,
    openHours,
    currentView
}) => {
    const dispatch = useDispatch();
    const companyId = useSelector(state => state.auth.user.company.id);
    const [showIcon, setShowIcon] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const formattedSlotHour = format(new Date(slotDate), 'HH:mm');
    const notWorkingHour = !openHours?.[employeeId]?.[formattedSlotHour];

    const hasAppointment = appointments.find((appointment) => {
        const { startElements, employee } = appointment;

        const appointmentDate = new Date(
            startElements.year,
            startElements.month - 1,
            startElements.day,
            startElements.hour,
            startElements.minutes
        );

        return employee?.id === employeeId && isEqual(slotDate, appointmentDate);
    });

    useEffect(() => {
        if (hasAppointment && hasAppointment.type === "BLOCK") {
            setIsBlocked(true);
        }
    }, [hasAppointment]);

    const handleBlockClick = () => {
        const startDate = slotDate;
        const endDate = addMinutes(slotDate, currentStepTimeSlot);

        if (isBlocked) {
            return;
        }

        if (notWorkingHour) {

            const formattedStartTime = formatTimeToString(getHours(startDate), getMinutes(startDate));
            const formattedEndTime = formatTimeToString(getHours(endDate), getMinutes(endDate));

            const newHour = {
                type: "EMPLOYEE",
                day_of_week: 0,
                start: convertDateToString(startDate, false),
                end: convertDateToString(endDate, false),
                closed: false,
                user_id: employeeId,
                company_id: companyId,
                start_time: formattedStartTime,
                end_time: formattedEndTime,
                id: ''
            };

            dispatch(hoursActions.createEmployeeExtraHour(newHour));

            return;
        };

        dispatch(
            appointmentsActions.createAppointment({
                isBlocked: true,
                employeeId: employeeId,
                start: convertDateToString(startDate),
                end: convertDateToString(endDate),
                type: "BLOCK",
                employee_id: employeeId,
            })
        );
    };

    const handleNewAppointment = () => {
        if (!employeeId) {
            return;
        }

        setSelectedEmployee(employeeId);
        setActiveTab(0);
        setDrawer(true);
        setStartDate(slotDate);
    };

    const onMouseOver = () => setShowIcon(true);
    const onMouseOut = () => setShowIcon(false);

    return (
        <>
            <div
                onClick={handleNewAppointment}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                className={clsx(
                  showIcon && "active", 
                  notWorkingHour && 
                  (currentView === 'day' || currentView === 'week') && 
                  "closed-slot pointer-events-none")}
            >
                {children}
            </div>
            <FontAwesomeIcon
                icon={notWorkingHour ? faPlus : faTimes}
                size="lg"
                className={clsx(
                    "custom-time-slot__icon cursor-pointer",
                    !showIcon && "hidden",
                )}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onClick={handleBlockClick}
            />
        </>
    );
};
