// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 1,
    date: new Date(2020, 11, 8, 9, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
      active: true,
      order: 1,
      serviceIds: [1, 4, 3],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 2,
    date: new Date(2020, 11, 9, 10, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: true,
    description: "appointment 2",
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 2,
      firstName: "Abie",
      lastName: "Cowperthwaite",
      email: "acowperthwaite1@storify.com",
      phone: "0931193294",
      active: false,
      order: 2,
      serviceIds: [1, 2, 7, 8],
    },
  },
  {
    id: 3,
    date: new Date(2020, 11, 8, 10, 30, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 3,
      firstName: "Melody",
      lastName: "Stodd",
      email: "mstodd2@twitpic.com",
      phone: "0931193294",
      active: true,
      order: 3,
      serviceIds: [1, 3, 5, 6],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 4,
    date: new Date(2020, 11, 7, 11, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 4,
      firstName: "Naomi",
      lastName: "Galbreth",
      email: "ngalbreth3@springer.com",
      phone: "0931193294",
      active: true,
      order: 4,
      serviceIds: [1, 4, 5, 6, 7, 8, 9],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 5,
    date: new Date(2020, 11, 10, 15, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 3,
      firstName: "Melody",
      lastName: "Stodd",
      email: "mstodd2@twitpic.com",
      phone: "0931193294",
      active: true,
      order: 3,
      serviceIds: [1, 3, 5, 6],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 6,
    date: new Date(2020, 11, 8, 15, 30, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 3,
      firstName: "Melody",
      lastName: "Stodd",
      email: "mstodd2@twitpic.com",
      phone: "0931193294",
      active: true,
      order: 3,
      serviceIds: [1, 3, 5, 6],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 7,
    date: new Date(2020, 11, 12, 14, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 5,
      firstName: "Ashley",
      lastName: "Jandl",
      email: "ajandl4@mapy.cz",
      phone: "0931193294",
      active: false,
      order: 5,
      serviceIds: [1, 4, 7, 10],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 8,
    date: new Date(2020, 11, 11, 13, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 2,
      firstName: "Abie",
      lastName: "Cowperthwaite",
      email: "acowperthwaite1@storify.com",
      phone: "0931193294",
      active: false,
      order: 2,
      serviceIds: [1, 2, 7, 8],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 9,
    date: new Date(2020, 11, 9, 12, 30, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 2,
      firstName: "Abie",
      lastName: "Cowperthwaite",
      email: "acowperthwaite1@storify.com",
      phone: "0931193294",
      active: false,
      order: 2,
      serviceIds: [1, 2, 7, 8],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 10,
    date: new Date(2020, 11, 10, 11, 45, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 4,
      firstName: "Naomi",
      lastName: "Galbreth",
      email: "ngalbreth3@springer.com",
      phone: "0931193294",
      active: true,
      order: 4,
      serviceIds: [1, 4, 5, 6, 7, 8, 9],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 11,
    date: new Date(2020, 11, 7, 9, 30, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 5,
      firstName: "Ashley",
      lastName: "Jandl",
      email: "ajandl4@mapy.cz",
      phone: "0931193294",
      active: false,
      order: 5,
      serviceIds: [1, 4, 7, 10],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 12,
    date: new Date(2020, 11, 11, 11, 30, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 3,
      firstName: "Melody",
      lastName: "Stodd",
      email: "mstodd2@twitpic.com",
      phone: "0931193294",
      active: true,
      order: 3,
      serviceIds: [1, 3, 5, 6],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 13,
    date: new Date(2020, 11, 10, 12, 30, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 3,
      firstName: "Melody",
      lastName: "Stodd",
      email: "mstodd2@twitpic.com",
      phone: "0931193294",
      active: true,
      order: 3,
      serviceIds: [1, 3, 5, 6],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 14,
    date: new Date(2020, 11, 11, 10, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 4,
      firstName: "Naomi",
      lastName: "Galbreth",
      email: "ngalbreth3@springer.com",
      phone: "0931193294",
      active: true,
      order: 4,
      serviceIds: [1, 4, 5, 6, 7, 8, 9],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 15,
    date: new Date(2020, 11, 9, 11, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 5,
      firstName: "Ashley",
      lastName: "Jandl",
      email: "ajandl4@mapy.cz",
      phone: "0931193294",
      active: false,
      order: 5,
      serviceIds: [1, 4, 7, 10],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 16,
    date: new Date(2020, 11, 10, 13, 0, 0),
    remark: "remark",
    notAskReview: true,
    privateAppointment: false,
    customer: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
    },
    employee: {
      id: 2,
      firstName: "Abie",
      lastName: "Cowperthwaite",
      email: "acowperthwaite1@storify.com",
      phone: "0931193294",
      active: false,
      order: 2,
      serviceIds: [1, 2, 7, 8],
    },
    service: {
      id: 1,
      description: "service 1",
      length: 30,
      blocksAgenda: true,
      customerCanBook: true,
    },
  },
  {
    id: 17,
    date: new Date(2020, 11, 8, 11, 0, 0),
    isBlocked: true,
    employee: {
      id: 1,
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "0931193294",
      active: true,
      order: 1,
      serviceIds: [1, 4, 3],
    },
  },
];
