import React from "react";
import CustomersLoadingDialog from "./../modules/Customers/pages/customers-loading-dialog/CustomersLoadingDialog";
import CustomersUIProvider from "./../modules/Customers/pages/CustomersUIContext";
import CustomersCard from "./../modules/Customers/pages/CustomersCard";

export function CustomersPage() {
  return (
    <CustomersUIProvider>
      <CustomersLoadingDialog />
      <CustomersCard />
    </CustomersUIProvider>
  );
}
