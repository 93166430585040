import { getCurrentLanguage } from "../_metronic/i18n";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      config.url = `${process.env.REACT_APP_API_BASE_URL}/${config.url}`;
      const language = getCurrentLanguage();

      config.headers["Accept-Language"] =
        language?.selectedLang === "en"
          ? "en-US,en;q=0.9,nl-NL,nl;q=0.8"
          : "nl-NL,nl;q=0.9,en-US,en;q=0.8";

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
