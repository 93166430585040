import React from 'react';
import { Button } from 'react-bootstrap';

export const CustomButton = ({ showSpinner, loading, ...props }) => {
    return (
        <Button variant={props.variant ? props.variant : 'primary'} type={props.type ? props.type : 'button'} {...props}>
            <div className='d-flex align-items-center'>
                <span>{props.children}</span>
                {showSpinner && loading && <span className="mx-3 spinner spinner-white"></span>}
            </div>
        </Button >
    );
};