import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
} from '../../../../_metronic/_partials/controls';
import { injectIntl } from 'react-intl';
import HoursForm from './HoursForm';
import { HoursTypeSelector } from '../components/HoursTypeSelector';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export function HoursCard(props) {
  const { intl } = props;
  const [selectedHourType, setSelectedHourType] = useState('Company');

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: 'HOURS.OPENING_HOURS' })}
        toolbar={
          <HoursTypeSelector
            selectedHourType={selectedHourType}
            setSelectedHourType={setSelectedHourType}
          />
        }
        icon={<AccessTimeIcon color="primary" fontSize="medium" style={{ marginRight: 10 }} />}
      ></CardHeader>
      <CardBody>
        <HoursForm
          selectedHourType={selectedHourType}
          setSelectedHourType={setSelectedHourType}
        />
      </CardBody>
    </Card>
  );
}

export default injectIntl(HoursCard);
