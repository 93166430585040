import React from 'react';
import {injectIntl} from "react-intl";
import {makeStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {Card, CardBody, CardHeader} from "../../../../../_metronic/_partials/controls";
import ResourceForm from "./ResourceForm";

const useStyles = makeStyles({
  list: {
    width: 350,
  }
});

function ServiceDrawer(props) {
  const classes = useStyles();
  const {intl, toggleDrawer, resourceId} = props;

  const sideList = () => (
    <div
      className={classes.list}
    >
      <Card>
        <CardHeader title={intl.formatMessage({id: "RESOURCE.RESOURCE"})}/>
        <CardBody>
          <ResourceForm toggleDrawer={toggleDrawer} resourceId={resourceId} />
        </CardBody>
      </Card>
    </div>
  );

  return (
    <SwipeableDrawer
      anchor="right"
      open={props.open}
      onClose={props.toggleDrawer(false)}
      onOpen={props.toggleDrawer(true)}
    >
      {sideList()}
    </SwipeableDrawer>

  );
}

export default injectIntl(ServiceDrawer);

