import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 1,
    password: "demo",
    email: "admin@demo.com",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    refreshPasswordToken: "3413947c-a6c6-4989-b108-c3bdbaa0f14b",
    roles: [1], // Administrator
    fullName: "Sean",
    company: {
      id: 1,
      industry: "beautySalon",
      companyName: "Keenthemes1",
    },
  },
  {
    id: 2,
    password: "demo",
    email: "user@demo.com",
    accessToken: "access-token-6829bba69dd3421d8762-991e9e806dbf",
    refreshToken: "access-token-f8e4c61a318e4d618b6c199ef96b9e55",
    roles: [2], // Manager
    pic: toAbsoluteUrl("/media/users/100_2.jpg"),
    fullName: "Megan",
    company: {
      companyId: 2,
      industry: "beautySalon",
      companyName: "Keenthemes2",
    },
  },
  {
    id: 3,
    password: "demo",
    email: "guest@demo.com",
    accessToken: "access-token-d2dff7b82f784de584b60964abbe45b9",
    refreshToken: "access-token-c999ccfe74aa40d0aa1a64c5e620c1a5",
    roles: [3], // Guest
    fullName: "Ginobili Maccari",
    company: {
      companyId: 3,
      industry: "beautySalon",
      companyName: "Keenthemes3",
    },
  },
];
