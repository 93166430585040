import "date-fns";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import DescriptionIcon from "@material-ui/icons/Description";
import * as categoryActions from "../../_redux/categoriesActions";
import { CustomButton } from "../../../../Components/CustomButton";
import { Errors } from "../../../../Components/Errors";

function CategoryForm(props) {
  const { intl, toggleDrawer, categoryId } = props;
  const iconColor = { color: "#B5B5C3" };
  const dispatch = useDispatch();

  const CategorySchema = Yup.object().shape({
    overlap_allowed: Yup.bool(),
    name: Yup.string()
      .max(
        255,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(() => {
    dispatch(categoryActions.fetchCategory(categoryId));
  }, [categoryId, dispatch]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    overlap_allowed: false,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CategorySchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const category = {
        name: values.name,
        overlap_allowed: values.overlap_allowed,
      };
      try {
        if (categoryId) {
          await dispatch(categoryActions.updateCategory(categoryId, { ...category }));
        } else {
          await dispatch(categoryActions.createCategory({ ...category }));
        }
        toggleDrawer(false)(null);
      } catch (e) {
        console.error(e);
      }
    },
  });

  const { categoriesState } = useSelector(
    (state) => ({
      categoriesState: state.categories,
    }),
    shallowEqual
  );

  const { categoryForEdit } = categoriesState;

  useEffect(() => {
    categoryForEdit && setInitialValues(categoryForEdit);
  }, [categoryForEdit]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
    >
      <div className="form-group fv-plugins-icon-container">
        <Errors />
        <TextField
          id="name"
          error={!!(formik.touched.name && formik.errors.name)}
          label={intl.formatMessage({ id: "CATEGORY.DESCRIPTION" })}
          name="name"
          fullWidth
          autoFocus
          margin="dense"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
          helperText={formik.errors.name}
          {...formik.getFieldProps("name")}
        />
        {false && (<FormControlLabel
          control={
            <Switch
              checked={formik.values?.overlap_allowed}
              onChange={(value) =>
                formik.setFieldValue(
                  "overlap_allowed",
                  !formik.values?.overlap_allowed
                )
              }
            />
          }
          label={intl.formatMessage({ id: "CATEGORY.CAN_NOT_OVERLAP" })}
        />
        )}
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <CustomButton
          type="submit"
          variant='primary'
          className="mr-5"
        >
          <FormattedMessage id="GENERAL.SUBMIT" />
        </CustomButton>
        <CustomButton
          variant='secondary'
          type="button"
          onClick={toggleDrawer(false)}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </CustomButton>
      </div>
    </form>
  );
}

export default injectIntl(CategoryForm);
