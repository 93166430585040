import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import TextField from "@material-ui/core/TextField";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import DescriptionIcon from "@material-ui/icons/Description";
import MenuItem from "@material-ui/core/MenuItem";
import * as resourcesActions from "../../_redux/resourcesActions";
import { CustomButton } from "../../../../Components/CustomButton";

function ResourceForm(props) {
  const { intl, toggleDrawer, resourceId } = props;
  const iconColor = { color: "#B5B5C3" };
  const dispatch = useDispatch();

  // TODO should get this from API
  const TYPES = [
    { value: "ROOM", label: intl.formatMessage({ id: "RESOURCE.TYPE.ROOM" }) },
    {
      value: "MACHINE",
      label: intl.formatMessage({ id: "RESOURCE.TYPE.MACHINE" }),
    },
    {
      value: "OTHER",
      label: intl.formatMessage({ id: "RESOURCE.TYPE.OTHER" }),
    },
  ];

  const ResourceSchema = Yup.object().shape({
    type: Yup.string(),
    name: Yup.string()
      .max(
        255,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(() => {
    dispatch(resourcesActions.fetchResource(resourceId));
  }, [resourceId, dispatch]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "ROOM",
  });

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ResourceSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const resource = {
        name: values.name,
        type: values.type,
      };
      try {
        if (resourceId) {
          await dispatch(
            resourcesActions.updateResource(resourceId, { ...resource })
          );
        } else {
          await dispatch(resourcesActions.createResource({ ...resource }));
        }
        toggleDrawer(false)(null);
      } catch (e) {
        console.error(e);
      }
    },
  });

  const { resourcesState } = useSelector(
    (state) => ({
      resourcesState: state.resources,
    }),
    shallowEqual
  );

  const { error: resourceError, resourceForEdit } = resourcesState;

  useEffect(() => {
    if (resourceForEdit) {
      setInitialValues(resourceForEdit);
    } else {
      setInitialValues({
        name: "",
        type: "",
      });
    }
  }, [resourceForEdit]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
    >
      <div className="form-group fv-plugins-icon-container">
        {resourceError && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{resourceError}</div>
          </div>
        )}
        <TextField
          id="name"
          error={!!(formik.touched.name && formik.errors.name)}
          label={intl.formatMessage({ id: "RESOURCE.DESCRIPTION" })}
          multiline
          autoFocus
          fullWidth
          margin="dense"
          variant="outlined"
          onChange={formik.handleChange}
          value={formik?.values?.name}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
          helperText={formik.errors.name}
          {...formik.getFieldProps("name")}
        />
        <TextField
          error={!!(formik.touched.type && formik.errors.type)}
          helperText={formik.errors.type}
          select
          id="type"
          label={intl.formatMessage({ id: "RESOURCE.TYPE" })}
          margin="dense"
          variant="outlined"
          fullWidth
          {...formik.getFieldProps("type")}
        >
          {TYPES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <CustomButton
          type="submit"
          variant='primary'
          className="mr-5"
        >
          <FormattedMessage id="GENERAL.SUBMIT" />
        </CustomButton>
        <CustomButton
          type="button"
          variant='secondary'
          onClick={toggleDrawer(false)}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </CustomButton>
      </div>
    </form>
  );
}

export default injectIntl(ResourceForm);
