import { addMinutes, subMinutes } from "date-fns";
/**
 * Convert a date variable to a string with requested fixed format
 * dayFirst is true: d-m-Y H:i
 * dayFirst is false: Y-m-d H:i
 *
 * @param {Date} dateToConvert
 * @param {boolean} dayFirst
 * @return string
 */
export function convertDateToString(dateToConvert, dayFirst = true) {

  try {

    let year = dateToConvert.getFullYear();
    let day = ("0" + dateToConvert.getDate()).slice(-2);
    let month = ("0" + (dateToConvert.getMonth() + 1)).slice(-2);
    let hours = ("0" + dateToConvert.getHours()).slice(-2);
    let minutes = ("0" + dateToConvert.getMinutes()).slice(-2);

    if (dayFirst) {
      return day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    } else {
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    }
    
  } catch (error) {
    console.error(error);
  }
}

export function setTimeToDate(date, time) {
  const newDate = new Date(date);
  const [hours, minutes] = time.split(":");
  newDate.setHours(parseInt(hours));
  newDate.setMinutes(parseInt(minutes));
  return newDate;
}

export function formatDateToTime(date = new Date()) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return formatTimeToString(hours, minutes);
}

export const minTime = (minTime, time) => {
  let { minHours, minMinutes } = minTime.split(':')
  minHours = parseInt(minHours);
  minMinutes = parseInt(minMinutes);

  let { hours, minutes } = time.split(':');
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (hours < minHours) {
    return false;
  } else if (hours === minHours) {
    if (minutes <= minMinutes) {
      return false;
    }
  }
  return true;
}

export const addMinutesToDateString = (start, duration = 0) => {
  const date = setTimeToDate(new Date(), start);
  const serviceDate = addMinutes(date, duration);
  return formatDateToTime(serviceDate);
}

export const subMinutesToDateString = (start, duration = 0) => {
  const date = setTimeToDate(new Date(), start);
  const serviceDate = subMinutes(date, duration);
  return formatDateToTime(serviceDate);
}

export const formatTimeToString = (hours, minutes) => {
  const hour = hours.toString().length === 2 ? hours : '0' + hours;
  const minute = minutes.toString().length === 2 ? minutes : '0' + minutes;

  return hour + ':' + minute;
};


export const formatDate = (elements) => {
  return new Date(
    elements.year,
    elements.month - 1,
    elements.day,
    elements.hour,
    elements.minutes
  );
};;;
