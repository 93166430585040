export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 }
];
export const initialFilter = {
  filter: {
    name: "",
    email: "",
    phoneNumber: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "name",
  page: 1,
  pageSize: 10
};
