import 'date-fns';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import * as addressActions from '../_redux/addressActions';
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from './countries';
import translatedCountryNames from 'i18n-iso-countries';
import { CustomSnackbar } from '../../../Components/CustomSnackbar';
import { CustomButton } from '../../../Components/CustomButton';
import { Errors } from '../../../Components/Errors';

translatedCountryNames.registerLocale(
  require('i18n-iso-countries/langs/en.json')
);
translatedCountryNames.registerLocale(
  require('i18n-iso-countries/langs/nl.json')
);

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export function AddressForm(props) {
  const { intl } = props;
  const classes = useStyles();

  // TODO move to separate component
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // End

  const AddressSchema = Yup.object().shape({
    line1: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' },
          { min: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    city: Yup.string()
      .max(
        255,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    country: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    postcode: Yup.string()
      .max(
        20,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' },
          { max: 20 }
        )
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.VALIDATION.WRONG_EMAIL_FORMAT' }))
      .max(
        255,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    phonenumber: Yup.string()
      .max(
        32,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' },
          { max: 32 }
        )
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    website: Yup.string()
      .matches(
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)$/,
        intl.formatMessage({ id: 'AUTH.VALIDATION.WRONG_WEBSITE_FORMAT' })
      )
      .max(
        255,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' },
          { max: 255 }
        )
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addressActions.fetchAddress());
  }, [dispatch]);

  const [initialValues, setInitialValues] = useState({
    line1: '',
    city: '',
    postcode: '',
    email: '',
    phonenumber: '',
    website: '',
    country: '',
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddressSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setOpen(true);
        if (address.id) {
          dispatch(addressActions.updateAddress(address.id, { ...values }));
        } else {
          dispatch(addressActions.createAddress(values));
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const { addressState } = useSelector(
    (state) => ({
      addressState: state.address,
    }),
    shallowEqual
  );

  const { address, loading, success } = addressState;

  useEffect(() => {
    setInitialValues(address);
  }, [address]);

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
      : isoCode;
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
    >
      <div className='form-group fv-plugins-icon-container'>
        <Errors />
        <div className='row'>
          <div className='col-md-6'>
            <TextField
              id='line1'
              error={!!(formik.touched.line1 && formik.errors.line1)}
              label={intl.formatMessage({ id: 'ADDRESS.STREET' })}
              multiline
              fullWidth
              margin='dense'
              variant='outlined'
              helperText={formik.errors.line1}
              InputLabelProps={{
                shrink: true,
              }}
              {...formik.getFieldProps('line1')}
            />
            <div className='row'>
              <div className='col-sm-6'>
                <TextField
                  id='postcode'
                  label={intl.formatMessage({ id: 'ADDRESS.ZIP_CODE' })}
                  margin='dense'
                  fullWidth
                  error={!!(formik.touched.postcode && formik.errors.postcode)}
                  helperText={
                    formik.errors.postcode ? formik.errors.postcode : null
                  }
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...formik.getFieldProps('postcode')}
                />
              </div>
              <div className='col-sm-6'>
                <TextField
                  id='city'
                  error={!!(formik.touched.city && formik.errors.city)}
                  label={intl.formatMessage({ id: 'ADDRESS.CITY' })}
                  multiline
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  helperText={formik.errors.city}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...formik.getFieldProps('city')}
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-sm-6'>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  id='email'
                  label={intl.formatMessage({ id: 'ADDRESS.EMAIL' })}
                  type='email'
                  name='email'
                  margin='dense'
                  variant='outlined'
                  fullWidth
                  helperText={formik.errors.email}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...formik.getFieldProps('email')}
                />
              </div>
              <div className='col-sm-6'>
                <TextField
                  error={
                    !!(formik.touched.phonenumber && formik.errors.phonenumber)
                  }
                  id='phonenumber'
                  label={intl.formatMessage({ id: 'ADDRESS.PHONE' })}
                  name='phonenumber'
                  margin='dense'
                  variant='outlined'
                  fullWidth
                  helperText={formik.errors.phonenumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...formik.getFieldProps('phonenumber')}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <Autocomplete
                  id='country'
                  name='country'
                  options={countries}
                  classes={{
                    option: classes.option,
                  }}
                  onChange={(e, newValue) =>
                    formik.setFieldValue('country', newValue.code)
                  }
                  value={
                    formik.values.country &&
                    countries.find(
                      (country) => country.code === formik.values.country
                    )
                  }
                  disableClearable
                  getOptionLabel={(option) =>
                    translatedCountryNames.getName(option.code, intl.locale)
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{countryToFlag(option.code)}</span>
                      {translatedCountryNames.getName(option.code, intl.locale)}
                      ({option.code})
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        !!(formik.touched.country && formik.errors.country)
                      }
                      helperText={formik.errors.country}
                      label={intl.formatMessage({ id: 'ADDRESS.COUNTRY' })}
                      variant='outlined'
                      margin='dense'
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'no-autocomplete',
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </div>
              <div className='col-md-6'>
                <TextField
                  error={!!(formik.touched.website && formik.errors.website)}
                  id='website'
                  label={intl.formatMessage({ id: 'ADDRESS.WEBSITE' })}
                  name='website'
                  margin='dense'
                  variant='outlined'
                  fullWidth
                  helperText={formik.errors.website}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...formik.getFieldProps('website')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='form-group d-flex flex-wrap flex-center'>
        <CustomButton
          type='submit'
          variant='primary'
          className='mr-5'
          loading={loading}
          showSpinner
          disabled={formik.isSubmitting}
          onClick={() => formik.setStatus('submitted')}
        >
          <FormattedMessage id='GENERAL.SUBMIT' />
        </CustomButton>
        <CustomButton
          type='button'
          variant='secondary'
          onClick={() =>
            address
              ? formik.setValues(address)
              : formik.setValues(initialValues)
          }
        >
          <FormattedMessage id='GENERAL.CANCEL' />
        </CustomButton>
      </div>
      {success && !loading && (
        <CustomSnackbar
          alertType='success'
          open={open}
          handleClose={handleClose}
          text={intl.formatMessage({ id: 'ADDRESS.CHANGES_SAVED' })}
        />
      )}
    </form>
  );
}

export default injectIntl(AddressForm);
