import { useDispatch } from "react-redux";
import { appointmentsSlice } from '../modules/Appointments/_redux/appointmentsSlice';
import { customersSlice } from '../modules/Customers/_redux/customers/customersSlice';
import { employeesSlice } from '../modules/Employees/_redux/employeesSlice';
import { servicesSlice } from '../modules/Services/_redux/servicesSlice';
import { addressSlice } from '../modules/Address/_redux/addressSlice';
import { categoriesSlice } from '../modules/Categories/_redux/categoriesSlice';
import { resourcesSlice } from '../modules/Resources/_redux/resourcesSlice';

const { actions: appointmentsActions } = appointmentsSlice;
const { actions: customersActions } = customersSlice;
const { actions: employeesActions } = employeesSlice;
const { actions: servicesActions } = servicesSlice;
const { actions: addressActions } = addressSlice;
const { actions: categoriesActions } = categoriesSlice;
const { actions: resourcesActions } = resourcesSlice;

export const useClearAllErrors = () => {
    const dispatch = useDispatch();

    const clearErrors = () => {
        dispatch(appointmentsActions.clearErrors());
        dispatch(customersActions.clearErrors());
        dispatch(employeesActions.clearErrors());
        dispatch(servicesActions.clearErrors());
        dispatch(addressActions.clearErrors());
        dispatch(categoriesActions.clearErrors());
        dispatch(resourcesActions.clearErrors());
    };

    return {
        clearAllErrors: clearErrors
    };
};