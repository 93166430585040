/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Card,
    CardBody,
    CardHeader,
} from '../../../../_metronic/_partials/controls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    FormControlLabel,
    IconButton,
    InputAdornment,
    makeStyles,
    Switch,
    TextField,
} from '@material-ui/core';
import { CalendarMonth, SmartButton } from '@mui/icons-material';
import { FormLabel, Tab, Tabs } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useHistory } from 'react-router-dom';

import * as appointmentsActions from '../_redux/appointmentsActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CustomSnackbar } from '../../../Components/CustomSnackbar';
import { CustomButton } from '../../../Components/CustomButton';
import { Alert } from '@mui/material';
import { Errors } from '../../../Components/Errors';

import ColorPicker from 'material-ui-color-picker';

const useStyles = makeStyles(() => ({
    switchLabel: {
        marginLeft: 0,
    },
}));

const settingsMapping = {
    showEmployees: 'APT-PAGE-SHOW-EMPLOYEE',
    serviceLabelText: 'APT-PAGE-SERVICE-TEXT',
    onlineCancellationAllowed: 'APT-CANCEL-BEFORE-HOURS',
    buttonColor: 'APT-BOOK-BUTTON-COLOR',
    buttonPosition: 'APT-BOOK-BUTTON-POSITION',
    buttonLabel: 'APT-BOOK-BUTTON-LABEL',
    notificationsToEmail: 'APT-NEW-NOTIFICATION-EMAIL',
    slotsInterval: 'APT-SLOTS-INTERVAL'
};

export const BookingSettingsCard = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [showInfo, setShowInfo] = useState(false);

    const [step, setStep] = useState('');

    const [tabValue, setTabValue] = useState(0);
    const [bookingPage, setBookingPage] = useState(false);
    const [bookNow, setBookNow] = useState(false);

    const settingsState = useSelector((state) => state.appointments.settings);
    const appointmentsState = useSelector(state => state.appointments);
    const { actionsLoading: isLoading, errors } = appointmentsState;

    const bottomRight = intl.formatMessage({ id: 'APPOINTMENTS.SETTINGS.BUTTON.POSITION.BOTTOM_RIGHT' });
    const bottomLeft = intl.formatMessage({ id: 'APPOINTMENTS.SETTINGS.BUTTON.POSITION.BOTTOM_LEFT' });
    const topRight = intl.formatMessage({ id: 'APPOINTMENTS.SETTINGS.BUTTON.POSITION.TOP_RIGHT' });
    const topLeft = intl.formatMessage({ id: 'APPOINTMENTS.SETTINGS.BUTTON.POSITION.TOP_LEFT' });

    const positionOptions = [bottomRight, bottomLeft, topRight, topLeft];

    const [initialValues, setInitialValues] = useState({
        showEmployees: false,
        serviceLabelText: '',
        onlineCancellationAllowed: '',
        buttonColor: '#4A90E2',
        buttonLabel: '',
        buttonPosition: positionOptions[0],
        notificationsToEmail: '',
        slotsInterval: ''
    });

    const bookingSettingsSchema = Yup.object().shape({
        showEmployees: Yup.bool(),
        serviceLabelText: Yup.string(),
        notificationsToEmail: Yup.string(),
        buttonColor: Yup.string(),
        buttonPosition: Yup.string(),
        onlineCancellationAllowed: Yup.number().min(
            0,
            intl.formatMessage(
                { id: 'APPOINTMENTS.VALIDATION.MIN_LENGTH_FIELD' },
                { min: 0 }
            )
        ).max(
            168,
            intl.formatMessage(
                { id: 'APPOINTMENTS.VALIDATION.MAX_LENGTH_FIELD' },
                { max: 168 }
            )
        ),
        slotsInterval: Yup.number().min(
            15,
            intl.formatMessage(
                { id: 'APPOINTMENTS.VALIDATION.MIN_LENGTH_FIELD_SLOT_INTERVAL' },
                { min: 15 }
            )
        ).max(
            120,
            intl.formatMessage(
                { id: 'APPOINTMENTS.VALIDATION.MAX_LENGTH_FIELD_SLOT_INTERVAL' },
                { max: 120 }
            )
        ),
        buttonLabel: Yup.string().min(
            0,
            intl.formatMessage(
                { id: 'APPOINTMENTS.VALIDATION.MIN_LENGTH_FIELD' },
                { min: 0 }
            )
        ).max(
            256,
            intl.formatMessage(
                { id: 'APPOINTMENTS.VALIDATION.MAX_LENGTH_FIELD' },
                { max: 256 }
            )
        ),
    });

    function handleTabChange(event, newValue) {
        setTabValue(newValue);
        }

    if (tabValue === 0 && !setBookNow) {
    setBookNow(true);
    } else if (tabValue === 1 && !setBookingPage) {
    setBookingPage(true);
    }

    const [open, setOpen] = useState(false);
    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: bookingSettingsSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const currentShowEmployees = settingsState?.find(s => s.code === settingsMapping.showEmployees);
            const currentServiceLabelText = settingsState?.find(s => s.code === settingsMapping.serviceLabelText);
            const currentOnlineCancellationAllowed = settingsState?.find(s => s.code === settingsMapping.onlineCancellationAllowed);
            const currentButtonColor = settingsState?.find(s => s.code === settingsMapping.buttonColor);
            const currentButtonLabel = settingsState?.find(s => s.code === settingsMapping.buttonLabel);
            const currentButtonPosition = settingsState?.find(s => s.code === settingsMapping.buttonPosition);
            const currentNotificationsToEmail = settingsState?.find(s => s.code === settingsMapping.notificationsToEmail);
            const currentSlotsInterval = settingsState?.find(s => s.code === settingsMapping.slotsInterval);

            const settings = [
                {
                    id: currentShowEmployees ? currentShowEmployees.id : '',
                    code: settingsMapping.showEmployees,
                    value: values.showEmployees ? '1' : '0'
                },
                {
                    id: currentServiceLabelText ? currentServiceLabelText.id : '',
                    code: settingsMapping.serviceLabelText,
                    value: values.serviceLabelText
                },
                {
                    id: currentOnlineCancellationAllowed ? currentOnlineCancellationAllowed.id : '',
                    code: settingsMapping.onlineCancellationAllowed,
                    value: values.onlineCancellationAllowed
                },
                {
                    id: currentButtonColor ? currentButtonColor.id : '',
                    code: settingsMapping.buttonColor,
                    value: values.buttonColor
                },
                {
                    id: currentButtonLabel ? currentButtonLabel.id : '',
                    code: settingsMapping.buttonLabel,
                    value: values.buttonLabel
                },
                {
                    id: currentButtonPosition ? currentButtonPosition.id : '',
                    code: settingsMapping.buttonPosition,
                    value: values.buttonPosition
                },
                {
                    id: currentNotificationsToEmail ? currentNotificationsToEmail.id : '',
                    code: settingsMapping.notificationsToEmail,
                    value: values.notificationsToEmail
                },
                {
                    id: currentSlotsInterval ? currentSlotsInterval.id : '',
                    code: settingsMapping.slotsInterval,
                    value: values.slotsInterval
                },
            ];
            dispatch(appointmentsActions.createAppointmentSettings(settings));
            setOpen(true);
        },
    });

    useEffect(() => {
        dispatch(appointmentsActions.getAppointmentSettings());
    }, []);

    useEffect(() => {
        if (settingsState?.length) {
            const showEmployees = settingsState.find(setting => setting.code === settingsMapping.showEmployees)?.value === '1';
            const serviceLabelText = settingsState.find(setting => setting.code === settingsMapping.serviceLabelText)?.value;
            const onlineCancellationAllowed = settingsState.find(setting => setting.code === settingsMapping.onlineCancellationAllowed)?.value;

            const buttonColor = settingsState.find(setting => setting.code === settingsMapping.buttonColor)?.value;
            const buttonPosition = settingsState.find(setting => setting.code === settingsMapping.buttonPosition)?.value;
            const buttonLabel = settingsState.find(setting => setting.code === settingsMapping.buttonLabel)?.value;

            const notificationsToEmail = settingsState.find(setting => setting.code === settingsMapping.notificationsToEmail)?.value;
            const slotsInterval = settingsState.find(setting => setting.code === settingsMapping.slotsInterval)?.value;
            setInitialValues({
                showEmployees,
                serviceLabelText,
                onlineCancellationAllowed,
                buttonColor,
                buttonPosition,
                buttonLabel,
                notificationsToEmail,
                slotsInterval
            });
        }
    }, [settingsState]);

    return (
        <Card>
            <CardHeader
                title={intl.formatMessage({ id: 'APPOINTMENTS.SETTINGS.SETTINGS_PAGE' })}
                icon={<SettingsIcon color="primary" fontSize="medium" style={{ marginRight: 10 }} />}
            >
            </CardHeader>
            <CardBody>
                {!errors && !isLoading && (
                    <CustomSnackbar
                        alertType='success'
                        open={open}
                        handleClose={handleClose}
                        text={intl.formatMessage({ id: 'GENERAL.CHANGES_SAVED' })}
                    />
                )}
                {showInfo && (
                    <>
                        {step === 'allowed-cancellation-info' && <Alert severity="info" onClose={() => {setShowInfo(false); setStep('')}}>{intl.formatMessage({ id: "APPOINTMENTS.SETTINGS.ONLINE.CANCELLATION.ALLOWED.INFO" })}</Alert>}
                        {step === 'slots-interval-info' && <Alert severity="info" onClose={() => {setShowInfo(false); setStep('')}}>{intl.formatMessage({ id: "APPOINTMENTS.SETTINGS.SLOTS.INTERVAL.INFO" })}</Alert>}
                    </>
                )}
                <Errors />
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    style={{ height: 60 }}
                >
                    <Tab
                        icon={<CalendarMonth />}
                        iconPosition="start"
                        label={intl.formatMessage({ id: 'APPOINTMENTS.SETTINGS.BOOKING_PAGE_TAB' })}
                        disabled={bookingPage}
                    />
                    <Tab
                        icon={<SmartButton />}
                        iconPosition="start"
                        label={intl.formatMessage({ id: 'APPOINTMENTS.SETTINGS.BOOK_NOW_BUTTON_TAB' })}
                        disabled={bookNow}
                    />
                </Tabs>

                <form onSubmit={formik.handleSubmit} style={{ marginTop: 50 }}>
                    <div className='row'>
                    {tabValue === 0 && (
                        <>
                        <div className='col-12'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values?.showEmployees}
                                        onChange={() =>
                                            formik.setFieldValue(
                                                'showEmployees',
                                                !formik.values?.showEmployees
                                            )
                                        }
                                    />
                                }
                                label={intl.formatMessage({
                                    id: 'APPOINTMENTS.SETTINGS.SHOW.EMPLOYEES',
                                })}
                                labelPlacement='start'
                                classes={{
                                    labelPlacementStart: classes.switchLabel,
                                }}
                            />
                        </div>
                        <div className='col-12 col-md-7 mb-5'>
                            <TextField
                                error={
                                    !!(
                                        formik.touched.onlineCancellationAllowed &&
                                        formik.errors.onlineCancellationAllowed
                                    )
                                }
                                id='onlineCancellationAllowed'
                                label={intl.formatMessage({
                                    id: 'APPOINTMENTS.SETTINGS.ONLINE.CANCELLATION.ALLOWED',
                                })}
                                name='onlineCancellationAllowed'
                                type='number'
                                inputMode="numeric"
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                helperText={formik.errors.onlineCancellationAllowed}
                                value={formik.values.onlineCancellationAllowed || ""}
                                onChange={(e) => formik.setFieldValue('onlineCancellationAllowed', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton style={{ padding: 0 }} onClick={() => {
                                                setShowInfo(true); 
                                                setStep('allowed-cancellation-info');
                                                }}>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className='col-12 col-md-7 mb-5'>
                            <TextField
                                error={
                                    !!(
                                        formik.touched.serviceLabelText &&
                                        formik.errors.serviceLabelText
                                    )
                                }
                                id='serviceLabelText'
                                label={intl.formatMessage({
                                    id: 'APPOINTMENTS.SETTINGS.SERVICE.LABEL.TEXT',
                                })}
                                name='serviceLabelText'
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                helperText={formik.errors.serviceLabelText}
                                value={formik.values.serviceLabelText || ""}
                                onChange={(e) => formik.setFieldValue('serviceLabelText', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className='col-12 col-md-7 mb-5'>
                            <TextField
                                error={
                                    !!(
                                        formik.touched.notificationsToEmail &&
                                        formik.errors.notificationsToEmail
                                    )
                                }
                                id='notificationToEmail'
                                label={intl.formatMessage({
                                    id: 'APPOINTMENTS.SETTINGS.SERVICE.SEND.NOTIFICATION.EMAIL',
                                })}
                                name='notificationsToEmail'
                                type='email'
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                helperText={formik.errors.notificationsToEmail}
                                value={formik.values.notificationsToEmail || ""}
                                onChange={(e) => formik.setFieldValue('notificationsToEmail', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className='col-12 col-md-7'>
                            <TextField
                                error={
                                    !!(
                                        formik.touched.slotsInterval &&
                                        formik.errors.slotsInterval
                                    )
                                }
                                id='slotsInterval'
                                label={intl.formatMessage({
                                    id: 'APPOINTMENTS.SETTINGS.SLOTS.INTERVAL',
                                })}
                                name='slotsInterval'
                                type='number'
                                inputMode="numeric"
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                helperText={formik.errors.slotsInterval}
                                value={formik.values.slotsInterval || ""}
                                onChange={(e) => formik.setFieldValue('slotsInterval', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton style={{ padding: 0 }} onClick={() => {
                                                setShowInfo(true); 
                                                setStep('slots-interval-info');
                                                }}>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        </>
                    )}
                    {tabValue === 1 && (
                        <>
                        <div className='col-12 mb-2' style={{display: 'flex', gap: 20, alignItems: 'end'}}>
                            <FormLabel>
                                {intl.formatMessage({id: 'APPOINTMENTS.SETTINGS.BUTTON.COLOR.TEXT'})}
                            </FormLabel>
                            <ColorPicker
                                name='buttonColor'
                                variant='outlined'
                                margin='dense'
                                value={formik.values.buttonColor}
                                onChange={(color) => {
                                    formik.setFieldValue("buttonColor", color);
                                }}
                                style={{ background: `${!formik?.values.buttonColor ? formik.initialValues?.buttonColor : formik?.values.buttonColor}`, width: 140, borderRadius: '3px' }}
                                InputLabelProps={{
                                    shrink: true,
                                    padding: 20,
                                }}
                                disabled
                            />
                        </div>
                        <div className='col-12 col-md-7 mb-5'>
                            <TextField
                                id='buttonLabel'
                                name='buttonLabel'
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                value={formik.values.buttonLabel || ""}
                                onChange={(e) => {
                                    formik.setFieldValue("buttonLabel", e.target.value);
                                }}
                                label={intl.formatMessage({
                                    id: 'APPOINTMENTS.SETTINGS.BUTTON.LABEL.TEXT',
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className='col-12 col-md-7'>
                            <Autocomplete
                                id="buttonPosition"
                                name="buttonPosition"
                                fullWidth
                                options={positionOptions}
                                value={formik.values.buttonPosition}
                                onChange={(event, value) => {
                                    formik.setFieldValue("buttonPosition", value);
                                }}
                                getOptionLabel={((option) => option || "")}
                                getOptionSelected={(option, value) => option === value}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        name="buttonPosition"
                                        label={intl.formatMessage({
                                            id: 'APPOINTMENTS.SETTINGS.BUTTON.POSITION.TEXT',
                                        })} 
                                        variant="outlined" 
                                        inputMode="numeric"
                                        margin='dense'
                                        InputLabelProps={{
                                            shrink: true,
                                        }} 
                                    />}
                            />    
                        </div>
                        </>
                    )}
                    </div>
                    <div className='row my-5 text-center'>
                        <div className='col-12'>
                            <CustomButton variant='primary' className='mr-5' type='submit' disabled={isLoading} loading={isLoading} showSpinner>
                                {intl.formatMessage({ id: 'GENERAL.SUBMIT' })}
                            </CustomButton>
                            <CustomButton variant='secondary' onClick={() => history.goBack()}>
                                {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                            </CustomButton>
                        </div>
                    </div>
                </form>
            </CardBody>
        </Card>
    );
};
