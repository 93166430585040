import { createSlice } from "@reduxjs/toolkit";

const initialServicesState = {
  listLoading: false,
  servicesLoading: false,
  totalCount: 0,
  services: [],
  servicesWithoutComponents: [],
  servicesByEmployeeId: null,
  serviceForEdit: null,
  lastError: null,
  errors: null
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const servicesSlice = createSlice({
  name: "services",
  initialState: initialServicesState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.servicesLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errors = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.servicesLoading = true;
      }
    },
    servicesFetched: (state, action) => {
      const { services } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.services = services;
      state.servicesWithoutComponents = [
        ...state.services?.filter(
          (services) => services?.components?.length === 0
        ),
      ];
    },
    serviceCreated: (state, action) => {
      state.error = null;
      state.services.push(action.payload.service);
    },
    serviceUpdated: (state, action) => {
      state.error = null;
      state.services = [
        ...state.services.map((service) =>
          service.id === action.payload.service.id
            ? action.payload.service
            : service
        ),
      ];
    },
    serviceDeleted: (state, action) => {
      state.error = null;
      state.servicesLoading = false;
      state.services = [
        ...state.services.filter(
          (services) => services.id !== Number(action.payload.id)
        ),
      ];
      state.servicesWithoutComponents = [
        ...state.servicesWithoutComponents.filter(
          (services) => services.id !== Number(action.payload.id)
        ),
      ];
    },
    serviceFetched: (state, action) => {
      state.servicesLoading = false;
      state.serviceForEdit = action.payload.serviceForEdit;
      state.error = null;
    },
    servicesByEmployeeIdFetched: (state, action) => {
      const { services } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.servicesByEmployeeId = services;
    },
  },
});
