import * as hoursActions from '../modules/Hours/_redux/hoursActions';
import { isEqual, omit } from "lodash";

export const handleCreateOrUpdateHours = (hoursType, hours, dispatch) => {
    hours.forEach((day) => {
        day.forEach((hour) => {
            const newHour = { ...hour };

            newHour.start = newHour.closed ? null : `1001-01-01 ${newHour.start_time}:00`;
            newHour.end = newHour.closed ? null : `1001-01-01 ${newHour.end_time}:00`;

            if (hoursType === 'Company') {
                delete newHour.user_id;
            } else {
                newHour.user_id = hoursType.id;
            }

            const isSameHour = isEqual(omit(newHour, ['user_id']), omit(hour, ['user_id']));

            if ((hoursType === 'Company' && isSameHour) || (hoursType !== 'Company' && isSameHour && newHour.id)) {
                return;
            }

            if (hoursType !== 'Company') {
                if (!newHour.id) {
                    dispatch(hoursActions.createEmployeeHour(newHour));
                } else {
                    dispatch(hoursActions.updateEmployeeHour(newHour));
                }
            } else {
                if (!newHour.id) {
                    dispatch(hoursActions.createHour(newHour));
                } else {
                    dispatch(hoursActions.updateHour(newHour));
                }
            }
        });
    });
};
