import { ListItem, List, makeStyles } from "@material-ui/core";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import UniversalTileWidget from "../../../_metronic/_partials/widgets/tiles/UniversalTileWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import LanguageIcon from "@material-ui/icons/Language";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flex: "1",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  card: {
    width: "100%",
    transition: "transform 0.2s",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      transform: "scale(1.05) translateZ(0)",
    },
  },
}));

const providers = [
  {
    route: "website",
    background: "#3799FF",
    icon: <LanguageIcon />,
    title: "Website",
  },
  // TODO put back when RedMaki Wordpress Plugin has been approved
  // {
  //   route: "wordpress",
  //   background: "#21759b",
  //   icon: <FontAwesomeIcon icon={faWordpress} />,
  //   title: "Wordpress",
  // },
  {
    route: "facebook",
    background: "#4267B2",
    icon: <FontAwesomeIcon icon={faFacebook} />,
    title: "Facebook",
  },
  {
    route: "instagram",
    background: "#833AB4",
    icon: <FontAwesomeIcon icon={faInstagram} />,
    title: "Instagram",
  },
  {
    route: "floating-button",
    background: "#833AB4",
    icon: <FontAwesomeIcon icon={faPlayCircle} />,
    title: "Floating Button",
  },
];

export default function TilesPage() {
  const classes = useStyles();

  return (
    <Card>
      <FormattedMessage id={"INTEGRATION.ADD_SCHEDULING_TO_ALL_YOUR_CHANNELS"}>
        {(title) => <CardHeader title={title}></CardHeader>}
      </FormattedMessage>
      <CardBody>
        <div className="row">
          <List className={classes.list}>
            {providers.map((provider, index) => (
              <div key={provider.name} className="col-xl-3">
                <ListItem className={classes.card}>
                  <Link
                    to={`your-website/${provider.route}`}
                    style={{ width: "100%" }}
                  >
                    <UniversalTileWidget
                      backgroundColor={provider.background}
                      widgetHeight="150px"
                      provider={provider}
                    />
                  </Link>
                </ListItem>
              </div>
            ))}
          </List>
        </div>
      </CardBody>
    </Card>
  );
}
