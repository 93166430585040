import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { injectIntl } from "react-intl";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CommunicationForm from "./CommunicationForm";
export function CommunicationCard(props) {
  const { intl } = props;
  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: "COMMUNICATION.NAME" })}
        icon={
          <ContactMailIcon
            color="primary"
            fontSize="medium"
            style={{ marginRight: 10 }}
          />
        }
      ></CardHeader>
      <CardBody>
        <CommunicationForm />
      </CardBody>
    </Card>
  );
}
export default injectIntl(CommunicationCard);
