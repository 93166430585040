import axios from "axios";

export const LOGIN_URL = "login";
export const REGISTER_URL = "register";
export const GOOGLE_PROFILE = "google-tokens";
export const REQUEST_PASSWORD_URL = "password/forgot";
export const RESET_PASSWORD_URL = "password/reset";

export const ME_URL = "users";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullName, password, companyName, locale, domain) {
  return axios.post(REGISTER_URL, { email, fullName, password, companyName, language: locale, domain });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(password, token) {
  return axios.post(RESET_PASSWORD_URL, { password, token });
}

export function getUserByToken() {
  return axios.get(ME_URL);
}

export function createGoogleProfile(code) {
  return axios.get(`${GOOGLE_PROFILE}?code=${code}`)
}
