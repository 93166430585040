import axios from "axios";
const communicationURL="company";
// upload Image

export function uploadLogo( logo, id ) {
    return axios.post( `${communicationURL}/${id}/logo`, logo );
}

// get compnay logo (company data)

export function getCompanyInfo( id ) {
    return axios.get( `${communicationURL}/${id}/logo` )
}