/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { injectIntl } from "react-intl";

function DropdownMenu2({ updateSeries, intl }) {
  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover">
        <li className="navi-header pb-1">
          <span className="text-primary text-uppercase font-weight-bold font-size-sm">
            {intl.formatMessage({ id: "APPOINTMENTS.SELECT_PERIOD" })}
          </span>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span onClick={() => updateSeries("week")} className="navi-text">
              {intl.formatMessage({ id: "APPOINTMENTS.WEEKLY" })}
            </span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span onClick={() => updateSeries("month")} className="navi-text">
              {intl.formatMessage({ id: "APPOINTMENTS.MONTHLY" })}
            </span>
          </a>
        </li>
      </ul>
      {/*end::Navigation*/}
    </>
  );
}

export default injectIntl(DropdownMenu2);
