import { createSlice } from "@reduxjs/toolkit";

const initialAppointmentState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  appointments: [],
  appointmentForEdit: {},
  error: null,
  errors: null,
  appointmentsSummary: [],
  settings: []
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const appointmentsSlice = createSlice({
  name: "appointment",
  initialState: initialAppointmentState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errors = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    appointmentCreated: (state, action) => {
      const appointment = action.payload.appointment;
      state.error = null;
      state.actionsLoading = false;
      state.appointmentForEdit = {};
      if (appointment.appointments.length === 0) {
        state.appointments.push(appointment);
      } else {
        state.appointments = [
          ...state.appointments,
          ...appointment.appointments,
        ];
      }
    },
    appointmentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.appointments = [
        ...state.appointments.map((appointment) =>
          appointment.id === action.payload.appointment.id
            ? action.payload.appointment
            : appointment
        ),
      ];
    },
    appointmentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.appointmentForEdit = {};
      state.appointments = [
        ...state.appointments.filter(
          (appointment) =>
            appointment.id !== action.payload.id &&
            appointment.appointment_id !== action.payload.id
        ),
      ];
    },
    appointmentsFetched: (state, action) => {
      const { appointments } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.appointments = appointments;
    },
    appointmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.appointmentForEdit = action.payload.appointmentForEdit;
      state.error = null;
    },
    appointmentDrawerClose: (state) => {
      state.actionsLoading = false;
      state.appointmentForEdit = {};
    },
    fetchAppointmentsSummary: (state, action) => {
      state.appointmentsSummary = action.payload;
    },
    settingsFetched: (state, action) => {
      state.settings = action.payload.settings;
    },
    appointmentSettingsCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.settings = action.payload;
    },
    appointmentForEditReseted: (state) => {
      state.appointmentForEdit = {};
    },
  },
});
