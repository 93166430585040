import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { FormattedMessage } from "react-intl";
import MuiAlert from "@material-ui/lab/Alert";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  icon: {
    alignSelf: "center",
  },
  message: {
    "& button": {
      marginLeft: "10px",
    },
  },
}));

export const NewVersionSnackbar = ({
  snackbarOpen,
  handleClose,
  onConfirmNewVersion,
}) => {
  const classes = useStyles();
  return (
    <Snackbar open={snackbarOpen} onClose={(e, reason) => handleClose(e, reason)}>
      <MuiAlert
        onClose={handleClose}
        elevation={6}
        variant="filled"
        severity="info"
        classes={{
          icon: classes.icon,
          message: classes.message,
        }}
      >
        <FormattedMessage
          id={"GENERAL.NEW_VERSION_AVAILABLE"}
          style={{ marginRight: "10px" }}
        />
        <Button
          onClick={onConfirmNewVersion}
          color="primary"
          variant="contained"
        >
          <FormattedMessage id={"GENERAL.REFRESH"} />
        </Button>
      </MuiAlert>
    </Snackbar>
  );
};

