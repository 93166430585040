import { createSlice } from "@reduxjs/toolkit";

const initialHoursState = {
  loading: false,
  hours: null,
  error: null,
  errors: null,
  success: false,
  employees: {},
  extraHours: {}
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const hoursSlice = createSlice({
  name: "hours",
  initialState: initialHoursState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errors = null;
      state.loading = true;
      state.success = false;
    },
    hoursFetched: (state, action) => {
      const { hours } = action.payload;
      state.loading = false;
      state.error = null;
      state.hours = hours;
    },
    employeeHoursFetched: (state, action) => {
      const { hours, employeeId } = action.payload;
      state.loading = false;
      state.error = null;
      state.employees[employeeId] = hours;
    },
    employeeExtraHoursFetched: (state, action) => {
      const { hours, employeeId } = action.payload;
      state.loading = false;
      state.error = null;
      state.extraHours[employeeId] = hours[7];
    },
    hourCreated: (state, action) => {
      const { day_of_week } = action.payload.hour;

      state.error = null;
      state.success = true;
      state.loading = false;
      state.hours[day_of_week - 1].push(action.payload.hour);
    },
    employeeHourCreated: (state, action) => {
      const { day_of_week, user_id } = action.payload.hour;

      state.error = null;
      state.success = true;
      state.loading = false;

      if (action.payload.hour.id) {
        state.employees[user_id][day_of_week - 1].push(action.payload.hour);
      }

      state.employees[user_id][day_of_week - 1] = state.employees[user_id][day_of_week - 1].filter(hour => hour.id);
    },
    employeeExtraHourCreated: (state, action) => {
      const { user_id } = action.payload.hour;
      state.error = null;
      state.success = true;
      state.loading = false;

      if (action.payload.hour.id) {
        if (!state.extraHours[user_id]) {
          state.extraHours[user_id] = [];
        }
        state.extraHours[user_id].push(action.payload.hour);
      }
    },
    hourUpdated: (state, action) => {
      const { day_of_week, id } = action.payload.hour;
      const toReplaceIndex = state.hours[day_of_week - 1].findIndex(hour => hour.id === id);

      state.error = null;
      state.success = true;
      state.loading = false;
      state.hours[day_of_week - 1][toReplaceIndex] = action.payload.hour;
    },
    employeeHourUpdated: (state, action) => {
      const { day_of_week, user_id, id } = action.payload.hour;
      const toReplaceIndex = state.employees[user_id][day_of_week - 1].findIndex(hour => hour.id === id);

      state.error = null;
      state.success = true;
      state.loading = false;
      state.employees[user_id][day_of_week - 1][toReplaceIndex] = action.payload.hour;
    },
    hourDeleted: (state, action) => {
      state.loading = false;
      state.error = null;
      state.actionsLoading = false;
      state.hours = [
        ...state.hours.filter(
          (hours) => hours.id !== Number(action.payload.id)
        ),
      ];
    },
    employeeHoursDeleted: (state, action) => {
      const { id, employeeId } = action.payload;
      state.loading = false;
      state.error = null;
      state.actionsLoading = false;
      state.employees[employeeId] = [
        ...state.employees[employeeId].filter(
          hours => hours.id !== Number(id)
        )
      ];
    },
    resetEmployeeHours: (state, action) => {
      const { hours, employeeId } = action.payload;
      const newHours = hours.map(day => day.map(hour => {
        hour.id = '';

        return hour;
      }));

      state.loading = false;
      state.error = null;
      state.actionsLoading = false;
      state.employees[employeeId] = newHours;
    }
  },
});
