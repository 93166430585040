import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';

export const Errors = () => {
    const [showErrors, setShowErrors] = useState(true);

    const {
        customersState,
        employeesState,
        servicesState,
        appointmentsState,
        addressState,
        categoriesState,
        resourcesState
    } = useSelector(
        (state) => ({
            appointmentsState: state.appointments,
            customersState: state.customers,
            employeesState: state.employees,
            servicesState: state.services,
            addressState: state.address,
            categoriesState: state.categories,
            resourcesState: state.resources
        }),
        shallowEqual
    );

    const {
        errors: customersErrorList,
    } = customersState;
    const {
        errors: employeesErrorList,
    } = employeesState;
    const {
        errors: servicesErrorList,
    } = servicesState;
    const {
        errors: appointmentsErrorList,
    } = appointmentsState;
    const {
        errors: addressErrorList,
    } = addressState;
    const {
        errors: categoriesErrorList,
    } = categoriesState;
    const {
        errors: resourcesErrorList,
    } = resourcesState;

    const allMergedErrors = useMemo(() => {
        return {
            ...customersErrorList,
            ...employeesErrorList,
            ...servicesErrorList,
            ...appointmentsErrorList,
            ...addressErrorList,
            ...categoriesErrorList,
            ...resourcesErrorList
        };
    }, [appointmentsErrorList, customersErrorList, employeesErrorList, servicesErrorList, addressErrorList, categoriesErrorList, resourcesErrorList]);

    useEffect(() => {
        allMergedErrors && setShowErrors(true);
    }, [allMergedErrors]);

    if (
        !customersErrorList &&
        !employeesErrorList &&
        !servicesErrorList &&
        !appointmentsErrorList &&
        !addressErrorList &&
        !resourcesErrorList &&
        !categoriesErrorList
    ) {
        return null;
    }

    return (
        <>
            {showErrors && allMergedErrors && Object.keys(allMergedErrors).map(key => {
                const errors = allMergedErrors[key];

                return errors.map(error => (
                    <Alert severity='error' color='error' onClose={() => setShowErrors(false)} className="mb-3">
                        { error }
                    </Alert>
                ));
            })}
        </>
    );
};

