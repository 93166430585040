import React from "react";
import GetStartedWidget from "../modules/Dashboard/components/GetStartedWidget";
import AppointmentsWidget from "../modules/Dashboard/components/AppointmentsWidget";

export function DashboardPage() {

  return (
    <>
      <div className="row">
        <div className="col-lg-6 mb-4 mb-md-0">
          <GetStartedWidget />
        </div>
        <div className="col-lg-6">
          <AppointmentsWidget />
          <br />
        </div>
      </div>
      <div className="row">
      </div>
    </>
  );
}
