import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/Customers/_redux/customers/customersSlice";
import {employeesSlice} from "../app/modules/Employees/_redux/employeesSlice";
import {servicesSlice} from "../app/modules/Services/_redux/servicesSlice";
import {appointmentsSlice} from "../app/modules/Appointments/_redux/appointmentsSlice";
import {categoriesSlice} from "../app/modules/Categories/_redux/categoriesSlice";
import {resourcesSlice} from "../app/modules/Resources/_redux/resourcesSlice";
import {hoursSlice} from "../app/modules/Hours/_redux/hoursSlice";
import {addressSlice} from "../app/modules/Address/_redux/addressSlice";
import {analyticsSlice} from "../app/modules/Analytics/_redux/analyticsSlice";
import {websiteSlice} from "../app/modules/Website/_redux/websiteSlice";
import {communicationSlice} from "../app/modules/Communication/_redux/communications/communicationsSlice";
export const rootReducer = combineReducers( {
  auth: auth.reducer,
  customers: customersSlice.reducer,
  employees: employeesSlice.reducer,
  services: servicesSlice.reducer,
  appointments: appointmentsSlice.reducer,
  categories: categoriesSlice.reducer,
  resources: resourcesSlice.reducer,
  hours: hoursSlice.reducer,
  address: addressSlice.reducer,
  analytics: analyticsSlice.reducer,
  website: websiteSlice.reducer,
  communication: communicationSlice.reducer,
} );

export function* rootSaga() {
  yield all( [ auth.saga() ] );
}
