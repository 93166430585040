import React, { Suspense } from "react";
import { Layout } from "../_metronic/layout";
import WebsitePage from "./pages/WebsitePage";
import { CheckoutPage } from "./pages/Checkout";
import { CustomersPage } from "./pages/Customers";
import { Redirect, Switch } from "react-router-dom";
import { DashboardPage } from "./pages/DashboardPage";
import { shallowEqual, useSelector } from "react-redux";
import { AppointmentsPage } from "./pages/Appointments";
import { CommunicationPage } from "./pages/CommunicationPage";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import SettingsPage from "./pages/Settings";

export default function BasePage() {
  const { subscribed } = useSelector(
    ({ auth }) => ({
      subscribed: auth.user.subscribed,
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {!subscribed ? (
        <Suspense>
          <Redirect exact from="/" to="/checkout" />
          <ContentRoute path="/checkout" component={CheckoutPage} />
        </Suspense>
      ) : (
        <Layout>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Redirect exact from="/checkout" to="/dashboard" />
            <ContentRoute path="/dashboard" component={DashboardPage} />
            <ContentRoute path="/appointments" component={AppointmentsPage} />
            <ContentRoute path="/customers" component={CustomersPage} />
            <ContentRoute path="/your-website" component={WebsitePage} />
            <ContentRoute path="/settings" component={SettingsPage} />
            <ContentRoute path="/communication" component={CommunicationPage} />
            <Redirect to="error/error-v1" />
          </Switch>
        </Layout>
      )}
    </Suspense>
  );
}
