import React, {useEffect} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {injectIntl} from "react-intl";
import * as actions from "../../_redux/employeesActions";
import ActionsColumnFormatter from "./ActionsColumnFormatter";
import MuiAlert from '@material-ui/lab/Alert';

function EmployeesTable(props) {

  const { intl, toggleDrawer } = props;
  const dispatch = useDispatch();

  const {currentState} = useSelector(
    (state) => ({currentState: state.employees}),
    shallowEqual
  );
  const {employees, error} = currentState;

  useEffect(() => {
      dispatch(actions.fetchAllEmployees());
  }, [dispatch]);

  const columns = [
    {
      dataField: "action",
      text: intl.formatMessage({id: "GENERAL.ACTIONS"}),
      formatter: (value, row) => <ActionsColumnFormatter row={row} toggleDrawer={toggleDrawer}/>,
      style: {
        minWidth: "120",
      },
    },
    {
      dataField: "firstName",
      text: intl.formatMessage({id: "EMPLOYEE.NAME"}),
      formatter: (cellContent, row) => <div>{row.firstName} {row.lastName}</div>,
    },
    {
      dataField: "phoneNumber",
      text: intl.formatMessage({id: "EMPLOYEE.PHONE"})
    },
  ];

  const noEmployeesMessage = () => {
    return (
      <MuiAlert elevation={0} variant="outlined" severity="info" >
        {intl.formatMessage({id: "EMPLOYEE.NO_EMPLOYEE"})}
      </MuiAlert>
    )
  }

  return (
    <>
      {error && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div
            className="alert-text font-weight-bold">{error}</div>
        </div>
      )}
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      loading={true}
      remote
      keyField="id"
      data={employees === null ? [] : employees}
      columns={columns}
      noDataIndication={noEmployeesMessage}
    />
  </>
  );
}

export default injectIntl(EmployeesTable);

