import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { injectIntl, FormattedMessage } from "react-intl";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import * as hoursActions from "../_redux/hoursActions";

const useStyles = makeStyles({
  minWidth120: {
    "min-width": "120px",
  },
  width170: {
    width: "170px",
  },
  height: {
    height: "60px",
    margin: "0",
  },
  verticalAlign: {
    "vertical-align": "top",
  },
});

const DAYS = [
  "HOURS.MONDAY",
  "HOURS.TUESDAY",
  "HOURS.WEDNESDAY",
  "HOURS.THURSDAY",
  "HOURS.FRIDAY",
  "HOURS.SATURDAY",
  "HOURS.SUNDAY",
];

function HoursTable(props) {
  const { intl, errors, values, setFieldValue, status, selectedHourType } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <table className="table table-head-custom table-vertical-center overflow-hidden">
        <tbody>
          {values &&
            values.map((day, index) => {
              return (
                <tr key={index}>
                  <td className="vertical-top name">
                    <FormattedMessage id={`${DAYS[index]}`} />
                  </td>
                  <td className={classes.width170}>
                    {day.map((item, i) => {
                      return (
                        <div key={i}>
                          <TextField
                            name="id"
                            type="hidden"
                            value={values[index][i].id}
                          />
                          <TextField
                            name="day_of_week"
                            type="hidden"
                            value={values[index][i].day_of_week}
                          />
                          <TextField
                            className={classes.height}
                            error={
                              status === "submitted" &&
                              errors?.[index]?.[i]?.start_time
                            }
                            label={intl.formatMessage({
                              id: "HOURS.OPENING_HOUR",
                            })}
                            key={`${index}.${i}`}
                            fullWidth
                            disabled={values[index][0].closed}
                            margin="dense"
                            variant="outlined"
                            type="time"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={values[index][i].start_time}
                            onChange={(e) => {
                              return setFieldValue(
                                `hours.${index}.${i}.start_time`,
                                e.target.value
                              );
                            }
                            }
                          />
                        </div>
                      );
                    })}
                  </td>
                  <td className={classes.width170}>
                    {day.length ?
                      day.map((item, i) => {

                        return (
                          <TextField
                            className={classes.height}
                            error={
                              status === "submitted" &&
                              errors?.[index]?.[i]?.end_time
                            }
                            label={intl.formatMessage({
                              id: "HOURS.CLOSE_HOUR",
                            })}
                            key={`${index}.${i}`}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            type="time"
                            disabled={values[index][0].closed}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={values[index][i].end_time}
                            onChange={(e) =>
                              setFieldValue(
                                `hours.${index}.${i}.end_time`,
                                e.target.value
                              )
                            }
                          />
                        );
                      }) : null}
                  </td>
                  <td className="vertical-top closed">
                    <FormControlLabel
                      className={classes.height}
                      label={intl.formatMessage({ id: "HOURS.CLOSED" })}
                      control={
                        <Switch
                          checked={values[index][0] ? values[index][0].closed : false}
                          onChange={(value) => {
                            setFieldValue(
                              `hours.${index}.0.closed`,
                              !values?.[index][0].closed
                            );
                          }}
                        />
                      }
                    />
                  </td>
                  <td className={classes.minWidth120}>
                    {day.length ?
                      day.map((item, i) => {
                        return (
                          <div className={classes.height} key={`${index}.${i}`}>
                            <button
                              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                              disabled={values[index][0].closed}
                              onClick={() => {
                                let currentTimeFrame = [...values[index]];

                                let newTimeFrame = currentTimeFrame[0];
                                newTimeFrame = {
                                  ...newTimeFrame,
                                  start_time: "",
                                  end_time: "",
                                };

                                delete newTimeFrame["id"];

                                currentTimeFrame = [
                                  ...currentTimeFrame,
                                  newTimeFrame,
                                ];

                                setFieldValue(
                                  `hours.${index}`,
                                  currentTimeFrame
                                );
                              }}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  title={intl.formatMessage({
                                    id: "HOURS.ADD",
                                  })}
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Navigation/Plus.svg"
                                  )}
                                />
                              </span>
                            </button>
                            {i !== 0 && (
                              <button
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={(e) => {
                                  e.preventDefault();

                                  const aac = [...values[index]];
                                  const hourToBeDeleted = aac[i];

                                  if (hourToBeDeleted.id) {
                                    if (selectedHourType === 'Company') {
                                      dispatch(
                                        hoursActions.deleteHour(
                                          hourToBeDeleted.id
                                        )
                                      );
                                      setTimeout(() => {
                                        dispatch(hoursActions.fetchHours());
                                      }, 1000);
                                    } else {
                                      const { company_id, id: employeeId } = selectedHourType;

                                      dispatch(
                                        hoursActions.deleteEmployeeHours(
                                          hourToBeDeleted.id,
                                          employeeId
                                        )
                                      );
                                      setTimeout(() => {
                                        dispatch(hoursActions.fetchHoursByEmployee(company_id, employeeId));
                                      }, 1000);
                                    }
                                  } else {
                                    aac.splice(i, 1);

                                    setFieldValue(
                                      `hours.${index}`,
                                      aac
                                    );
                                  }

                                }}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <SVG
                                    title={intl.formatMessage({
                                      id: "HOURS.REMOVE",
                                    })}
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Minus.svg"
                                    )}
                                  />
                                </span>
                              </button>
                            )}
                          </div>
                        );
                      }) : null}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export default injectIntl(HoursTable);
