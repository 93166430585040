import { createSlice } from "@reduxjs/toolkit";

const initialWebsiteState = {
    loading: false,
    wordpressInstallation: {
        status: '',
        started: '',
        finished: '',
        admin_url: '',
        username: '',
        password: ''
    },
    error: null,
    success: false
};

export const callTypes = {
    list: "list",
    action: "action"
};

export const websiteSlice = createSlice({
    name: "website",
    initialState: initialWebsiteState,
    reducers: {
        clearErrors: (state) => {
            state.errors = null;
        },
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.loading = false;
            state.success = false;
        },
        startCall: (state) => {
            state.error = null;
            state.loading = true;
            state.success = false;
        },
        wordpressInstallationFetched: (state, action) => {
            const { wordpressInstallation } = action.payload;
            state.loading = false;
            state.success = true;
            state.error = null;
            state.wordpressInstallation = wordpressInstallation;
        }
    }
});
