import * as requestFromServer from "./customersCrud";
import { customersSlice, callTypes } from "./customersSlice";

const { actions } = customersSlice;

export const fetchCustomers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomers(queryParams)
    .then(response => {
      dispatch(actions.customersFetched({ total: response.data.total, customers: response.data.data })); // USE FOR REQUEST DATA
      //dispatch(actions.customersFetched({total: response.total, customers: response.data})); // USE FOR REAL MOCKS DATA
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchCustomersForAppointment = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomers(queryParams)
    .then(response => {
      dispatch(actions.customersForAppointmentFetched({ customers: response.data.data })); // USE FOR MOCKS DATA
      //dispatch(actions.customersForAppointmentFetched({customers: response.data})); // USE FOR REAL REQUEST DATA
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createCustomer = customerForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCustomer(customerForCreation)
    .then(response => {
      const { customer } = response.data;
      dispatch(actions.customerCreated({ customer }));
      return customer;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateCustomer = (id, customer) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(id, customer)
    .then(response => {
      const { customer } = response.data;
      dispatch(actions.customerUpdated({ customer }));
      return customer;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
      const { customer } = response.data;
      dispatch(actions.customerDeleted({ id: customer.id }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};


export const fetchCustomer = id => dispatch => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then(response => {
      const customer = response.data;
      dispatch(actions.customerFetched({ customerForEdit: customer }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

