import { createSlice } from "@reduxjs/toolkit";
const initialCommunicationState = {
    loading: false,
    actionsLoading: false,
    companyLogo: "",
    error: null,
    errors: null,
    companyInfo: null
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const communicationSlice = createSlice({
    name: "communications",
    initialState: initialCommunicationState,
    reducers: {
        clearErrors: (state) => {
            state.errors = null;
        },
        catchErrors: (state, action) => {
            state.errors = action.payload.errors;
            if (action.payload.callTYpes === callTypes.list) {
                state.loading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.errors = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        uploadLogo: (state, action) => {
            state.error = null;
            state.companyLogo = action.payload.logo;
        },
        companyFetched: (state, action) => {
            const { data } = action.payload;
            state.loading = true;
            state.error = null
            state.companyInfo = data;
        }
    },
});
