import axios from "axios";

export const SERVICE_URL = "services";

export async function getAllService() {
  return axios.get(`${SERVICE_URL}`);
}

export async function getServicesByEmployee(companyId, employeeId) {
  return axios.get(`companies/${companyId}/employees/${employeeId}/${SERVICE_URL}`);
}

export function createService(service) {
  return axios.post(SERVICE_URL, { service });
}

export function updateService(id, service) {
  return axios.put(`${SERVICE_URL}/${id}`, { service });
}

export function getServiceById(serviceId) {
  return axios.get(`${SERVICE_URL}/${serviceId}`);
}

export function deleteService(id) {
  return axios.delete(`${SERVICE_URL}/${id}`);
}
