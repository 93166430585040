/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import "date-fns";
import { isAfter, isSameDay } from "date-fns";
import { nl, enUS } from "date-fns/locale";
import { useFormik } from "formik";
import { injectIntl, FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { initialFilter } from "../../../Customers/pages/CustomersUIHelpers";
import * as customersActions from "../../../Customers/_redux/customers/customersActions";
import * as employeesAction from "../../../Employees/_redux/employeesActions";
import * as servicesAction from "../../../Services/_redux/servicesActions";
import * as appointmentsAction from "../../_redux/appointmentsActions";
import {
  addMinutesToDateString,
  convertDateToString,
  formatDate,
  formatDateToTime,
  setTimeToDate,
  subMinutesToDateString
} from "../../../../helpers/dateHelpers";
import { TextField, Divider, MenuItem, InputAdornment, Link } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { 
  AssignmentInd, 
  RoomService, 
  Phone, 
  Email, 
  AccountCircle 
} from "@material-ui/icons";
import { CustomButton } from "../../../../Components/CustomButton";
import { appointmentsSlice } from "../../_redux/appointmentsSlice";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getCurrentLanguage } from "../../../../../_metronic/i18n";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Errors } from "../../../../Components/Errors";
import * as requestFromServer from "../../../Customers/_redux/customers/customersCrud";
import { getAppointmentSchema } from "./AppointmentForm.scheme";

const initialValues = {
  customer: null,
  service: null,
  employee: null,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  remark: "",
  start: formatDateToTime(new Date()),
  end: formatDateToTime(new Date()),
  date: new Date(),
  type: "",
  company: "",
  repeat: "X",
  repeat_times: "",
};

function AppointmentForm(props) {
  const [searchCustomers, setSearchCustomers] = useState("");
  const {
    intl,
    appointmentId,
    toggleDrawer,
    start,
    setDrawer,
    onAppointmentChange,
    selectedEmployee,
  } = props;

  const iconColor = { color: "#B5B5C3" };
  const { user } = useSelector((state) => state.auth);
  const { actions } = appointmentsSlice;

  const AppointmentSchema = useMemo(() => getAppointmentSchema(intl, start), [intl, start]);

  const [initialValuesState, setInitialValueState] = useState(initialValues);
  const [originalEndTime, setOriginalEndTime] = useState("");
  const [serviceChanged, setServiceChanged] = useState(false);
  const formik = useFormik({
    initialValues: initialValuesState,
    validationSchema: AppointmentSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const startTime = setTimeToDate(values.date, values.start);
      const endTime = setTimeToDate(values.date, values.end);
      const isEndDateSameDay = isSameDay(startTime, endTime);
      const isEndDateAfterStartDate = isAfter(endTime, startTime);
      const appointment = {
        employee_id: values.employee.id,
        start: convertDateToString(startTime),
        end: convertDateToString(endTime),
        date: convertDateToString(values.date),
        remark: values.remark,
        service_id: values.service.id,
        company_id: values.company,
        repeat: values.repeat !== "X" ? values.repeat : "",
        repeat_times: values.repeat_times,
        type: "CLIENT",
      };

      if (!isEndDateAfterStartDate) {
        formik.setFieldError(
          "end",
          intl.formatMessage({ id: "APPOINTMENTS.ERROR.MIN.END.DATE" })
        );
        return;
      }

      if (!isEndDateSameDay) {
        formik.setFieldError(
          "end",
          intl.formatMessage({ id: "APPOINTMENTS.ERROR.MAX.END.DATE" })
        );
        return;
      }

      let createdCustomer;

      const newCustomer = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phone,
        email: values.email,
      };

      if (!values.customer) {
        const response = await requestFromServer.createCustomer(newCustomer);
        createdCustomer = response.data.customer;
      } else {
        const response = await requestFromServer.updateCustomer(values.customer.id, newCustomer);
        createdCustomer = response.data.customer;
      }

      if (appointmentId) {
        dispatch(
          appointmentsAction.updateAppointment(appointmentId, {
            ...appointment,
            customer_id: createdCustomer.id
          }, toggleDrawer)
        );
      } else {
        dispatch(
          appointmentsAction.createAppointment({
            ...appointment,
            customer_id: createdCustomer.id
          }, toggleDrawer)
        );
      }
    },
  });

  const {
    customersState,
    employeesState,
    servicesState,
    appointmentsState,
  } = useSelector(
    (state) => ({
      customersState: state.customers,
      employeesState: state.employees,
      servicesState: state.services,
      appointmentsState: state.appointments,
    }),
    shallowEqual
  );

  const {
    customersForAppointment: customers,
    listLoading: customersLoading,
  } = customersState;
  const {
    employeesByServiceId: employees,
  } = employeesState;
  const {
    servicesByEmployeeId: services,
  } = servicesState;
  const {
    error: appointmentsError,
    appointmentForEdit,
    actionsLoading,
  } = appointmentsState;

  const startDate = useMemo(() => {
    return appointmentForEdit?.startElements && formatDate(appointmentForEdit.startElements)
  }, [appointmentForEdit?.startElements]);

  const endDate = useMemo(() => {
    return  appointmentForEdit?.startElements && formatDate(appointmentForEdit.endElements)
  }, [appointmentForEdit?.startElements, appointmentForEdit?.endElements]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appointmentsAction.fetchAppointment(appointmentId));
  }, [appointmentId, dispatch]);

  const getInitialDate = useCallback((dateType) => {
    if (appointmentForEdit) {
      return dateType === "start" ? startDate : endDate;
    } else if (!appointmentForEdit && start !== "undefined") {
      return start;
    } else {
      return new Date();
    }
  }, [appointmentForEdit, startDate, endDate, start]);

  useEffect(() => {
    if (!actionsLoading) {
      let employee = null;
      let customer = null;
      let service = null;
      let firstName = "";
      let lastName = "";
      let email = "";
      let phone = "";
      
      if (appointmentId && appointmentForEdit && Object.keys(appointmentForEdit).length > 0) {
        employee = appointmentForEdit.employee || null;
        customer = appointmentForEdit.customer || null;
        service = appointmentForEdit.service || null;
        firstName = appointmentForEdit.customer?.firstName;
        lastName = appointmentForEdit.customer?.lastName;
        email = appointmentForEdit.customer?.email;
        phone = appointmentForEdit.customer?.phoneNumber;
      } else {
        employee = employeesState.employees.find(
          (e) => e.id === selectedEmployee
        );
      }

      setOriginalEndTime(formatDateToTime(getInitialDate("end")));
  
      setInitialValueState({
        remark: appointmentForEdit?.remark || "",
        start: formatDateToTime(getInitialDate("start")),
        end: formatDateToTime(getInitialDate("end")),
        date: getInitialDate("start"),
        service: service,
        customer: customer,
        employee: employee,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        type: appointmentForEdit?.type || "",
        company: user?.company?.id,
        repeat: appointmentForEdit?.repeat || "X",
        repeat_times: appointmentForEdit?.repeat_times || "",
      });
    }
  }, [getInitialDate, start, user, appointmentId, selectedEmployee, actionsLoading]);

  useEffect(() => {
    if (searchCustomers) {
      dispatch(
        customersActions.fetchCustomersForAppointment({
          ...initialFilter,
          filter: { name: searchCustomers },
          pageSize: 1000,
        })
      );
    }
  }, [searchCustomers, dispatch]);

  useEffect(() => {
    dispatch(
      employeesAction.fetchEmployeesByService(
        formik.values.company,
        formik.values?.service?.id || null
      )
    );
  }, [formik.values.company, formik.values.service, dispatch]);

  useEffect(() => {
    if(formik.values.service && serviceChanged) {
      formik.setValues({
        ...formik.values,
        end: addMinutesToDateString(originalEndTime, formik.values.service.duration),
      });
    }
  }, [formik.values.service, originalEndTime, serviceChanged])

  useEffect(() => {
    dispatch(
      servicesAction.fetchServicesByEmployee(
        formik.values.company,
        formik.values?.employee?.id
      )
    );
  }, [formik.values.company, formik.values.employee, dispatch]);

  const appointmentNotFound =
    appointmentsError && appointmentsError.includes("404");

  const language = getCurrentLanguage();
  const datePickerLocale = language?.selectedLang === "en" ? enUS : nl;

  if (appointmentNotFound) {
    return (
      <>
        <Alert severity="info" className="mb-3">
          {intl.formatMessage({
            id: "APPOINTMENTS.HAS.BEEN.DELETED",
          })}
        </Alert>
        <div className="d-flex justify-content-center">
          <CustomButton
            type="button"
            variant="secondary"
            className="mr-5"
            onClick={() => {
              return window.location.reload();
            }}
          >
            <FormattedMessage id="GENERAL.CLOSE" />
          </CustomButton>
        </div>
      </>
    );
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
    >
      <div className="form-group fv-plugins-icon-container">

        <Errors />

        {appointmentForEdit?.appointment?.repeat_times > 0 && (
          <Alert severity="info" className="mb-3">
            {intl.formatMessage({
              id: "APPOINTMENTS.APPOINTMENT_PART_OF_REPEAT",
            })}
            &nbsp;
            <Link
              href="#"
              onClick={() => {
                dispatch(
                  appointmentsAction.deleteAppointment(
                    appointmentForEdit.appointment.id
                  )
                );
              }}
            >
              {intl.formatMessage({ id: "APPOINTMENTS.DELETE_SERIES" })}
            </Link>
          </Alert>
        )}

        {(!appointmentId || !actionsLoading) && (
          <Autocomplete
            value={formik.values.customer}
            autoComplete={false}
            onChange={(e, value) => {
              value
                ? formik.setValues({
                  ...formik.values,
                  customer: value || null,
                  phone: value?.phoneNumber || "",
                  email: value?.email || "",
                  firstName: value?.firstName || "",
                  lastName: value?.lastName || "",
                })
                : formik.setValues({
                  ...formik.values,
                  customer: null,
                  phone: "",
                  email: "",
                  firstName: "",
                  lastName: "",
                });
            }}
            onInputChange={(e, value) => setSearchCustomers(value)}
            options={searchCustomers ? customers || [] : []}
            getOptionSelected={(option, value) => option?.id === value?.id}
            loading={customersLoading}
            noOptionsText={
              searchCustomers
                ? intl.formatMessage({ id: "APPOINTMENTS.NO_CUSTOMER" })
                : intl.formatMessage({
                  id: "APPOINTMENTS.TYPE_SOMETHING_TO_FIND_CUSTOMER",
                })
            }
            getOptionLabel={({ firstName, lastName }) =>
              `${firstName} ${lastName}`
            }
            renderInput={(params) => (
              <TextField
                {...{
                  ...params,
                  InputProps: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle style={iconColor} />
                      </InputAdornment>
                    ),
                  },
                }}
                label={intl.formatMessage({
                  id: "APPOINTMENTS.TYPE_SOMETHING_TO_FIND_CUSTOMER",
                })}
                margin="dense"
                variant="outlined"
              />
            )}
          />
        )}
        {(!appointmentId || !actionsLoading) && (
          <Autocomplete
            name="employee"
            value={formik.values.employee || null}
            onChange={(e, value) => {
              formik.setFieldTouched("employee", true);
              formik.setFieldValue("employee", value);
            }}
            options={employees || []}
            getOptionLabel={(option) => {
              const employee = employeesState?.employees?.find(
                (e) => e.id === option.id
              );

              return `${employee?.firstName} ${employee?.lastName}`;
            }}
            getOptionSelected={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...{
                  ...params,
                  InputProps: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentInd style={iconColor} />
                      </InputAdornment>
                    ),
                  },
                }}
                error={!!(formik.touched.employee && formik.errors.employee)}
                helperText={formik.errors.employee}
                label={intl.formatMessage({
                  id: "APPOINTMENTS.SELECT_EMPLOYEE",
                })}
                margin="dense"
                variant="outlined"
              />
            )}
          />
        )}
        {(!appointmentId || !actionsLoading) && (
          <Autocomplete
            value={formik.values.service || null}
            onChange={(e, value) => {
              if(value && value.id !==null && value.id !== formik.values?.service?.id) {
                setServiceChanged(true);
              }
              formik.setFieldTouched("service", true);
              formik.setFieldValue("service", value || null);
            }}
            options={services !== null ? [...services].sort((a,b) => a?.category?.name.localeCompare(b?.category?.name)) : []}
            loading={customersLoading}
            groupBy={(option) => {
              if (option.category === null) { return intl.formatMessage({id: "APPOINTMENTS.NO_CATEGORY"})}
              return option?.category?.name && option.category.name;
            }}
            getOptionLabel={({ name }) => name || ""}
            getOptionSelected={(option, value) => {
              return option?.id === value?.id
            }}
            renderInput={(params) => (
              <TextField
                {...{
                  ...params,
                  InputProps: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <RoomService style={iconColor} />
                      </InputAdornment>
                    ),
                  },
                }}
                helperText={
                  formik.errors.service ? formik.errors.service : 
                    ((formik.values?.service?.resourceNames) ? 
                      intl.formatMessage({id: "SERVICE.USES_RESOURCES"})+formik.values?.service?.resourceNames : '')
                }
                error={!!(formik.touched.service && formik.errors.service)}
                label={intl.formatMessage({
                  id: "APPOINTMENTS.SELECT_SERVICE",
                })}
                margin="dense"
                variant="outlined"
              />
            )}
          />
        )}
        <Divider light className="mt-4 mb-4" />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={datePickerLocale}
        >
          <div className="d-flex flex-row">
              <div className="col-4 col-sm-3 p-0 pr-3">
                <TextField
                  id="start"
                  error={!!(formik.touched.start && formik.errors.start)}
                  helperText={formik.errors.start}
                  label={intl.formatMessage({ id: "APPOINTMENTS.STARTTIME" })}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values?.start}
                  onChange={(e) => {
                    formik.setFieldValue("start", e.target.value);
                    formik.setFieldValue("end", addMinutesToDateString(e.target.value, formik.values.service.duration));
                  }}
                />
              </div>
              <div className="col-4 col-sm-3 p-0 mr-2 pr-4">
                <TextField
                  id="end"
                  error={!!(formik.touched.end && formik.errors.end)}
                  helperText={formik.errors.end}
                  label={intl.formatMessage({ id: "APPOINTMENTS.ENDTIME" })}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values?.end}
                  onChange={(e) => {
                    setOriginalEndTime(subMinutesToDateString(e.target.value, formik.values.service.duration));
                    formik.setFieldValue("end", e.target.value);
                  }}
                />
              </div>
            <div className="col-4 col-sm-6 pl-2 pr-0">
              <DatePicker
                id="date"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={!!(formik.touched.date && formik.errors.date)}
                    helperText={formik.errors.date}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "97%" }}
                  />
                )}
                label={intl.formatMessage({ id: "APPOINTMENTS.DATE" })}
                value={formik.values?.date}
                onChange={(value) => {
                  formik.setFieldValue("date", value);
                }}
                format="dd-MM-yyyy"
                hideTabs={true}
                showToolbar={false}
              />
            </div>
          </div>
        </LocalizationProvider>
        <Divider light className="mt-4 mb-4" />
        <div className="row">
          <div className="col-sm-6">
            <TextField
              error={!!(formik.touched.firstName && formik.errors.firstName)}
              id="firstName"
              label={intl.formatMessage({ id: "APPOINTMENTS.FIRST_NAME" })}
              name="firstName"
              margin="dense"
              variant="outlined"
              fullWidth
              helperText={formik.errors.firstName}
              {...formik.getFieldProps("firstName")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              error={!!(formik.touched.lastName && formik.errors.lastName)}
              id="lastName"
              label={intl.formatMessage({ id: "APPOINTMENTS.LAST_NAME" })}
              name="lastName"
              margin="dense"
              variant="outlined"
              fullWidth
              helperText={formik.errors.lastName}
              {...formik.getFieldProps("lastName")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <TextField
          error={!!(formik.touched.phone && formik.errors.phone)}
          id="phone"
          label={intl.formatMessage({ id: "APPOINTMENTS.PHONE_NUMBER" })}
          name="phone"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.phone}
          {...formik.getFieldProps("phone")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={!!(formik.touched.email && formik.errors.email)}
          id="email"
          label={intl.formatMessage({ id: "APPOINTMENTS.EMAIL" })}
          type="email"
          name="email"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.email}
          {...formik.getFieldProps("email")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        <Divider light className="mt-4 mb-4" />
        <TextField
          id="remark"
          label={intl.formatMessage({ id: "APPOINTMENTS.REMARK" })}
          multiline
          rows={4}
          fullWidth
          margin="dense"
          variant="outlined"
          {...formik.getFieldProps("remark")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {appointmentForEdit === undefined && (
          <div className="row">
            <div className="col-sm-8">
              <TextField
                error={!!(formik.touched.repeat && formik.errors.repeat)}
                helperText={formik.errors.repeat}
                select
                id="repeat"
                label={intl.formatMessage({ id: "APPOINTMENTS.REPEAT" })}
                margin="dense"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("repeat")}
              >
                <MenuItem key="X" value="X">
                  {intl.formatMessage({ id: "GENERAL.NONE" })}
                </MenuItem>
                <MenuItem key="D" value="D">
                  {intl.formatMessage({ id: "APPOINTMENTS.DAILY" })}
                </MenuItem>
                <MenuItem key="W" value="W">
                  {intl.formatMessage({ id: "APPOINTMENTS.WEEKLY" })}
                </MenuItem>
                <MenuItem key="M" value="M">
                  {intl.formatMessage({ id: "APPOINTMENTS.MONTHLY" })}
                </MenuItem>
                <MenuItem key="Y" value="Y">
                  {intl.formatMessage({ id: "APPOINTMENTS.YEARLY" })}
                </MenuItem>
              </TextField>
            </div>
            <div className="col-sm-4">
              <TextField
                id="repeat_times"
                label={intl.formatMessage({ id: "APPOINTMENTS.REPEAT_TIMES" })}
                margin="dense"
                variant="outlined"
                type="number"
                fullWidth
                InputProps={{
                  inputProps: { min: 0, max: 365 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("repeat_times")}
              />
            </div>
          </div>
        )}
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <CustomButton
          type="submit"
          variant="primary"
          loading={actionsLoading}
          disabled={formik.isSubmitting}
          className="mr-5"
        >
          <FormattedMessage id="GENERAL.SUBMIT" />
        </CustomButton>
        <CustomButton
          type="button"
          variant="secondary"
          className="mr-5"
          onClick={() => dispatch(appointmentsAction.resetAppointmentForEdit(toggleDrawer))}
        >
          <FormattedMessage id="GENERAL.CLOSE" />
        </CustomButton>
        {appointmentId && (
          <CustomButton
            variant="outline-danger"
            type="button"
            onClick={() => {
              dispatch(
                appointmentsAction.deleteAppointment(appointmentId)
              ).then(() => {
                onAppointmentChange(null);
                setDrawer(false);
                actions.appointmentDrawerClose();
              });
            }}
          >
            <FormattedMessage id="APPOINTMENTS.CANCEL_APPOINTMENT" />
          </CustomButton>
        )}
      </div>
    </form>
  );
}

export default injectIntl(AppointmentForm);
