import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import * as actions from "../../_redux/servicesActions";
import ActionsColumnFormatter from "./ActionsColumnFormatter";
import CheckIcon from "@material-ui/icons/Check";
import MuiAlert from "@material-ui/lab/Alert";
import { Errors } from "../../../../Components/Errors";

const useStyles = makeStyles({
  icon: {
    "text-align": "center",
  },
});

function ServicesTable(props) {
  const { intl, toggleDrawer } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.services }),
    shallowEqual
  );
  const { services } = currentState;

  useEffect(() => {
    dispatch(actions.fetchAllServices());
  }, [dispatch]);

  const columns = [
    {
      dataField: "action",
      text: intl.formatMessage({ id: "GENERAL.ACTIONS" }),
      formatter: (value, row) => (
        <ActionsColumnFormatter row={row} toggleDrawer={toggleDrawer} />
      ),
      style: {
        minWidth: "120px",
      },
    },
    {
      dataField: "category.name",
      text: intl.formatMessage({ id: "SERVICE.CATEGORY" }),
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "SERVICE.DESCRIPTION" }),
    },
    {
      dataField: "price",
      text: intl.formatMessage({ id: "SERVICE.PRICE" }),
    },
    {
      dataField: "duration",
      text: intl.formatMessage({ id: "SERVICE.LENGTH" })
    },
    {
      dataField: "resourceNames",
      text: intl.formatMessage({ id: "SERVICE.RESOURCES" }),
    },
    {
      dataField: "blocks_agenda",
      text: intl.formatMessage({ id: "SERVICE.BLOCKS_AGENDA" }),
      formatter: (value) =>
        value ? (
          <div className={classes.icon}>
            <CheckIcon />
          </div>
        ) : null,
    }
  ];

  const noServicesMessage = () => {
    return (
      <MuiAlert elevation={0} variant="outlined" severity="info">
        {intl.formatMessage({ id: "SERVICE.NO_SERVICES" })}
      </MuiAlert>
    );
  };

  return (
    <>
      <Errors />
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        loading={true}
        remote
        keyField="id"
        data={services === null ? [] : services}
        columns={columns}
        noDataIndication={noServicesMessage}
      />
    </>
  );
}

export default injectIntl(ServicesTable);
