import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    "& .card-header": {
      justifyContent: "center !important",
      flexDirection: "column",
    },
  },
  link: {
    color: "#BFBFBF",
    marginRight: "10px",
  },
  apiKeyBox: {
    marginTop: "10px",
    backgroundColor: "#F5F5F5",
    color: "#818181",
    display: "flex",
    alignItems: "center",
    "& p": {
      margin: "0",
    },
  },
}));

const WordpressIntegration = (props) => {
  const { intl } = props;
  const classes = useStyles();
  const wordpressApiKey = useSelector(
    (state) => state.auth.user.company.apikey
  );
  const [keyCopyText, setKeyCopyText] = useState(
    intl.formatMessage({ id: "GENERAL.COPY" })
  );

  useEffect(() => {
    setTimeout(() => {
      setKeyCopyText(intl.formatMessage({ id: "GENERAL.COPY" }));
    }, 3000);
  }, [keyCopyText, intl]);

  return (
    <Card className={classes.card}>
      <CardHeader title={intl.formatMessage({ id: "INTEGRATION.WORDPRESS" })}>
        <Link className={classes.link} to="/your-website">
          {intl.formatMessage({ id: "INTEGRATION.BACKBUTTON" })}
        </Link>
      </CardHeader>
      <CardBody>
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-cloud-plus"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z"
            />
            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
          </svg>
          &nbsp;{intl.formatMessage({ id: "INTEGRATION.WORDPRESS_PLUGIN" })}
        </h4>
        <div className={classes.apiKeyBox}>
          <p>{wordpressApiKey}</p>
          <Tooltip
            disableFocusListener
            title={keyCopyText}
            placement="right"
            arrow
          >
            <CopyToClipboard
              text={wordpressApiKey}
              className="btn btn-icon btn-light btn-sm ml-3"
            >
              <button
                className="btn btn-icon btn-light btn-sm ml-3"
                onClick={() =>
                  setKeyCopyText(intl.formatMessage({ id: "GENERAL.COPIED" }))
                }
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Clipboard.svg"
                    )}
                  />
                </span>
              </button>
            </CopyToClipboard>
          </Tooltip>
        </div>
        <p className="mt-3">
          {intl.formatMessage({ id: "INTEGRATION.WORDPRESS_TEXT" })}
        </p>
      </CardBody>
    </Card>
  );
};

export default injectIntl(WordpressIntegration);
