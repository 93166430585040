/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";

export function Title() {
  const location = useLocation();
  const subheader = useSubheader();

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setTitle(aside.title || header.title);
  }, [location.pathname, subheader]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
    <div className="header-title">
      <div className="d-flex align-items-center">
        <h5 className="text-dark font-weight-bold">{subheader.title}</h5>
      </div>
    </div>
  );
}
