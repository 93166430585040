import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import * as actions from "../../_redux/categoriesActions";
import ActionsColumnFormatter from "./ActionsColumnFormatter";
import MuiAlert from '@material-ui/lab/Alert';
import { Errors } from "../../../../Components/Errors";

function CategoriesTable(props) {

  const { intl, toggleDrawer } = props;
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.categories }),
    shallowEqual
  );
  const { categories, listLoading } = currentState;

  useEffect(() => {
    dispatch(actions.fetchAllCategories());
  }, [dispatch]);

  const columns = [
    {
      dataField: "action",
      text: intl.formatMessage({ id: "GENERAL.ACTIONS" }),
      formatter: (value, row) => <ActionsColumnFormatter row={row} toggleDrawer={toggleDrawer} />,
      style: {
        width: "200px",
      },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "CATEGORY.DESCRIPTION" }),
    }
  ];

  const noCategoriesMessage = () => {
    return (
      <MuiAlert elevation={0} variant="outlined" severity="info" >
        {intl.formatMessage({ id: "CATEGORY.NO_CATEGORY" })}
      </MuiAlert>
    );
  };

  return (
    <>
      <Errors />
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        loading={listLoading}
        remote
        keyField="description"
        data={categories === null ? [] : categories}
        columns={columns}
        noDataIndication={noCategoriesMessage}
        overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(198,225,255,0.5)' }) } })}
      />
    </>
  );
}

export default injectIntl(CategoriesTable);

