import React from "react";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import * as employeesActions  from "../../_redux/employeesActions";
import { useDispatch } from "react-redux";

function ActionsColumnFormatter(props) {

  const {intl, row, toggleDrawer} = props;
  const dispatch = useDispatch();
  
  return (
    <>
      <button
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={toggleDrawer(true, row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            title={intl.formatMessage({id: "EMPLOYEE.EDIT_EMPLOYEE"})}
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </button>
      <> </>

      <button
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => {
          dispatch(employeesActions.deleteEmployee(row.id));
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG 
            title={intl.formatMessage({id: "EMPLOYEE.DELETE_EMPLOYEE"})}
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} 
          />
        </span>
      </button>
    </>
  );
}

export default injectIntl(ActionsColumnFormatter);
