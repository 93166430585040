import React, {useState} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ServicesTable from "./services-table/ServicesTable";
import ServiceDrawer from "./ServiceDrawer/SeviceDrawer";
import { injectIntl, FormattedMessage } from "react-intl";
import RoomServiceIcon from "@material-ui/icons/RoomService";

export function ServicesCard(props) {

  const {intl} = props;
  const [drawer, setDrawer] = useState(false);
  const [serviceId, setServiceId] = useState(null);

  const toggleDrawer = (open, id) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
    setServiceId(id)
  };

  return (
    <Card>
      <CardHeader 
        title={intl.formatMessage({id: "SERVICE.SERVICE_LIST"})}
        icon={<RoomServiceIcon color="secondary" fontSize="medium" style={{ marginRight: 10 }} />}
      >
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setDrawer(true)}
          >
            <FormattedMessage id={"SERVICE.ADD"}/>
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ServicesTable toggleDrawer={toggleDrawer} />
      </CardBody>
      <ServiceDrawer open={drawer} toggleDrawer={toggleDrawer} serviceId={serviceId} />
    </Card>
  );
}

export default injectIntl(ServicesCard);
