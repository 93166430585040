import React from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT"}))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, {max: 50 }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus }) => {
      requestPassword(values.email)
        .then(() => {
          setStatus({
            message: intl.formatMessage(
              {id: "AUTH.FORGOT.SUCCESS"},
            ),
            type: "info"
          });
        })
        .catch(() => {
          setStatus({
            message: intl.formatMessage(
              {id: "AUTH.VALIDATION.NOT_FOUND"},
              {name: values.email}
            ),
            type: "danger"
          });
        });
    },
  });

  return (
    <>
        <Fade in={true} timeout={2000}>
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <img
              alt="Logo"
              height="40"
              className="mb-15"
              src={toAbsoluteUrl("/media/logos/logo-light.png")}
            />
            <h3 className="font-size-h1"><FormattedMessage id="AUTH.FORGOT.TITLE" /></h3>
            <div className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.FORGOT.DESC" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className={`mb-10 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">
                  {formik.status.message}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
            <TextField
              error={!!(formik.touched.email && formik.errors.email)}
              id="email"
              placeholder={intl.formatMessage({ id: "AUTH.GENERAL.EMAIL"})}
              type="email"
              name="email"
              margin="normal"
              variant="outlined"
              autoComplete="username"
              helperText={formik.errors.email}
              {...formik.getFieldProps("email")}
            />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <FormattedMessage id="GENERAL.SEND" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="GENERAL.CANCEL" />
                </button>
              </Link>
            </div>
          </form>
        </div>
        </Fade>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
