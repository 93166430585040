import React from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import { injectIntl } from "react-intl";
import AddressForm from "./AddressForm";
import BusinessIcon from '@mui/icons-material/Business';

function AddressCard(props) {
  const { intl } = props;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: "ADDRESS.ADDRESS" })}
        icon={<BusinessIcon color="primary" fontSize="medium" style={{ marginRight: 10 }} />}
      >
      </CardHeader>
      <CardBody>
        <AddressForm />
      </CardBody>
    </Card>
  );
}

export default injectIntl(AddressCard);
