import "date-fns";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import TextField from "@material-ui/core/TextField";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from "@material-ui/icons/Phone";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import * as servicesAction from "../../../Services/_redux/servicesActions";
import * as employeesActions from "../../_redux/employeesActions";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CustomButton } from "../../../../Components/CustomButton";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '95%'
  },
}));

function EmployeeForm(props) {

  const { intl, toggleDrawer, employeeId } = props;
  const classes = useStyles();
  const iconColor = { color: "#B5B5C3" };
  const dispatch = useDispatch();

  const EmployeeSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(
        125,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 125 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .max(
        125,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
          { max: 125 }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneNumber: Yup.string().max(
      32,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
        { max: 32 }
      )
    ),
    serviceIds: Yup.array(Yup.number()),
  });

  useEffect(() => {
    dispatch(employeesActions.fetchEmployee(employeeId));
  }, [employeeId, dispatch]);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    serviceIds: [],
  });

  const [chosenServices, setChosenServices] = useState([]);

  const formik = useFormik({
    initialValues,
    validationSchema: EmployeeSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const employee = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        serviceIds: chosenServices,
      };
      try {
        if (employeeId) {
          dispatch(
            employeesActions.updateEmployee(employeeId, { ...employee })
          );
        } else {
          dispatch(employeesActions.createEmployee({ ...employee }));
        }
        toggleDrawer(false)(null);
      } catch (e) {
        console.error(e);
      }
    },
  });

  const { servicesState, employeesState } = useSelector(
    (state) => ({
      servicesState: state.services,
      employeesState: state.employees,
    }),
    shallowEqual
  );

  const {
    services,
    error: servicesError,
  } = servicesState;
  const { error: employeesError, employeeForEdit } = employeesState;

  useEffect(() => {
    setInitialValues(employeeForEdit);
    setChosenServices(employeeForEdit?.serviceIds);
  }, [employeeForEdit]);

  useEffect(() => {
    dispatch(servicesAction.fetchAllServices());
  }, [dispatch]);

  const handleChange = (event) => {
    setChosenServices(event.target.value);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
    >
      <div className="form-group fv-plugins-icon-container">
        {(servicesError || employeesError) && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">
              {servicesError || employeesError}
            </div>
          </div>
        )}
        <TextField
          id="firstName"
          error={!!(formik.touched.firstName && formik.errors.firstName)}
          label={intl.formatMessage({ id: "EMPLOYEE.FIRST_NAME" })}
          name="firstName"
          fullWidth
          margin="dense"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentIndIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
          helperText={formik.errors.firstName}
          {...formik.getFieldProps("firstName")}
        />
        <TextField
          id="lastName"
          error={!!(formik.touched.lastName && formik.errors.lastName)}
          label={intl.formatMessage({ id: "EMPLOYEE.LAST_NAME" })}
          name="lastName"
          fullWidth
          margin="dense"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentIndIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
          helperText={formik.errors.lastName}
          {...formik.getFieldProps("lastName")}
        />
        <TextField
          error={!!(formik.touched.phoneNumber && formik.errors.phoneNumber)}
          id="phoneNumber"
          label={intl.formatMessage({ id: "EMPLOYEE.PHONE" })}
          name="phoneNumber"
          margin="dense"
          variant="outlined"
          fullWidth
          helperText={formik.errors.phoneNumber}
          {...formik.getFieldProps("phoneNumber")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon style={iconColor} />
              </InputAdornment>
            ),
          }}
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="serviceIds-input">{intl.formatMessage({ id: "EMPLOYEE.SERVICES" })}</InputLabel>
          <Select
            id="serviceIds"
            multiple
            fullWidth
            value={chosenServices}
            onChange={handleChange}
            input={<Input id="serviceIds-input" />}
          >
            <MenuItem disabled value="">
              <em>{intl.formatMessage({ id: "EMPLOYEE.SERVICES" })}</em>
            </MenuItem>
            {services.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{intl.formatMessage({ id: "EMPLOYEE.SERVICES_HELPER_TEXT" })}</FormHelperText>
        </FormControl>
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <CustomButton
          variant='primary'
          type="submit"
          className="mr-5"
        >
          <FormattedMessage id="GENERAL.SUBMIT" />
        </CustomButton>
        <CustomButton
          variant='secondary'
          type="button"
          onClick={toggleDrawer(false)}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </CustomButton>
      </div>
    </form>
  );
}

export default injectIntl(EmployeeForm);
