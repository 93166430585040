import React, { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(response => {
            disableLoading();
            props.login(response.data.accessToken);
            const payload = {
              profileObj: response.data.user.google_profile,
              accessToken: response.data.user.google_profile?.access_token
            }
            props.googleSignIn(payload);
          })
          .catch((error) => {
            disableLoading();
            setSubmitting(false);
            setStatus(error?.response?.data?.message);
          });
      }, 1000);
    },
  });

  return (
    <Fade in={true} timeout={1000}>
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="text-center mb-lg-10">
          <img
            alt="Logo"
            height="40"
            className="mb-15"
            src={toAbsoluteUrl("/media/logos/logo-light.png")}
          />
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          <div className="form-group fv-plugins-icon-container">
            <TextField
              error={!!(formik.touched.email && formik.errors.email)}
              id="email"
              label={intl.formatMessage({ id: "AUTH.GENERAL.EMAIL" })}
              type="email"
              name="email"
              margin="normal"
              variant="outlined"
              autoComplete="username"
              helperText={formik.errors.email}
              {...formik.getFieldProps("email")}
            />
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              id="password"
              label={intl.formatMessage({ id: "AUTH.GENERAL.PASSWORD" })}
              name="password"
              type="password"
              margin="normal"
              variant="outlined"
              helperText={formik.errors.password}
              {...formik.getFieldProps("password")}
            />
          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <Link
              to="/auth/forgot-password"
              className="text-dark-50 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>
                <FormattedMessage id="AUTH.LOGIN.BUTTON" />
              </span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
        {/*end::Form*/}
      </div>
    </Fade>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
