/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import { ContentRoute } from "../../../../_metronic/layout"
import { FormattedMessage } from "react-intl";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ShowHeader from "./ShowHeader";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
      const aSideStyle = {
        backgroundColor: '#ba212d'
      }
      return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
              id="kt_login"
          >
            {/*begin::Aside*/}
            <Hidden mdDown>
              <Fade in={true} timeout={2000}>
              <div
                  className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-20 p-lg-20"
                  style={aSideStyle}
              >
                {/*begin: Aside Container*/}
                <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={750}>
                <div className="d-flex flex-row-fluid flex-column text-center">

                  {/* start:: Aside content */}
                  <div className="flex-column-fluid d-flex flex-column text-center mt-40 ml-30">
                    <h2 className="text-white">                      
                      <FormattedMessage id={"AUTH.REDMAKI_SLAPSTICK"}/>
                    </h2>
                  </div>
                  {/* end:: Aside content */}                

                  {/* start:: Aside footer for desktop */}
                  <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                    <div className="opacity-70 font-weight-bold	text-white">
                      &copy; 2021 RedMaki
                    </div>
                    <div className="d-flex">
                    </div>
                  </div>
                  {/* end:: Aside footer for desktop */}
                </div>
                </Slide>
                {/*end: Aside Container*/}
              </div>
              {/*begin::Aside*/}
              </Fade>
            </Hidden>

            {/*begin::Content*/}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
              {/*begin::Content header*/}
                <Switch>
                  <ContentRoute path="/auth/login" component={ShowHeader} />
                  <ContentRoute path="/auth/registration" component={ShowHeader} />
                  <ContentRoute path="/auth/forgot-password" component={ShowHeader} />
                  <ContentRoute path="/auth/reset-password" component={ShowHeader}/>
                </Switch>
              {/*end::Content header*/}

              {/* begin::Content body */}

              <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={750}>
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                  <Switch>
                    <ContentRoute path="/auth/login" component={Login}/>
                    <ContentRoute path="/auth/registration" component={Registration}/>
                    <ContentRoute path="/auth/forgot-password" component={ForgotPassword}/>
                    <ContentRoute path="/auth/reset-password" component={ResetPassword}/>
                    <Redirect from="/auth" exact={true} to="/auth/login"/>
                    <Redirect to="/auth/login"/>
                  </Switch>
              </div>
              </Slide>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div
                  className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  &copy; 2021 RedMaki
                </div>
                <div className="d-flex order-1 order-sm-2 my-2">
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
