import * as requestFromServer from "./hoursCrud";
import { hoursSlice, callTypes } from "./hoursSlice";

const { actions } = hoursSlice;

export const fetchHours = () => (dispatch) => {
  return requestFromServer
    .getHours()
    .then((response) => {
      const { hours } = response.data;
      dispatch(actions.hoursFetched({ hours }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchHoursByEmployee = (companyId, employeeId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getHoursByEmployee(companyId, employeeId)
    .then((response) => {
      const { hours } = response.data;
      dispatch(actions.employeeHoursFetched({ hours, employeeId }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const fetchExtraHoursByEmployee = (companyId, employeeId, startDate, endDate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getExtraHoursByEmployee(companyId, employeeId, startDate, endDate)
    .then((response) => {
      const { hours } = response.data;
      dispatch(actions.employeeExtraHoursFetched({ hours, employeeId }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const createHour = (hour) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createHour(hour)
    .then((response) => {
      const { hour } = response.data;
      dispatch(actions.hourCreated({ hour }));
      return hour;
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const createEmployeeHour = (hour) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createHour(hour)
    .then((response) => {
      const { hour } = response.data;
      dispatch(actions.employeeHourCreated({ hour }));
      return hour;
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const createEmployeeExtraHour = (hour) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createHour(hour)
    .then((response) => {
      const { hour } = response.data;
      dispatch(actions.employeeExtraHourCreated({ hour }));
      return hour;
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateHour = (hour) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .updateHour(hour)
    .then((response) => {
      const { hour } = response.data;
      dispatch(actions.hourUpdated({ hour }));
      return hour;
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateEmployeeHour = (hour) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .updateHour(hour)
    .then((response) => {
      const { hour } = response.data;
      dispatch(actions.employeeHourUpdated({ hour }));
      return hour;
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const deleteHour = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteHour(id)
    .then((response) => {
      const { hours } = response.data;
      dispatch(actions.hourDeleted({ id: hours.id }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const deleteEmployeeHours = (id, employeeId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteHour(id)
    .then((response) => {
      const { hours } = response.data;
      dispatch(actions.employeeHoursDeleted({ id: hours.id, employeeId }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};

export const deleteEmployeeHoursAndReturnCompanyHours = (companyId, employeeId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEmployeeHoursAndReturnCompanyHours(companyId, employeeId)
    .then((response) => {
      const { hours } = response.data;
      dispatch(actions.resetEmployeeHours({ hours, employeeId }));
    })
    .catch((error) => {
      const errors = error.response.data.errors;

      dispatch(actions.catchErrors({ errors }));
    });
};