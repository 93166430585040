/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import { injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import DropdownMenu2 from "../../../../_metronic/_partials/dropdowns/DropdownMenu2";
import * as appointmentsActions from "../../Appointments/_redux/appointmentsActions";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns/DropdownCustomToggler";

const AppointmentsWidget = ({ className, chartColor = "danger", intl }) => {

  const options = useMemo(() => {
    return {
        series: [{
            name: "",
            data: []
        }],
        chart: {
            type: "line",
            height: 150,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            },
            sparkline: {
                enabled: true
            }
        },      
        dataLabels: {
            enabled: true,
            offsetY: 4,
        },
        xaxis: {
            type: 'category',            
            tickPlacement: 'between',
            categories: ['20000101','20000101','20000101','20000101','20000101','20000101','20000101'],
            labels: {
                show: true,
                formatter: function(value) {
                    if (value.length === 8) {
                        return value.substring(6,8)+'-'+value.substring(4,6)+'-'+value.substring(0,4)                            
                    } else {
                        return value;
                    }                    
                },
            },
        }
    };
  }, []);

  const appointmentsSummary = useSelector(
    (state) => state.appointments.appointmentsSummary
  );
  const [chart, setChart] = useState();
  const [total, setTotal] = useState();
  const [timeFrame, setTimeFrame] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_4_chart");

    if (!element) {
      return;
    }

    const chart = new ApexCharts(element, options);
    setChart(chart);

    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appointmentsActions.fetchAppointmentsSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chart && appointmentsSummary) {
      chart.updateSeries([
        {
          name: intl.formatMessage({ id: "APPOINTMENTS.PREVIOUS_WEEK" }),
          data: appointmentsSummary?.week?.previous,
        },
        {
          name: intl.formatMessage({ id: "APPOINTMENTS.CURRENT_WEEK" }),
          data: appointmentsSummary?.week?.current,
        },
      ]);
      setTotal({
        previous: appointmentsSummary?.week?.previous.reduce(
          (a, b) => a + b,
          0
        ),
        current: appointmentsSummary?.week?.current.reduce((a, b) => a + b, 0),
      });
      setTimeFrame("Week");
    }
  }, [chart, appointmentsSummary, intl]);

  const updateSeries = (type) => {
    if (type === "week") {
      chart.updateOptions({
        series: [
          {
            name: intl.formatMessage({ id: "APPOINTMENTS.PREVIOUS_WEEK" }),
            data: appointmentsSummary?.week?.previous,
          },
          {
            name: intl.formatMessage({ id: "APPOINTMENTS.CURRENT_WEEK" }),
            data: appointmentsSummary?.week?.current,
          },
        ],
      });
      setTimeFrame("Week");
      setDropdownOpen(false);
    } else if (type === "month") {
      chart.updateOptions({
        series: [
          {
            name: intl.formatMessage({ id: "APPOINTMENTS.PREVIOUS_MONTH" }),
            data: appointmentsSummary?.month?.previous,
          },
          {
            name: intl.formatMessage({ id: "APPOINTMENTS.CURRENT_MONTH" }),
            data: appointmentsSummary?.month?.current,
          },
        ],
      });
      setTimeFrame("Month");
      setDropdownOpen(false);
    }
  };

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title font-weight-bolder">
            {intl.formatMessage({ id: "APPOINTMENTS.APPOINTMENTS" })}
          </h3>
          <div className="card-toolbar">
            <Dropdown className="dropdown-inline">
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
                setDropdownOpen={setDropdownOpen}
              >
                <i className="ki ki-bold-more-hor p-5" />
              </Dropdown.Toggle>
              {dropdownOpen && (
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <DropdownMenu2 updateSeries={updateSeries} />
                </Dropdown.Menu>
              )}
            </Dropdown>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column p-0">
          {/* begin::Chart */}
          <div
            id="kt_mixed_widget_4_chart"
            data-color={chartColor}
            style={{ height: "160px", minHeight: "160px" }}
          />
          {/* end::Chart */}

          {/* begin::Stats */}
          <div className="bg-white card-rounded flex-grow-1">
            {/* begin::Row */}
            <div className="row m-0">
              <div className="col px-8 py-6 mr-8">
                <div className="font-size-sm text-muted font-weight-bold">
                  {intl.formatMessage({
                    id:
                      timeFrame === "Week"
                        ? "APPOINTMENTS.PREVIOUS_WEEK"
                        : "APPOINTMENTS.PREVIOUS_MONTH",
                  })}
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  {total?.previous}
                </div>
              </div>
              <div className="col px-8 py-6">
                <div className="font-size-sm text-muted font-weight-bold">
                  {intl.formatMessage({
                    id:
                      timeFrame === "Week"
                        ? "APPOINTMENTS.CURRENT_WEEK"
                        : "APPOINTMENTS.CURRENT_MONTH",
                  })}
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  {total?.current}
                </div>
              </div>
            </div>
            {/* end::Row */}
          </div>
          {/* end::Stats */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
};

export default injectIntl(AppointmentsWidget);
