import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { ME_URL } from "../modules/Auth/_redux/authCrud";
import { actions } from "../modules/Auth/_redux/authRedux";

export function CheckoutPage() {

  const dispatch = useDispatch();

  useEffect(() => {
    // Get latest info from server
    // DO NOT USE REDUX STATE AS THIS WILL CAUSE DOUBLE CHECKOUT
    axios.get(ME_URL)
      .then(function (response) {
        // If subscribed then update state to redirect to dashboard
        // Otherwise go to checkout
        if (response.data.subscribed) {
          dispatch(actions.requestUser(response.data));
        } else {
          window.location.href = `${process.env.REACT_APP_BASE_URL}/checkout/${response.data.email}`;
        }
      })
      .catch(function (error) {
        console.log(error);
      })      
  }, [dispatch]);  

  return (
      <LayoutSplashScreen />
  ) 
}
