import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Login from "../../modules/GoogleLogin/Login";
import Logout from "../../modules/GoogleLogin/Logout";
import axios from "axios";

export function GoogleCalendarPage() {
  const [isLoggedWithGoogle, setIsLoggedWithGoogle] = useState(false);
  const googleInfo = useSelector((s) => s.auth.googleInfo);

  useEffect(() => {
    googleInfo !== null
      ? setIsLoggedWithGoogle(true)
      : setIsLoggedWithGoogle(false);
  }, [googleInfo]);

  useEffect(() => {
    if (googleInfo !== null) {
      axios.get('https://www.googleapis.com/analytics/v3/management/accounts', {'accessToken': googleInfo.accessToken})
        .then(function (response) {
          // handle success
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })      
    }
  }, [googleInfo]);


  return (
    <Card>
      <CardHeader title={<FormattedMessage id="GOOGLE.TITLE" />}>
        <CardHeaderToolbar>
          {isLoggedWithGoogle ? (
            <Logout />
          ) : (
            <Login isLoggedWithGoogle={isLoggedWithGoogle} />
          )}
        </CardHeaderToolbar>
      </CardHeader>
    </Card>
  );
}
