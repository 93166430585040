import * as requestFromServer from "./addressCrud";
import { addressSlice, callTypes } from "./addressSlice";

const { actions } = addressSlice;

export const fetchAddress = () => dispatch => {
  return requestFromServer
    .getAddress()
    .then(response => {
      const { address } = response.data;
      dispatch(actions.addressFetched({ address }));
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const createAddress = address => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAddress(address)
    .then(response => {
      const { address } = response.data;
      dispatch(actions.addressCreated({ address }));
      return address;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

export const updateAddress = (id, address) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAddress(id, address)
    .then(response => {
      const { address } = response.data;
      dispatch(actions.addressUpdated({ address }));
      return address;
    })
    .catch(error => {
      const errors = error.response.data.errors;
      dispatch(actions.catchErrors({ errors }));
    });
};

