import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import defaultImage from "../Asset/defaultImage.png";
import {useDispatch, useSelector} from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as actions from "../_redux/communications/communicationsActions";
import * as communicationActions from "../_redux/communications/communicationsActions";
import {Alert} from "@material-ui/lab";
import {Button} from "@mui/material";
function CommunicationForm() {
  const dispatch=useDispatch();
  const {user}=useSelector( ( state ) => state.auth );
  const {currentState}=useSelector( ( state ) => ( {
    currentState: state.communication,
  } ) );
  const {companyInfo, loading}=currentState;
  const [ File, setFile ]=useState( defaultImage );
  const allowedFileTypes=[ "jpg", "png", "gif" ];
  const [ errorMessage, setErrorMessage ]=useState( "COMMUNICATION.HELPER_TEXT" );
  const [ error, setError ]=useState( false );
  const FileChangeHandler=async ( event ) => {
    let file=event.target.files[ 0 ];
    let reader=new FileReader();
    const fileExtension=file.name.split( "." ).at( -1 );
    if ( !allowedFileTypes.includes( fileExtension ) ) {
      setErrorMessage( "COMMUNICATION.TYPE_ERROR" );
      setError( true );
      setFile( defaultImage );
    } else if ( file.size>=5e6 ) {
      setErrorMessage( "COMMUNICATION.SIZE_ERROR" );
      setError( true );
      setFile( defaultImage );
    } else {
      reader.onload=function ( e ) {
        setFile( e.target.result );
        setError( false );
        setErrorMessage( "" );
      };
      reader.readAsDataURL( event.target.files[ 0 ] );
      const formData=new FormData();
      formData.append( "companyLogo", event.target.files[ 0 ] );
      try {
        await dispatch( communicationActions.uploadCompanyLogo( formData, user.company.id ) );
        await dispatch( actions.fetchCompanyData( user.company.id ) );
      } catch ( e ) {
        console.error( e );
      }
    }
  };

  useEffect( () => {
    dispatch( actions.fetchCompanyData( user.company.id ) );
  }, [dispatch, user.company.id] );
  const onClearError=() => {
    setError( false );
    setErrorMessage( "COMMUNICATION.HELPER_TEXT" );
  };
  return (
    <>
      <div style={{width: "250px"}}>
        <div className='d-flex flex-column'>
          <img
            src={
              loading&&companyInfo?.logo_path!=null
                ? companyInfo?.logo_path
                :File
            }
            alt='logo'
            width='250px'
            height='auto'
          />
          <Button
            variant='contained'
            component='label'
            style={{display: "flex", gap: "8px"}}
            className='mt-4'
          >
            <span>
              <CloudUploadIcon />
            </span>
            <span style={{position: "relative", top: "2px"}}>
              <FormattedMessage id={"COMMUNICATION.UPLOAD"} />
            </span>
            <input
              hidden
              accept='image/png, image/gif, image/jpg'
              type='file'
              name='file'
              onChange={FileChangeHandler}
            />
          </Button>
          {error? (
            <Alert
              severity='error'
              color='error'
              className='mt-10'
              onClose={onClearError}
            >
              <FormattedMessage id={`${errorMessage}`} />
            </Alert>
          ):(
            <span className='mt-5'>
              <FormattedMessage id={"COMMUNICATION.HELPER_TEXT"} />
            </span>
          )}
        </div>
      </div>
    </>
  );
}
export default CommunicationForm;
