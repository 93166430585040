import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  total: 0,
  customers: [],
  customersForAppointment: [],
  error: null,
  errors: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const customersSlice = createSlice({
  name: "customers",
  initialState: initialCustomersState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errors = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    customerCreated: (state, action) => {
      state.error = null;
      state.customers.push(action.payload.customer);
    },
    customerUpdated: (state, action) => {
      state.error = null;
      state.customers = [
        ...state.customers.map(customer => customer.id === action.payload.customer.id ? action.payload.customer : customer),
      ];
    },
    customerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.customers = [
        ...state.customers.filter(customer => customer.id !== Number(action.payload.id)),
      ];
    },
    customerFetched: (state, action) => {
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;
      state.error = null;
    },
    customersFetched: (state, action) => {
      const { total, customers } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.customers = customers;
      state.total = total;
    },
    customersForAppointmentFetched: (state, action) => {
      const { customers } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.customersForAppointment = customers;
    }
  }
});
