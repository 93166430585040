import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ResourcesTable from "./resources-table/ResourcesTable";
import { injectIntl, FormattedMessage } from "react-intl";
import ResourceDrawer from "./ResourceDrawer/ResourceDrawer";
import InventoryIcon from '@mui/icons-material/Inventory';

export function ResourcesCard(props) {

  const { intl } = props;
  const [drawer, setDrawer] = useState(false);
  const [resourceId, setResourceId] = useState(null);

  const toggleDrawer = (open, id) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
    setResourceId(id);
  };

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: "RESOURCE.RESOURCE_LIST" })}
        icon={<InventoryIcon color="primary" fontSize="medium" style={{ marginRight: 10 }} />}
      >
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setDrawer(true)}
          >
            <FormattedMessage id={"RESOURCE.ADD"} />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ResourcesTable toggleDrawer={toggleDrawer} />
      </CardBody>
      <ResourceDrawer open={drawer} toggleDrawer={toggleDrawer} resourceId={resourceId} />
    </Card>
  );
}

export default injectIntl(ResourcesCard);
