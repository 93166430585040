/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { Card, CardBody } from "../../../_partials/controls";

export function QuickUser() {

  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const getSubscriptionStartDate = () => {
    if (user.subscribed && !user.company.company) {
      const startDate = user.subscriptions[0].created_at;
      const date = new Date(startDate);
      return date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear();  
    }
  }

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          <FormattedMessage id="USER.PROFILE" />
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted"/>
        </a>
      </div>

      <div
        className="offcanvas-content pr-5 mr-n5"
      >
        <div className="d-flex align-items-center mt-5">
          <div
            className="symbol symbol-100 mr-5"
          >
            <div className="symbol-label" style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/users/blank.png"
              )})`
            }}/>
            <i className="symbol-badge bg-success"/>
          </div>
          <div className="d-flex flex-column">
            <p className="font-weight-bold font-size-h5 text-dark-75"
            >
              {user.fullName}
            </p>
            <div className="navi mt-2">
              <p className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted">
                    {user.email}
                  </span>
                </span>
              </p>
            </div>
            <button className="btn btn-light-primary btn-bold" onClick={logoutClick}><FormattedMessage id="USER.SIGN_OUT" /></button>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5"/>
        { user.subscribed && (
          <Card className="rounded bg-light-secondary">
          <CardBody>
            <p><span className=""><FormattedMessage id="USER.SUBSCRIPTION_ACTIVE_SINCE" />{ getSubscriptionStartDate() }</span></p>
            <p>
              <FormattedMessage id="GENERAL.GOTO" />
              <a href={'' + user.billingPortalLink} > Stripe billing portal </a>
              <FormattedMessage id="USER.VIEWHISTORYORCANCEL" />              
            </p>
          </CardBody>
        </Card>
        )}
      </div>
    </div>
  );
}
