import { createSlice } from "@reduxjs/toolkit";

const initialAnalyticsState = {
    loading: false,
    profiles: [],
    profileVisits: [0, 0, 0, 0, 0, 0, 0],
    profileCategories: ['20000101', '20000101', '20000101', '20000101', '20000101', '20000101', '20000101'],
    profileTotal: 0,
    error: null,
    success: false
};

export const callTypes = {
    list: "list",
    action: "action"
};

export const analyticsSlice = createSlice({
    name: "analytics",
    initialState: initialAnalyticsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            // TODO handle this, array of error messages in response body
            //state.error = action.payload.error.response.data;
            state.loading = false;
            state.success = false;
        },
        startCall: (state) => {
            state.errors = null;
            state.loading = true;
            state.success = false;
        },
        profilesFetched: (state, action) => {
            const { profiles } = action.payload;
            state.loading = false;
            state.success = true;
            state.error = null;
            state.profiles = profiles;
        },
        visitsFetched: (state, action) => {
            const { visits, categories, total } = action.payload;
            state.loading = false;
            state.success = true;
            state.error = null;
            state.profileVisits = visits;
            state.profileCategories = categories;
            state.profileTotal = total;
        }
    }
});
