/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
} from "../../../../_metronic/_partials/controls";
import { injectIntl } from "react-intl";
import { Calendar, Views, dateFnsLocalizer } from "react-big-calendar";
import * as employeesActions from "../../Employees/_redux/employeesActions";
import * as appointmentsActions from "../_redux/appointmentsActions";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import isEqual from "date-fns/isEqual";
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-GB";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getCurrentLanguage } from "../../../../_metronic/i18n";
import { appointmentsSlice } from "../_redux/appointmentsSlice";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";

import AppointmentDrawer from "./ApointmentDrawer/AppointmentDrawer";
import { CustomTimeSlot } from "../components/CustomTimeSlot";
import { CustomEventSlot } from "../components/CustomEventSlot";

import { endOfDay, endOfWeek, startOfDay } from "date-fns/esm";
import { CustomToolbar } from "../components/CustomToolbar";

import * as hoursActions from '../../Hours/_redux/hoursActions';
import { Alert } from "@mui/material";

const { actions } = appointmentsSlice;

const locales = {
  en: en,
  nl: nl,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const calendarViews = {
  week: {
    getStart: (date) => startOfWeek(date, { weekStartsOn: 0 }),
    getEnd: (date) => endOfWeek(date),
  },
  day: {
    getStart: (date) => startOfDay(date),
    getEnd: (date) => endOfDay(date),
  },
};

function AppointmentCard(props) {
  const { intl } = props;
  const companyId = useSelector(state => state.auth.user.company.id);
  const employeesHours = useSelector(state => state.hours.employees);
  const employeeExtraHours = useSelector(state => state.hours.extraHours);

  const { employeesState, appointmentsSate } = useSelector(
    (state) => ({
      employeesState: state.employees,
      appointmentsSate: state.appointments,
    }),
    shallowEqual
  );
  const { employees, error: employeesError } = employeesState;
  const { appointments } = appointmentsSate;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [fetchStartDate] = useState(new Date());
  const [fetchEndDate, setFetchEndDate] = useState(new Date());

  const [currentStepTimeSlot, setCurrentStepTimeSlot] = useState(15);

  const [appointmentId, setAppointmentId] = useState(null);
  const [activeTab, setActiveTab] = useState(null);

  const [showBlockInfo, setShowBlockInfo] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [date, setDate] = useState(new Date());
  const [value, setValue] = useState(new Date());

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(employeesActions.fetchAllEmployees());
  }, [dispatch]);

  const language = getCurrentLanguage();
  
  let variables = {
    resources: employees?.map((employee) => ({
      ...employee,
      employee_id: employee.id,
    })),
    resourceAccessor: (appointment) => appointment?.employee_id,
    resourceTitleAccessor: (employee) =>
      `${employee.firstName} ${employee.lastName}`,
    resourceIdAccessor: "employee_id",
  };

  let culture = language?.selectedLang === "en" ? "en-GB" : "nl";

  const [drawer, setDrawer] = useState(false);
  const [currentView, setCurrentView] = useState("day");

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === false) {
      setAppointmentId(null);
      actions.appointmentDrawerClose();
    }
    setDrawer(open);
  };

  // TODO move to separate file
  const eventStyling = (event, start, end, isSelected) => {
    if (event.type === "BLOCK") {
      return {
        style: {
          backgroundColor: "#D1D3E0",
          borderColor: "#D1D3E0",
          borderRadius: 0,
          color: "#3F4254",
        },
        className: `rbc-event__blocked ${currentView}`,
      };
    } else if (event.type === "CLIENT" && event.employee_preference === 0) {
      return {
        style: {
          backgroundColor: "#e1f0ff",
          borderColor: "#3699ff",
          borderRadius: 0,
          color: "#3F4254",
        },
      };
    } else if (event.type === "CLIENT" && event.employee_preference !== 0) {
      return {
        style: {
          backgroundColor: "#C9F7F5",
          borderColor: "#1BC5BD",
          borderRadius: 0,
          color: "#3F4254",
        },
      };
    } else {
      return {
        style: {
          backgroundColor: "#E5EAEE",
          borderColor: "#B2B7BB",
          borderRadius: 0,
          color: "#3F4254",
        },
      };
    }
  };

  const handleChangeCalendarView = (view) => {
    setCurrentView(view);
    const newStartDate = calendarViews[view].getStart(startDate);
    const newEndDate = calendarViews[view].getEnd(endDate);

    setValue(newStartDate);
    setFetchEndDate(newEndDate);

    if (view === "week") {
      setCurrentStepTimeSlot(15);
    }
  };

  const handleCalendarNavigation = (date, view) => {
    const newStartDate = calendarViews[view].getStart(date);
    const newEndDate = calendarViews[view].getEnd(date);
    

    setValue(newStartDate);
    setFetchEndDate(newEndDate);
    setDate(date);
  };

  useEffect(() => {
    dispatch(hoursActions.fetchHours());
  }, []);

  useEffect(() => {
    employees?.forEach(employee => {
      const startDate = format(fetchStartDate, 'ddMMyyyy');
      const endDate = format(fetchEndDate, 'ddMMyyyy');

      dispatch(hoursActions.fetchHoursByEmployee(companyId, employee.id));
      dispatch(hoursActions.fetchExtraHoursByEmployee(companyId, employee.id, startDate, endDate));
    });

    dispatch(
      appointmentsActions.getAppointments(
        format(value, "yyyy-MM-dd"),
        format(fetchEndDate, "yyyy-MM-dd")
      )
    );
  }, [employees, fetchStartDate, fetchEndDate]);

  return (
    <Card style={{ marginTop: '1.5rem' }}>
      <CardBody>
        {drawer && (
          <AppointmentDrawer
            open={drawer}
            toggleDrawer={toggleDrawer}
            setDrawer={setDrawer}
            appointmentId={appointmentId}
            activeTab={activeTab}
            onAppointmentChange={setAppointmentId}
            start={startDate}
            selectedEmployee={selectedEmployee}
          />
        )}
        {employeesError && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">
              {employeesError}
            </div>
          </div>
        )}
        {showBlockInfo && (
          <Alert severity="info" onClose={() => setShowBlockInfo(false)}>{intl.formatMessage({ id: "APPOINTMENTS.BLOCK.INFO" })}</Alert>
        )}
        {employees.length > 0 && (
          <Calendar
            localizer={localizer}
            events={appointments
              .filter(({ isBlocked }) => !isBlocked)
              .filter(a => a.service?.blocks_agenda !== 0)
              .map((appointment) => ({
                ...appointment,
                start: new Date(
                  appointment.startElements.year,
                  appointment.startElements.month - 1,
                  appointment.startElements.day,
                  appointment.startElements.hour,
                  appointment.startElements.minutes
                ),
                end: new Date(
                  appointment.endElements.year,
                  appointment.endElements.month - 1,
                  appointment.endElements.day,
                  appointment.endElements.hour,
                  appointment.endElements.minutes
                ),
              }))}
            defaultView={Views.DAY}
            min={new Date(1970, 1, 1, 6, 0, 0)}
            max={new Date(1970, 1, 1, 23, 0, 0)}
            views={["day", "week"]}
            culture={culture}
            titleAccessor="title"
            eventPropGetter={eventStyling}
            onRangeChange={(dates) => {
              setStartDate(dates[0]);
              setEndDate(dates[dates.length - 1]);
            }}
            onSelectEvent={(event) => {
              if (event.type === "BLOCK") {
                dispatch(appointmentsActions.deleteAppointment(event.id));
              } else {
                if (event.type === "CLIENT") {
                  setActiveTab(0);
                } else {
                  setActiveTab(1);
                }
                setAppointmentId(event.id);
                toggleDrawer(true)(null);
              }
            }}
            components={{
              event: (props) => <CustomEventSlot {...props} />,
              timeSlotWrapper: (props, slotDate) => { 
                const id = props.resource;
                let hours = {}; 
                
                const slotHour = format(props.value, 'HH:mm');
                const employeeWorkingHours = employeesHours[id];
                const currentDayOfTheWeek = getDay(props.value);
                const employeeDayHours = employeeWorkingHours?.[currentDayOfTheWeek === 0 ? 6 : currentDayOfTheWeek - 1];
                const currentEmployeeExtraHours = employeeExtraHours[id];

                if (employeeDayHours) {
                  employeeDayHours.forEach((hour) => {
                    const formattedHourStart = format(new Date(hour.start), 'HH:mm');
                    const formattedHourEnd = format(new Date(hour.end), 'HH:mm');
                if (!hours[id]) {
                      hours[id] = {};
                    }

                  const isHourOpen = hour.closed ? false : slotHour >= formattedHourStart && slotHour < formattedHourEnd;

                if (!hours[id][slotHour]) {
                        hours[id][slotHour] = isHourOpen;
                    }
                  });
                }
                if (currentEmployeeExtraHours) {
                  currentEmployeeExtraHours.forEach(hour => {
                    const isHourOpen = isEqual(new Date(hour.start), slotDate);

                if (!hours[id]) {
                        hours[id] = {};
                    }
                if (!hours[id][slotHour]) {
                        hours[id][slotHour] = isHourOpen;
                    }
                  });
                }
                return (
                <CustomTimeSlot
                  {...props}
                  appointments={appointments}
                  setActiveTab={setActiveTab}
                  setDrawer={setDrawer}
                  setStartDate={setStartDate}
                  currentStepTimeSlot={currentStepTimeSlot}
                  setSelectedEmployee={setSelectedEmployee}
                  openHours={hours}
                  currentView={currentView}
                  employeesHours={employeesHours}
                  employeeExtraHours={employeeExtraHours}
                  getDay={getDay}
                  id={props.resource}
                />
              )},
              toolbar: (props) => (
                <CustomToolbar
                  {...props}
                  currentStepTimeSlot={currentStepTimeSlot}
                  setCurrentStepTimeSlot={setCurrentStepTimeSlot}
                  currentView={currentView}
                  setShowBlockInfo={setShowBlockInfo}
                  value={value}
                  handleCalendarNavigation={handleCalendarNavigation}
                />
              ),
            }}
            slotPropGetter={(slotDate, id) => {
              const isBlocked = appointments.findIndex(
                ({ isBlocked, employee, date }) => {
                  return isBlocked &&
                    employee.id === id &&
                    isEqual(slotDate, new Date(date));
                }
              );
              return {
                className: `custom-time-slot ${isBlocked !== -1 ? "blocked-slot" : ""}`,
              };
            }}
            defaultDate={new Date()}
            messages={{
              today: intl.formatMessage({ id: "GENERAL.TODAY" }),
              previous: intl.formatMessage({ id: "GENERAL.PREVIOUS" }),
              next: intl.formatMessage({ id: "GENERAL.NEXT" }),
              day: intl.formatMessage({ id: "GENERAL.DAY" }),
              week: intl.formatMessage({ id: "GENERAL.WEEK" }),
            }}
            {...variables}
            onNavigate={handleCalendarNavigation}
            onView={handleChangeCalendarView}
            step={currentStepTimeSlot}
            date={date}
          />
        )}
        {employees.length === 0 && (
          <div className="d-flex align-items-center mb-9 bg-light-warning rounded p-5">
            <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              ></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <NavLink
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                to="/settings/employees"
              >
                {intl.formatMessage({ id: "DASHBOARD.ENTER_EMPLOYEES" })}
              </NavLink>
              <span className="text-muted font-weight-bold">
                {intl.formatMessage({ id: "DASHBOARD.ENTER_EMPLOYEES_INFO" })}
              </span>
            </div>
            <span className="font-weight-bolder text-danger py-1 font-size-lg">
              <NavLink
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                to="/settings/employees"
              >
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-right.svg"
                  )}
                ></SVG>
              </NavLink>
            </span>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default injectIntl(AppointmentCard);
