import { createSlice } from "@reduxjs/toolkit";

const initialCategoriesState = {
  listLoading: false,
  actionsLoading: false,
  categories: null,
  lastError: null,
  errors: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialCategoriesState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errors = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    categoryCreated: (state, action) => {
      state.error = null;
      state.categories.push(action.payload.category);
    },
    categoryUpdated: (state, action) => {
      state.error = null;
      state.categories = [
        ...state.categories.map(category => category.id === action.payload.category.id ? action.payload.category : category),
      ];
    },
    categoryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categories = [
        ...state.categories.filter(categories => categories.id !== Number(action.payload.id)),
      ];
    },
    categoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.categoryForEdit = action.payload.categoryForEdit;
      state.error = null;
    },
    categoriesFetched: (state, action) => {
      const { categories } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.categories = categories;
    },
  }
});
