import React from "react";
import { Switch, Route } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import TilesPage from "./IntegrationTiles/TilesPage";
import WebsiteIntegration from "./IntegrationTiles/WebsiteIntegration";
import WordpressIntegration from "./IntegrationTiles/WordpressIntegration";
import FacebookIntegration from "./IntegrationTiles/FacebookIntegration";
import InstagramIntegration from "./IntegrationTiles/InstagramIntegration";
import FloatingButtonIntegration from "./IntegrationTiles/FloatingButtonIntegration";

export default function WebsitePage() {
  return (
    <Switch>
      <Route exact path="/your-website" component={TilesPage} />
      <ContentRoute
        from="/your-website/website"
        component={WebsiteIntegration}
      />
      <ContentRoute
        from="/your-website/wordpress"
        component={WordpressIntegration}
      />
      <ContentRoute
        from="/your-website/facebook"
        component={FacebookIntegration}
      />
      <ContentRoute
        from="/your-website/instagram"
        component={InstagramIntegration}
      />
      <ContentRoute
        from="/your-website/floating-button"
        component={FloatingButtonIntegration}
      />
    </Switch>
  );
}
