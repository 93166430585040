import React, { useState } from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { ButtonGroup, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useWindowDimensions } from '../../../helpers/hooks/useWindowDimensions';

import AdjustIcon from '@mui/icons-material/Adjust';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { nl, enUS } from 'date-fns/locale';
import { getCurrentLanguage } from '../../../../_metronic/i18n';

const useStyles = makeStyles((theme) => ({
    customToolbar: {
        margin: '1rem 0',
    },
    btnGroup: {
        [theme.breakpoints.down('md')]: {
            '& .btn': {
                padding: '0.1rem'
            }
        }
    },
    day: {
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
            fontSize: '0.9rem',
        }
    },
}));

export const CustomToolbar = (props) => {
    const {
        onNavigate,
        onView,
        label,
        currentStepTimeSlot,
        setCurrentStepTimeSlot,
        currentView,
        setShowBlockInfo,
        value,
        handleCalendarNavigation
    } = props;

    const intl = useIntl();
    const classes = useStyles();
    const { isMobile } = useWindowDimensions();

    const [open, setOpen] = useState(false);

    const handleNavigate = (view) => onNavigate(view);
    const handleSelectTimeSlot = (value) => setCurrentStepTimeSlot(value);
    const handleChangeView = (view) => onView(view);

    const language = getCurrentLanguage();
    const datePickerLocale = language && language.selectedLang === 'en' ? enUS : nl;

    return (
        <Grid
            className={classes.customToolbar}
            container
            justifyContent='space-between'
            alignItems='center'
        >
            <Grid item>
                <ButtonGroup className={classes.btnGroup}>
                    <Button variant='secondary' onClick={() => handleNavigate('PREV')}>
                        {isMobile ? <NavigateBeforeIcon /> : intl.formatMessage({ id: 'GENERAL.PREVIOUS' })}
                    </Button>
                    <Button variant='secondary' onClick={() => handleNavigate('TODAY')}>
                        {isMobile ? <AdjustIcon /> : intl.formatMessage({ id: 'GENERAL.TODAY' })}
                    </Button>
                    <Button variant='secondary' onClick={() => handleNavigate('NEXT')}>
                        {isMobile ? <NavigateNextIcon /> : intl.formatMessage({ id: 'GENERAL.NEXT' })}
                    </Button>
                    <Button variant='secondary' onClick={() => setOpen(true)}>
                        <CalendarMonthIcon />
                    </Button>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={datePickerLocale}>
                        <DesktopDatePicker
                            toolbarFormat="iiii c MMMM yyyy"
                            value={value}
                            open={open}
                            onChange={(newValue) => handleCalendarNavigation(newValue, currentView)}
                            onClose={() => setOpen(false)}
                            renderInput={() => null}
                            showToolbar
                            PaperProps={{
                                style: {
                                    position: 'fixed',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate3d(-50%, -50%,0)'
                                }
                            }}
                            toolbarTitle={intl.formatMessage({ id: "APPOINTMENTS.DATE" })}
                        />
                    </LocalizationProvider>
                </ButtonGroup>
            </Grid>
            <Grid item>
                <p className={classes.day}>{label}</p>
            </Grid>
            <Grid item style={{ textAlign: 'end' }}>
                <ButtonGroup className={classes.btnGroup}>
                    <Button
                        active={currentView === 'day'}
                        variant='secondary'
                        onClick={() => handleChangeView('day')}
                    >
                        {intl.formatMessage({ id: 'GENERAL.DAY' })}
                    </Button>
                    <Button
                        active={currentView === 'week'}
                        variant='secondary'
                        onClick={() => handleChangeView('week')}
                    >
                        {intl.formatMessage({ id: 'GENERAL.WEEK' })}
                    </Button>
                </ButtonGroup>
                <DropdownButton
                    as={ButtonGroup}
                    variant='secondary'
                    title={`${currentStepTimeSlot}m`}
                    style={{ display: 'none' }}
                >
                    <Dropdown.Item onClick={() => handleSelectTimeSlot(15)}>
                        15 {intl.formatMessage({ id: 'GENERAL.MINUTES' })}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSelectTimeSlot(30)}>
                        30 {intl.formatMessage({ id: 'GENERAL.MINUTES' })}
                    </Dropdown.Item>
                </DropdownButton>
            </Grid>
            {isMobile && (
                <Grid item>
                    <IconButton style={{ padding: 0 }} onClick={() => setShowBlockInfo(true)}>
                        <HelpOutlineIcon />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};
