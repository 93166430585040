import { createSlice } from "@reduxjs/toolkit";

const initialEmployeesState = {
  listLoading: false,
  actionsLoading: false,
  employees: [],
  employeesByServiceId: null,
  employeeForEdit: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    order: "",
    serviceIds: [],
  },
  lastError: null,
  errors: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState: initialEmployeesState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload.errors;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errors = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    employeeCreated: (state, action) => {
      state.error = null;
      state.employees.push(action.payload.employee);
    },
    employeesFetched: (state, action) => {
      const { employees } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.employees = employees;
    },
    employeesByServiceIdFetched: (state, action) => {
      const { employees } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.employeesByServiceId = employees;
    },
    employeeUpdated: (state, action) => {
      state.error = null;
      state.employees = [
        ...state.employees.map(employee => employee.id === action.payload.employee.id ? action.payload.employee : employee),
      ];
    },
    employeeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.employees = [
        ...state.employees.filter(employee => employee.id !== Number(action.payload.id)),
      ];
    },
    employeeFetched: (state, action) => {
      state.actionsLoading = false;
      state.employeeForEdit = action.payload.employeeForEdit;
      state.error = null;
    }
  }
});
