import axios from "axios";

export const EMPLOYEE_URL = "employees";

export async function getAllEmployee() {
  return axios.get(`${EMPLOYEE_URL}`);
}

export async function getEmployeesByService(companyId, serviceId) {
  return axios.get(`companies/${companyId}/services/${serviceId}/${EMPLOYEE_URL}`);
}

export function createEmployee(employee) {
  return axios.post(EMPLOYEE_URL, { employee });
}

export function updateEmployee(id, employee) {
  return axios.put(`${EMPLOYEE_URL}/${id}`, { employee });
}

export function getEmployeeById(employeeId) {
  return axios.get(`${EMPLOYEE_URL}/${employeeId}`);
}

export function deleteEmployee(id) {
  return axios.delete(`${EMPLOYEE_URL}/${id}`);
}
